<template>
    <div class="account-form-wrapper">
        <div class="flex-box" style="align-items: normal">
            <div class="label">服务地区：</div>
            <div class="flex-unit addr-box">
                <span class="addr hover-point" v-for="(item,index) in addrs" :key="index"
                     :class="{'addr-active': item.cityName===userInfo.addr}"
                     @click="userInfo.addr=item.cityName">
                    {{item.cityName}}
                    <a-icon v-show="item.cityName===userInfo.addr" type="environment" />
                </span>
            </div>
        </div>
        <div class="flex-box">
            <div class="label">服务类型：</div>
            <div class="flex-unit">
                <div class="select-btn" v-for="(item,index) in serviceType" :key="index"
                     :class="{'select-btn-active': item===userInfo.serviceType}"
                     @click="userInfo.serviceType=item">{{item}}</div>
            </div>
        </div>
        <div class="flex-box">
            <div class="label">月开票量：</div>
            <div class="flex-unit">
                <div class="select-btn" v-for="(item,index) in receiptNum" :key="index"
                     :class="{'select-btn-active': item===userInfo.receiptNum}"
                     @click="userInfo.receiptNum=item">{{item}}</div>
            </div>
        </div>
        <div class="flex-box">
            <div class="label">极速申请：</div>
            <div class="flex-unit input-box">
                <a-input v-model="userInfo.name" placeholder="请输入称呼：如:王先生" style="height:54px;width: 237px;margin-right: 50px"/>
                <a-input v-model="userInfo.phone" placeholder="请输入您的手机号" style="height:54px;width: 237px;margin-right: 50px"/>
                <div class="btn hover-point" @click="sumbitForm">确定</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Input,Icon} from 'ant-design-vue';
    export default {
        name: "AccountForm",
        components: {
            [Input.name]: Input,
            [Icon.name]: Icon
        },
        data(){
            return {
                userInfo:{
                    addr:'福州',
                    serviceType:'一般纳税人',
                    receiptNum:'0申报',
                    name:'',
                    phone:''
                },
                serviceType:['一般纳税人','小规模纳税人'],
                receiptNum:['0申报','5-10张','10-20张','20张以上']
            }
        },
        computed:{
            addrs(){
                return this.$store.state.cities
            }
        },
        methods:{
            async sumbitForm(){
                 let data=this.userInfo;
                if(!/^[1][3,4,5,7,8][0-9]{9}$/.test(data.phone)){
                    this.$message.error('请填写正确的手机号');
                    return false
                }
                let req={
                    applicantTel:data.phone,
                    applicantName:data.name,
                    cityCode:data.addr,
                    invoiceNum:data.receiptNum,
                    taxType:data.serviceType,
                    serviceName:'代理记账'
                }
                let res=await this.$api.toApplyService(req);
                if(res===undefined){
                    this.$store.commit('changeStateVal',{key:'modalConfig',value:{showModal:true}});
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .account-form-wrapper{
        box-sizing: border-box;
        padding: 75px 50px 30px;
        background-color: #fff;
        font-size: 16px;
        .select-btn{
            float: left;
            width: 176px;
            height: 54px;
            border: 1px solid #D4D4D4;
            border-radius: 27px;
            margin-right: 54px;
            line-height: 54px;
            cursor: pointer;
            &:hover{
                border-color: @primary-color;
                color: @primary-color;
            }
        }
        .select-btn-active{
            border-color: @primary-color;
            color: @primary-color;
        }
        .flex-box{
            margin-bottom: 40px;
            align-items: center;
            .label{
                width: 120px;
            }
            .input-box{
                text-align: left;
                .btn{
                    display: inline-block;
                    width:163px;
                    height:54px;
                    border-radius:27px;
                    background-color:#F17862;
                    line-height: 54px;
                    text-align: center;
                    color: #fff;
                }
            }
        }
        .addr-box{
            text-align: left;
            .addr{
                display: inline-block;
                margin-right: 30px;
                &:hover{
                    color: @primary-color;
                }
            }
            .addr-active{
                color: @primary-color;
            }
        }
    }
</style>