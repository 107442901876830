<template>
    <div class="about-wrapper">
        <div class="banner bg-cc"></div>
        <div class="safe-content">
            <div class="tabs">
                <router-link class="tab" v-for="(tab,index) in tabs"
                             :key="index"
                             :class="{'tab-active':tab.name===cRoute}"
                             :to="{name: tab.name}">{{tab.label}}
                </router-link>
            </div>
            <div class="tabs-content">
                <router-view></router-view>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "About",
        data() {
            return {
                cRoute:'aboutCompany',
                tabs: [
                    {
                        "label": "关于智会算",
                        "name": "aboutCompany"
                    },
                    // {
                    //     "label": "发展历程",
                    //     "name": "development"
                    // },
                    {
                        "label": "荣誉一览",
                        "name": "honour"
                    },
                    {
                        "label": "联系我们",
                        "name": "contact"
                    },
                    {
                        "label": "诚聘英才",
                        "name": "recruitment"
                    }
                ]
            }
        },
        created(){
            // 设置初始路由
            this.cRoute=this.$route.name;
        },
        watch:{
            '$route'(to){
                this.cRoute=to.name;
            }
        }
    };
</script>
<style lang="less">
    .about-wrapper {
        .banner {
            background-image: url("../assets/about-banner.png");
        }

        .tabs{
            float: right;
            height: 63px;
            margin-top: -60px;
            border-radius: 3px;
            line-height: 63px;
            background-color: #F5F9FD;
            .tab{
                padding: 0 20px;
                border-right: 1px solid #CBCFD2;
                color: #212121;
                &:last-child{
                    border-right: none;
                }
            }

            .tab-active{
                position: relative;
                &:after{
                    content: '';
                    position: absolute;
                    width: 50px;
                    height: 3px;
                    left: 50%;
                    bottom: -8px;
                    margin-left: -25px;
                    background-color: #EA9534;
                }
            }
        }

        .tabs-content{
            box-sizing: border-box;
            padding-top: 50px;
        }
    }
</style>
