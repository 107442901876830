<template>
    <div class="development-wrapper safe-content">
        <div class="timeline" v-for="(develop,di) in developments" :key="di">
            <div class="year">{{develop.year}}</div>
            <div class="line-box">
                <div class="img-box">
                    <div class="img-desc">{{develop.title}}</div>
                    <div class="img bg-cc" :style="`background-image:url(${develop.img})`"></div>
                </div>
                <div class="content-box">
                    <div class="content-unit" v-for="(item,index) in develop.contentList" :key="index">
                        <div class="month">{{item.month}}</div>
                        <div v-html="item.content"></div>
                    </div>
                </div>
                <div class="vertical-line"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Development",
        data(){
            return {
                developments:[
                    {
                        year:2019,
                        title:'凝心聚力 逐梦前行',
                        img:'',
                        contentList:[
                            {
                                title: '七月',
                                content:'智会算企业客户数突破150家<br>智会算获2019爱分析“中国企业云创新企业500强”'
                            },
                            {
                                title: '五月',
                                content:'智会算入围”胡润中国潜力独角兽”榜单<br>“新机遇·智会算全国财税服务创新论坛”福建起航'
                            },
                            {
                                title: '四月',
                                content:'“智会算爱心公益行”关爱民工子弟、孤寡老人、自闭症儿童<br>与北京青年政治学院、唐山科技职业技术学院等高校达成战略合作'
                            },
                            {
                                title: '一月',
                                content: '福清分公司成立'

                            }
                        ]
                    },
                    {
                        year:2018,
                        title:'凝心聚力 逐梦前行',
                        img:'',
                        contentList:[
                            {
                                title: '七月',
                                content:'智会算企业客户数突破150家<br>智会算获2019爱分析“中国企业云创新企业500强”'
                            },
                            {
                                title: '五月',
                                content:'智会算入围”胡润中国潜力独角兽”榜单<br>“新机遇·智会算全国财税服务创新论坛”福建起航'
                            },
                            {
                                title: '四月',
                                content:'“智会算爱心公益行”关爱民工子弟、孤寡老人、自闭症儿童<br>与北京青年政治学院、唐山科技职业技术学院等高校达成战略合作'
                            },
                            {
                                title: '一月',
                                content: '福清分公司成立'

                            }
                        ]
                    },
                    {
                        year:2017,
                        title:'凝心聚力 逐梦前行',
                        img:'',
                        contentList:[
                            {
                                title: '七月',
                                content:'智会算企业客户数突破150家<br>智会算获2019爱分析“中国企业云创新企业500强”'
                            },
                            {
                                title: '五月',
                                content:'智会算入围”胡润中国潜力独角兽”榜单<br>“新机遇·智会算全国财税服务创新论坛”福建起航'
                            },
                            {
                                title: '四月',
                                content:'“智会算爱心公益行”关爱民工子弟、孤寡老人、自闭症儿童<br>与北京青年政治学院、唐山科技职业技术学院等高校达成战略合作'
                            },
                            {
                                title: '一月',
                                content: '福清分公司成立'

                            }
                        ]
                    }
                ]
            }
        },
        created(){
            this.getDevelopments();
        },
        methods:{
            async getDevelopments(){
                let res=await this.$api.getDevelopments();
                let list=(res || []).map(item=>{
                    let monthList=(item.companyHistoryMonthList || []).map(month=>{
                        return {
                            month: month.historyMonth,
                            content:month.content
                        }
                    })
                    return {
                        year:item.historyYear,
                        title:item.title,
                        img:item.picture,
                        contentList:monthList
                    }
                })
                this.developments=list;
            }
        }
    }
</script>

<style lang="less" scoped>
    .development-wrapper{
        .timeline{
            margin-bottom: 60px;
            .year{
                font-size: 18px;
                color: @primary-color;
            }
            .line-box{
                text-align: left;
                overflow: hidden;
                .img-box{
                    float: left;
                    width: 530px;
                    .img-desc{
                        margin-bottom: 20px;
                        font-size: 20px;
                    }
                    .img{
                        height: 332px;
                    }
                }
                .vertical-line{
                    float: left;
                    box-sizing: border-box;
                    width: 1px;
                    height: 400px;
                    padding: 10px 0;
                    margin-left: 69px;
                    margin-right: 69px;
                    background-color: @primary-color;
                }
                .content-box{
                    float: right;
                    width: 530px;
                    min-height:100px;
                    font-size: 16px;
                    .content-unit{
                        margin-bottom: 30px;
                    }
                }
            }

        }
        .timeline:nth-child(even){
            .img-box{
                float: right;
            }
            .content-box{
                float: left;
                box-sizing: border-box;
                padding-left: 60px;
            }
        }
    }
</style>