<template>
    <div class="recruitment-detail-wrapper">
        <div class="banner">
            <div class="header-info safe-content">
                <div class="header-title">{{info.positionName}}<span>{{info.salary}}</span></div>
                <div class="tags">
                    <span v-if="info.urgent==='Y'">急招</span>
                    <span>全职</span>
                </div>
                <div>发布时间：{{info.publishTime}}</div>
            </div>
        </div>
        <div class="safe-content duty-wrapper">
            <div class="duty-contain">
                <div class="duty-content">
                    <div class="duty-title">
                        <a-icon class="icon" type="environment" theme="filled"/>
                        <span>工作地点：</span>
                    </div>
                    <div class="duty-detail" style="margin-bottom: 40px;">{{info.location}}</div>
                    <div class="duty-title">
                        <a-icon class="icon" type="solution"/>
                        <span>岗位职责：</span>
                    </div>
                    <div class="duty-detail" v-html="info.duty"></div>
                </div>
                <div class="relative-job">
                    <div>相关职位</div>
                    <div class="jobs">
                        <router-link class="recruitment-card" v-for="(item,index) in list" :key="index"
                                     :to="{name: 'recruitmentDetail',query: {id: item.id}}">
                            <div class="card-head" :class="[item.status]">
                                <span>{{item.title}}</span>
                                <span v-if="item.status" class="tag">{{item.statusLabel}}</span>
                                <span class="salary">{{item.salary}}</span>
                            </div>
                            <div class="card-content">
                                <div class="location">
                                    <a-icon class="icon" type="environment" theme="filled"/>
                                    <span style="color: #333">工作地点：</span>{{item.location}}
                                </div>
                                <div class="duty">
                                    <a-icon class="icon" type="solution"/>
                                    <span style="color: #333">岗位职责：</span>{{item.duty}}
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Icon} from 'ant-design-vue';
    export default {
        name: "RecruitmentDetail",
        components:{
            [Icon.name]: Icon
        },
        async created() {
            let id=this.$route.query.id;
            await this.getDetail(id);
        },
        data(){
            return {
                info:{},
                list:[]
            }
        },
        methods:{
            async getDetail(id){
                let res=await this.$api.getRecruitmentDetail(id);
                this.info=res;
                this.list=(res.companyRecruitmentVOList || []).map(item=>{
                    return {
                        id:item.companyRecruitmentGuid,
                        title:item.positionName,
                        status:item.urgent==='Y' ? 'urgent' : '',
                        statusLabel:'急招',
                        salary:item.salary,
                        location:item.location,
                        duty:item.duty
                    }
                })
            }
        }
    };
</script>
<style lang="less">
    .recruitment-detail-wrapper{
        text-align: left;
        font-size: 16px;
        .banner{
            background-image: url("../assets/recruitment-detail-banner.png");
            .header-info{
                box-sizing: border-box;
                padding-top: 100px;
                color: #fff;
                .header-title{
                    font-size: 40px;
                    span{
                        display: inline-block;
                        margin-left: 50px;
                        color: @primary-color;
                    }
                }
                .tags{
                    margin-top: 20px;
                    margin-bottom: 30px;
                    span{
                        display: inline-block;
                        box-sizing: border-box;
                        padding: 2px 15px;
                        border: 1px solid @primary-color;
                        border-radius: 15px;
                        margin-right: 20px;
                        color: @primary-color;
                        text-align: center;
                    }
                }
            }
        }
        .duty-wrapper{
            box-sizing: border-box;
            padding: 20px 0;
            min-height: 200px;

            .duty-contain{
                display: flex;
                width: 100%;
                margin-top: 10px;
                .duty-content{
                    box-sizing: border-box;
                    padding-right: 100px;
                    width: 770px;
                    color: #656565;
                    .duty-title{
                        .icon{
                            color: @primary-color;
                            font-size: 20px;
                        }
                        span{
                            display: inline-block;
                            margin-left: 10px;
                        }
                    }
                    .duty-detail{
                        font-size: 14px;
                        line-height: 2;
                    }
                }
                .relative-job{
                    flex: 1;
                    padding: 6px 0 35px 45px;
                    border-left: 1px solid #e3e4e5;
                    font-size: 16px;
                }
            }

            .relative-job{
                .jobs{
                    .recruitment-card{
                        display: block;
                        width: 380px;
                        height: 220px;
                        border-radius: 10px;
                        box-shadow: 0 0 4px 1px rgba(0,0,0,.1);
                        margin-top: 20px;
                        overflow: hidden;
                        .card-head{
                            box-sizing: border-box;
                            padding-left: 20px;
                            height: 45px;
                            line-height: 45px;
                            font-size: 18px;
                            color: #fff;
                            background: linear-gradient(45deg,#01649F,#3690C4);
                            .tag{
                                display: inline-block;
                                box-sizing: border-box;
                                padding: 3px 8px;
                                border-radius: 8px;
                                margin-left: 10px;
                                color: #F89E61;
                                font-size: 12px;
                                background-color: #fff;
                                line-height: 1;
                            }
                            .salary{
                                float: right;
                                margin-right: 20px;
                            }
                        }

                        /*头部状态值*/
                        .urgent{
                            background: linear-gradient(45deg,#F89E61,#EB5D60);
                        }


                        .card-content{
                            box-sizing: border-box;
                            padding: 35px 20px 20px;
                            color: #666;
                            font-size: 14px;
                            .icon{
                                margin-right: 15px;
                                font-size: 18px;
                                color: #F89E61;
                            }
                            .location{
                                height: 23px;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                word-break: break-all;
                            }
                            .duty{
                                height: 66px;
                                margin: 20px 0;
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow:ellipsis;
                            }
                        }
                    }
                }
            }

        }
    }
</style>
