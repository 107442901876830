<template>
    <div class="recruitment-wrapper safe-content">
        <div class="recruitment-tabs">
            <div class="recruitment-tab" v-for="(tab,index) in typeList"
                 :key="index"
                 :class="{'tab-active':tab.value===cType}"
                 @click="changeTab(tab.value)">{{tab.label}}
            </div>
        </div>
        <div class="recruitment-content">
            <router-link class="recruitment-card" v-for="(item,index) in list" :key="index"
                         :to="{name: 'recruitmentDetail',query: {id: item.id}}">
                <div class="card-head" :class="[item.status]">
                    <span>{{item.title}}</span>
                    <span v-if="item.status" class="tag">{{item.statusLabel}}</span>
                    <span class="salary">{{item.salary}}</span>
                </div>
                <div class="card-content">
                    <div class="location">
                        <a-icon class="icon" type="environment" theme="filled"/>
                        <span style="color: #333">工作地点：</span>{{item.location}}
                    </div>
                    <div class="duty">
                        <a-icon class="icon" type="solution"/>
                        <span style="color: #333">岗位职责：</span>{{item.duty}}
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
    import {Icon} from 'ant-design-vue';
    export default {
        name: "Recruitment",
        components:{
            [Icon.name]: Icon
        },
        data(){
            return {
                typeList:[],
                cType:'',
                list:[]
            }
        },
        async created(){
            await this.getRecruitmentType();
          this.getRecruitment();
        },
        methods:{
            async getRecruitmentType(){
                let res=await this.$api.getRecruitmentType();
                let typeList=[{label:'全部职位',value:''}];
                (res || []).forEach(item=>{
                    typeList.push({
                        label:item.dictLabel,
                        value:item.dictValue
                    })
                })
                this.typeList=typeList;
            },
            async getRecruitment(id='',page=1){
                let res=await this.$api.getRecruitment(page,id);
                this.list=(res.list || []).map(item=>{
                    return {
                        id:item.companyRecruitmentGuid,
                        title:item.positionName,
                        status:item.urgent==='Y' ? 'urgent' : '',
                        statusLabel:'急招',
                        salary:item.salary,
                        location:item.location,
                        duty:item.duty
                    }
                })
                console.log(this.list)
            },
            changeTab(value){
                this.cType=value;
                this.getRecruitment(value);
            }
        }
    }
</script>

<style lang="less" scoped>
    .recruitment-wrapper{
        .recruitment-tabs{
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #e3e4e5;
            margin-bottom: 30px;
            font-size: 16px;
            .recruitment-tab{
                padding-bottom: 10px;
                &:hover{
                    color: @primary-color;
                }
            }
            .tab-active{
                color: @primary-color;
                border-bottom: 2px solid @primary-color;
            }
        }
        .recruitment-content{
            display: flex;
            flex-wrap: wrap;
            text-align: left;
            min-height: 300px;
            .recruitment-card{
                width: 380px;
                height: 220px;
                border-radius: 10px;
                box-shadow: 0 0 4px 1px rgba(0,0,0,.1);
                margin-right: 30px;
                margin-bottom: 30px;
                overflow: hidden;
                .card-head{
                    box-sizing: border-box;
                    padding-left: 20px;
                    height: 45px;
                    line-height: 45px;
                    font-size: 18px;
                    color: #fff;
                    background: linear-gradient(45deg,#01649F,#3690C4);
                    .tag{
                        display: inline-block;
                        box-sizing: border-box;
                        padding: 3px 8px;
                        border-radius: 8px;
                        margin-left: 10px;
                        color: #F89E61;
                        font-size: 12px;
                        background-color: #fff;
                        line-height: 1;
                    }
                    .salary{
                        float: right;
                        margin-right: 20px;
                    }
                }

                /*头部状态值*/
                .urgent{
                    background: linear-gradient(45deg,#F89E61,#EB5D60);
                }


                .card-content{
                    box-sizing: border-box;
                    padding: 35px 20px 20px;
                    font-size: 14px;
                    color: #666;
                    .icon{
                        margin-right: 15px;
                        font-size: 18px;
                        color: #F89E61;
                    }
                    .location{
                        height: 23px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        word-break: break-all;
                    }
                    .duty{
                        height: 66px;
                        margin: 20px 0;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow:ellipsis;
                    }
                }
            }
            .recruitment-card:nth-child(3n){
                margin-right: 0;
            }
        }
    }
</style>