<template>
    <div class="home-menus-wrapper">
        <div class="flex-box">
            <div class="account-type">
                <div class="icon" :style="`background-image:url(${require('../../assets/home-account-record.png')})`"></div>
                <p>小规模代理记账</p>
                <!--<p class="desc">我们承诺“做错包赔”</p>-->
            </div>
            <div class="account-content menus-lg">
                <div class="tag" style="background-color: #FA9B24">工商注册</div>
                <ul class="detail">
                    <li v-for="(item,index) in (list[0] || [])" :key="index">
                        <router-link class="link" :to="{name: 'service',query: {id: item.id}}">{{item.label}}</router-link>
                    </li>
                </ul>
            </div>
            <!--<div class="account-content menus-sm">
                <div class="tag" style="background-color: #FA9B24">工商注册</div>
                <ul class="detail">
                    <li v-for="(item,index) in (list[0] || [])" :key="index">
                        <router-link class="link" :to="{name: 'service',query: {id: item.id}}">{{item.label}}</router-link>
                    </li>
                </ul>
            </div>-->
        </div>
        <div class="flex-box">
            <div class="account-type">
                <div class="icon" :style="`background-image:url(${require('../../assets/home-account-tax.png')})`"></div>
                <p>一般纳税人代理记账</p>
               <!-- <p class="desc">智代账工具简化20道财务处理流程<br>提效70%的工作量</p>-->
            </div>
            <div class="account-content menus-lg">
                <div class="tag" style="background-color: #F17861">代理记账</div>
                <ul class="detail">
                    <li v-for="(item,index) in (list[1] || [])" :key="index">
                        <router-link class="link" :to="{name: 'service',query: {id: item.id}}">{{item.label}}</router-link>
                    </li>
                </ul>
            </div>
           <!-- <div class="account-content menus-sm">
                <div class="tag" style="background-color: #FA9B24">工商注册</div>
                <ul class="detail">
                    <li v-for="(item,index) in (list[0] || [])" :key="index">
                        <router-link class="link" :to="{name: 'service',query: {id: item.id}}">{{item.label}}</router-link>
                    </li>
                </ul>
            </div>-->
        </div>
    </div>
</template>

<script>
    export default {
        name: "Menus",
        data(){
            return {
                list:this.handleMenus(this.$store.state.headerMenus)
            }
        },
        methods:{
            handleMenus(menus=[]){
                return menus.map(item=>{
                    return item.subMenu;
                })
            }
        },
        watch:{
            '$store.state.headerMenus'(newVal){
                this.list=this.handleMenus(newVal);
            }
        }
    }
</script>

<style lang="less" scoped>
    .home-menus-wrapper{

        .flex-box{
            height: 200px;
            margin-bottom: 20px;
        }

        .account-type{
            width: 300px;
            margin-right: 20px;
            background-color: #fff;
            .icon{
                width: 80px;
                height: 80px;
                margin: 25px auto 12px;
            }
            p{
                margin-bottom: 5px;
            }
            .desc{
                font-size: 14px;
                color: #666;
                line-height: 1.3;
            }
        }

        .account-content{
            background-color: #fff;
            text-align: left;
            .tag{
                width: 100px;
                height: 34px;
                line-height: 34px;
                text-align: center;
                color: #fff;
            }
            .detail{
                margin: 30px 8px 30px 120px;
                li{
                    float: left;
                    width: 230px;
                    margin-bottom: 20px;
                    .link{
                        color: #333;
                    }
                    &:hover{
                        color: @primary-color;
                        .link{
                            color: @primary-color;
                        }
                    }
                }
            }
        }

        .menus-lg{
            width: 900px;
            /*margin-right: 20px;*/
        }

        .menus-sm{
            width: 260px;
        }

    }
</style>