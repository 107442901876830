import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Api from "./api/api";
import './mixins/style/app.less'
import { message,notification } from 'ant-design-vue';

message.config({
  top: `300px`,
  duration: 2
});
Vue.config.productionTip = false;
Vue.prototype.$api=new Api();
Vue.prototype.$message=message;
Vue.prototype.$notification=notification;
Vue.config.errorHandler = function (err, vm, info) {
  console.log(err, vm, info,1111)
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
