import HttpConnect from './index';

class Api{
    constructor() {
        this._http=new HttpConnect();
    }

    // 公共头部接口
    // 菜单,进行数据处理
    async getHeaderMenus(){
        let res=await this._http.getData('/api/service/getAllServiceType');
        if(res.length===0){
            return Promise.reject('服务数据丢失，请联系管理员');
        }
        return (res || []).map(menu=>{
            let subMenu=(menu.apiServiceTypeVOList || []).map(item=>{
                return {
                    label:item.serviceTypeName,
                    id:item.serviceTypeGuid
                }
            });
            return {
                label:menu.serviceParentTypeName,
                desc:menu.alias,
                title:menu.title,
                subMenu:subMenu
            }
        })
    }

    // 申请服务
    toApplyService(data){
        return this._http.postData('/api/service/applyForService',data)
    }

    // 二级页面服务申请
    toApplyDetailService(data){
        return this._http.postData('/api/service/serviceApplication',data)
    }

    // 获取文档
    getDocument(id){
        return this._http.getData('/api/company/getDocumentByType',{documentType:id})
    }

    // 经营城市地址
    getCities(){
        return this._http.getData('/api/company/getCityList')
    }

    // 首页接口
    getHomeBanner(){
        return this._http.getData('/api/home/getHomeTopBannerPage',{
            page:1,
            size:20
        })
    }

    async getHomeTraining(){
        let res=await this._http.getData('/api/company/getCompanyTrainingPage',{
            page:1,
            size:6
        });
        if(res.list.length<6){
            return Promise.reject('公司培训数据缺失，请联系管理员');
        }
        return res.list
    }

    getHomeNews(){
        return this._http.getData('/api/newsContent/getNewsMoldAndList')
    }

    // 代理记账
    // 专家团队
    getExpertList(){
        return this._http.getData('/api/company/expertList')
    }

    // 获取财税顾问专家团队列表
    getAdvisors(){
        return this._http.getData('/api/company/taxExpertList')
    }

    // 加盟我们接口
    getPartners(){
        return this._http.getData('/api/join/getCooperativeBusinessPage')
    }

    getMembers(){
        return this._http.getData('/api/join/getJoinBusinessPage')
    }

    toJoin(data){
        return this._http.postData('/api/join/applyForJoin',data)
    }

    // 资讯相关接口
    // 获取资讯类型列表
    getNewsType(){
        return this._http.getData('/api/newsContent/getApiNewsMoldVOList')
    }

    getNews(data){
        let {id,pageSize=10,page=1}=data;
        return this._http.getData('/api/newsContent/getNewsContentPageByMold',{
            newsMoldGuid:id,
            size:pageSize,
            page
        })
    }

    getNewsDetail(id){
        return this._http.getData('/api/newsContent/selectApiNewsContentVOById',{newsContentGuid:id})
    }

    // 关于我们页面接口
    getHonor(){
        return this._http.getData('/api/company/getCompanyHonorPage',{
            page:1,
            size:100
        })
    }

    getDevelopments(){
        return this._http.getData('/api/company/getCompanyHistoryList')
    }

    getRecruitmentType(){
        return this._http.getData('/api/company/getRecruitmentClassification')
    }

    getRecruitment(page=1,id){
        return this._http.getData('/api/company/getCompanyRecruitmentPage',{
            classification:id,
            size:100,
            page
        })
    }

    getRecruitmentDetail(id){
        return this._http.getData('/api/company/getCompanyRecruitmentDetailById',{companyRecruitmentGuid :id})
    }

    getContactProvince(){
        return this._http.getData('/api/company/getCompanyProvince')
    }

    getContactCompanies(id){
        return this._http.getData('/api/company/getCompanyProvinceItem',{companyProvinceGuid :id})
    }

    // 二级页面接口
    // 二级界面--培训详情
    getTrainDetail(id){
        return this._http.getData('/api/company/getCompanyTrainingDetail',{companyTrainingId :id})
    }

    // 二级页面--服务详情
    getServiceDetail(id){
        return this._http.getData('/api/service/getServiceTypeDetailByGuid',{serviceTypeGuid :id})
    }
}

export default Api