<template>
    <div class="partner-wrapper">
        <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide v-for="(oz,oi) in organizations" :key="oi">
                <div class="slide-content">
                    <div class="bg" :style="`background-image:url('${oz.bg}')`"></div>
                    <div class="label">{{oz.name}}</div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
    import { Swiper, SwiperSlide  } from 'vue-awesome-swiper'
    import 'swiper/swiper-bundle.css'
    export default {
        name: "Partner",
        components: {
            Swiper,
            SwiperSlide
        },
        async created(){
           this.getPartners();
        },
        mounted(){
            this.swiper=this.$refs.mySwiper.$swiper;
        },
        data(){
            return {
                organizations:[],
                swiperOptions: {
                    slidesPerView: 4,
                    // initialSlide:3,
                    spaceBetween:10,
                    // centeredSlides: true,
                    // loop:true,
                    grabCursor:true
                },
                swiper:{}
            }
        },
        methods:{
            async getPartners(){
                let res=await this.$api.getMembers();
                this.organizations=(res || []).map(item=>{
                    return {
                        bg:item.picture,
                        name:item.businessName
                    }
                });
            }
        }
    }
</script>

<style lang="less" scoped>
    .partner-wrapper{
        .slide-content{
            position: relative;
            .bg{
                width: 100%;
                height: 0;
                padding-bottom: 62.5%;
                background-size: cover;
                background-position: center;
            }
            .label{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 35px;
                line-height: 35px;
                background-color: rgba(0,0,0,.2);
                color: #fff;
            }
        }
    }

</style>