<template>
    <div class="policy-req-wrapper">
        <div class="flex-box">
            <div class="unit" v-for="(item,index) in list" :key="index">
                <div class="icon bg-cc hover-point" :style="`background-image:url(${require('../../assets/'+item.icon)})`"
                     @click="reqPolicy(item.label)"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PolicyReq",
        data(){
            return {
                list:[
                    {
                        icon:'registration-policy-tax.png',
                        label:'税收政策'
                    },
                    {
                        icon:'registration-policy-social.png',
                        label:'社保政策'
                    },
                    {
                        icon:'registration-policy-help.png',
                        label:'扶持政策'
                    },
                    {
                        icon:'registration-policy-lend.png',
                        label:'房租政策'
                    },
                    {
                        icon:'registration-policy-debt.png',
                        label:'贷款政策'
                    },
                    {
                        icon:'registration-policy-right.png',
                        label:'知识产权'
                    }
                ]
            }
        },
        methods:{
            reqPolicy(service){
                let title=`公司注册-政策资讯-${service}`;
                window.triggleZc(title,true);
            }
        }
    }
</script>

<style lang="less" scoped>
    .policy-req-wrapper{
        box-sizing: border-box;
        width: 1200px;
        padding: 0 80px;
        margin: 0 auto;
        .flex-box{
            width: 100%;
            flex-wrap: wrap;
        }

        .unit{
            width: 150px;
            height: 150px;
            margin: 0 98px 40px;
            border-radius: 50%;
            color: #fff;
            .icon{
                display: inline-block;
                width: 150px;
                height: 150px;

                &:hover{
                    transform: scale(1.1);
                    transition: transform .5s;
                    opacity: 1;
                }
            }
        }
    }
</style>