import axios from 'axios';

class HttpConnect{
    constructor() {

    }

    authenticate() {
        this._instance = axios.create({
            baseURL: 'https://www2.fzzhs.cn:8011',
            // headers: {
            //     'Content-Type': this._options.contentType,
            //     Authorization: this._options.authorization
            // },
            timeout: 1200000
        });
        this._instance.interceptors.response.use(function (res) {
            let resData=res.data;
            return resData.code ? Promise.reject(resData.msg) : resData.data;
        }, function (error) {
            return Promise.reject(error);
        });

    }

    async getData(endpoint,params) {
        await this.authenticate();
        return this._instance.get(endpoint,{
            params:params
        })
    }

    async postData(endpoint, data) {
        await this.authenticate();
        return this._instance.post(endpoint,data)
    }
}

export default HttpConnect