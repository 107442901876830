<template>
    <div class="the-advertisement-wrapper">
        <div class="cover">
            <span class="btn hover-point" @click="navAd">点击了解详情</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Advertisement",
        methods:{
            navAd(){
                let title='专属VIP银行开户通道';
                window.triggleZc(title,true);
            }
        }
    }
</script>

<style lang="less">
    .the-advertisement-wrapper{
        width: 100%;
        height: 333px;
        margin-top: 60px;
        background-image: url("../../assets/registration-ad.png");
        background-position: center;
        background-size: cover;
        .cover{
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,.3);
            color:#fff;
            opacity: 0;
            .btn{
                display: inline-block;
                width: 180px;
                height: 40px;
                margin-top: 150px;
                border: 1px solid #fff;
                border-radius: 5px;
                line-height: 40px;
            }
        }
        &:hover{
            .cover{
                opacity: 1;
            }
        }
    }
</style>