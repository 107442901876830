<template>
    <div class="order-wrapper">
        <div class="tabs">
            <div class="tab" v-for="(tab,index) in list" :key="index"
                 :class="{'tab-active':index===cTab}" @click="cTab=index">{{tab.label}}</div>
        </div>
        <div class="tabs-content">
            <span class="tip-triangle" :style="{left:list[cTab].tipLeft}"></span>
            <div class="order-box">
                <div class="info-box" v-show="list[cTab].name==='book'">
                    <div class="city-picker">
                        <a-select :options="citiesConfig" style="width: 130px" v-model="userInfo.addr"></a-select>
                    </div>
                    <div class="input-box">
                        <a-input v-model="userInfo.phone" placeholder="请输入您的电话号码" :max-length="11" style="width: 210px"/>
                    </div>
                    <div class="input-box">
                        <a-input v-model="userInfo.name" placeholder="请输入您的称呼" style="width: 210px"/>
                    </div>
                </div>
                <div class="info-box" v-show="list[cTab].name==='register'">
                    <div class="city-picker">
                        <a-select :options="citiesConfig" style="width: 130px" v-model="userInfo.addr"></a-select>
                    </div>
                    <div class="input-box">
                        <a-input v-model="userInfo.companyCode" placeholder="请输入企业号" style="width: 170px"/>
                    </div>
                    <div class="input-box">
                        <a-popover trigger="click" placement="bottom">
                            <template slot="content">
                                <div class="type-container">
                                    <div class="type-unit" v-for="(type,ti) in industryType" :key="ti">
                                        <div class="label">{{type.label}}</div>
                                        <span v-for="(item,index) in type.list" :key="index" @click="selectIndustry(item)">{{item}}</span>
                                    </div>
                                </div>
                                <div><a-icon type="exclamation-circle" style="color: #EA9534" />如果没有找到您想要的行业，可以选择直接输入</div>
                            </template>
                            <a-input placeholder="所属行业" style="width: 130px" v-model="userInfo.industry"/>
                        </a-popover>
                    </div>
                    <div class="input-box">
                        <a-select :options="companyType" style="width: 130px" v-model="userInfo.companyType"></a-select>
                    </div>
                </div>
                <div class="info-box" v-show="list[cTab].name==='tax'">
                    <div>
                        <a-input v-model="userInfo.sales" placeholder="含税销售额" style="width: 480px" type="number"/>
                    </div>
                    <div class="input-box">
                        <a-select :options="taxGroup" style="width: 130px" v-model="userInfo.tax"></a-select>
                    </div>
                </div>
                <div class="info-box" v-show="list[cTab].name==='saveTax'">
                    <div>
                        <a-input v-model="userInfo.yearSales" placeholder="年含销售额（元）" style="width: 150px"/>
                    </div>
                    <div class="input-box">
                        <a-input v-model="userInfo.yearProfit" placeholder="年净利润（元）" style="width: 140px"/>
                    </div>
                    <div class="input-box">
                        <a-input v-model="userInfo.divide" placeholder="分红（选填）" style="width: 140px"/>
                    </div>
                    <div class="input-box">
                        <a-select :options="taxIdentify" style="width: 140px" v-model="userInfo.taxIdentify"></a-select>
                    </div>
                </div>
                <div class="info-box" v-show="list[cTab].name==='incomeTax'">
                    <div>
                        <a-input v-model="userInfo.companyProfit" placeholder="企业利润总额（元）" style="width: 480px" type="number"/>
                    </div>
                    <div class="input-box">
                        <a-select :options="companyTaxType" style="width: 130px" v-model="userInfo.companyTaxType"></a-select>
                    </div>
                </div>
                <div class="orderBtn hover-point" @click="handleAction(list[cTab])">{{list[cTab].btnMsg}}</div>
            </div>
        </div>
        <div class="modal-box">
            <z-modal :config="list[cTab]">
                <template v-slot:header>
                    <div v-if="list[cTab].name==='book'">
                        <div class="book-title">您的申请我们已经收到</div>
                        <div class="book-title-desc">请耐心等候，我们将第一时间与您联系！</div>
                    </div>
                    <div v-else class="calc-title">测算结果</div>
                </template>
                <div v-if="list[cTab].name==='book'">
                    <img src="../../assets/wx-gzh.png">
                    <div>扫码关注公众号，为您的财税保驾护航</div>
                    <div class="modal-btn hover-point" @click="list[cTab].showModal=false">好的</div>
                </div>
                <div v-if="list[cTab].name==='tax'">
                    <div>您应缴纳的增值税及附加为：</div>
                    <div class="notice-num">{{list[cTab].modalData}}元</div>
                    <div style="color: #999">注：您的销售金额已含税</div>
                    <div class="modal-btn hover-point" @click="list[cTab].showModal=false">好的</div>
                </div>
                <div v-if="list[cTab].name==='saveTax'">
                    <!--<div style="margin: 15px 0">您目前应缴纳的增值税约为：<text style="color: #f00">7,353.00元</text></div>-->
                    <div class="notice-text">我们将至少为您节税{{list[cTab].modalData}}%</div>
                    <div class="modal-btn hover-point" @click="list[cTab].showModal=false">好的</div>
                </div>
                <div v-if="list[cTab].name==='incomeTax'">
                    <div>您应缴纳的企业所得税测算结果为：</div>
                    <div class="notice-num">{{list[cTab].modalData}}元</div>
                    <div style="color: #999">注：精准税额可能因具体情况有所变化</div>
                    <div class="modal-btn hover-point" @click="list[cTab].showModal=false">好的</div>
                </div>
            </z-modal>
        </div>
    </div>
</template>

<script>
    import ZModal from "../ZModal";
    import {Cascader,Input,Popover,Select} from 'ant-design-vue';

    export default {
        name: "TheOrders",
        props:{
            list:{
                type:Array,
                default:()=>[]
            }
        },
        components: {
            [Cascader.name]: Cascader,
            [Input.name]: Input,
            [Popover.name]: Popover,
            [Select.name]: Select,
            ZModal
        },
        data(){
            return {
                cTab:0,
                userInfo:{
                    addr:'福州',
                    phone:'',
                    name:'',
                    companyCode:'',
                    industry:'',
                    companyType: '有限责任公司',
                    sales:'',
                    tax:13,
                    yearSales:'',
                    yearProfit:'',
                    divide:'',
                    taxIdentify:3,
                    companyProfit:'',
                    companyTaxType:25
                },
                industryType:[
                    {
                        label:'科技类',
                        list:['网络科技','生物医药科技','信息科技','通信科技','新能源科技','自动化科技','激光科技','新材料','游戏科技','海洋科技','其他科技']
                    },
                    {
                        label:'服务类',
                        list:['生活服务','企业服务','翻译服务','礼仪服务','劳动服务','教育服务','旅游服务','运输服务','居民服务','租赁服务','其他服务']
                    },
                    {
                        label:'管理类',
                        list:['企业管理','酒店管理','投资管理','餐饮管理','物业管理','人力资源管理','商业管理']
                    },
                    {
                        label:'咨询类',
                        list:['战略咨询','IT咨询','创新设计咨询','财务咨询','技术咨询','商务咨询']
                    },
                    {
                        label:'其他',
                        list:['建筑装潢','实业','贸易','软件开发','批发采购','农林牧渔','工程','采矿业','生产和供应业']
                    }
                ],
                companyType: [
                    {
                        label:'有限责任公司',
                        value:'有限责任公司'
                    },
                    {
                        label:'股份有限公司',
                        value:'股份有限公司'
                    },
                    {
                        label:'合伙企业',
                        value:'合伙企业'
                    },
                    {
                        label:'有限公司',
                        value:'有限公司'
                    }
                ],
                taxGroup:[
                    {
                        label:'13%',
                        value:13
                    },
                    {
                        label:'9%',
                        value:9
                    },
                    {
                        label:'6%',
                        value:6
                    },
                    {
                        label:'5%',
                        value:5
                    },
                    {
                        label:'3%',
                        value:3
                    },
                    {
                        label:'1%',
                        value:1
                    }
                ],
                taxIdentify: [
                    {
                        label:'小规模纳税人',
                        value:3
                    },
                    {
                        label:'一般纳税人',
                        value:6
                    }
                ],
                companyTaxType:[
                    {
                        label:'普通居民企业',
                        value:25
                    },
                    {
                        label:'小型微利企业',
                        value:''
                    },
                    {
                        label:'高新企业',
                        value:15
                    },
                    {
                        label:'其他',
                        value:10
                    }
                ]
            }
        },
        computed:{
            citiesConfig(){
                return this.$store.state.cities.map(item=>{
                    return {
                        label:item.cityName,
                        value:item.cityName
                    }
                })
            }
        },
        methods:{
            selectIndustry(item){
                this.userInfo.industry=item;
            },
            handleAction(item){
                item.callback(this.userInfo,item);
            }
        }
    }
</script>

<style lang="less" scoped>
    .order-wrapper{
        @active-color:#fff;
        .tabs{
            display: flex;
            font-size: 16px;
            margin-bottom: 20px;
            .tab{
                margin-right: 30px;
                color: #E4E4E4;

                &:hover{
                    color: @active-color;
                    cursor: pointer;
                }
            }
            .tab-active{
                color: @active-color;
            }
        }
        .tabs-content{
            position: relative;
            box-sizing: border-box;
            width: 840px;
            .tip-triangle{
                position: absolute;
                top: -7px;
                left: 0;
                border-right: 9px solid transparent;
                border-left: 9px solid transparent;
                border-bottom: 9px solid #fff;
            }
            .order-box{
                display: flex;
                width: 100%;
                height: 60px;
                border-radius: 3px;
                background-color: #fff;
                overflow: hidden;
                .info-box{
                    flex: 1;
                    display: flex;
                    box-sizing: border-box;
                    padding:14px;
                    overflow: hidden;
                    &>div{
                        box-sizing: border-box;
                        border-right: 1px solid #999;

                        &:last-child{
                            border-right: 0;
                        }

                    }

                    .city-picker{
                        padding-right: 10px;
                        width: 220px;
                    }

                    .input-box{
                        padding-left: 25px;
                    }
                }
                .orderBtn{
                    width: 155px;
                    height: 60px;
                    line-height: 60px;
                    background-color: #EE6760;
                    font-size: 18px;
                    color: #fff;
                }
            }
        }
        .modal-box{
            font-size: 16px;
            line-height: 1.5;
            .book-title{
                padding-top: 6px;
                margin-left: 25px;
                font-size: 20px;
            }
            .book-title-desc{
                margin-left: 25px;
            }
            .calc-title{
                text-align: center;
                line-height: 70px;
                font-size: 24px;
            }
            .notice-num{
                margin: 20px 0;
                font-size: 34px;
                color: #f00;
            }
            .notice-text{
                padding-bottom: 20px;
                font-size: 26px;
                color: @primary-color;
            }
            .modal-btn{
                width: 277px;
                height: 50px;
                margin: 20px auto 0;
                line-height: 50px;
                background: @primary-color;
                border-radius: 10px;
                font-size: 20px;
                color: #fff;
            }
        }
    }
    .type-container{
        width: 1010px;
        .type-unit{
            margin-bottom: 10px;
            height: 26px;
            line-height: 26px;
            span{
                display: inline-block;
                margin-right: 24px;
                cursor: pointer;

                &:hover{
                    color: #EA9534;
                }

            }

            .label{
                display: inline-block;
                margin-right: 24px;
                width: 64px;
                text-align: center;
                background-color: #e8effc;
            }
        }

    }
</style>