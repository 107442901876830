<template>
    <div class="apply-wrapper">
        <a-form-model ref="applyForm" :model="form" :label-col="{span: 3}" :wrapperCol="{span: 17,offset:1}" :rules="rules">
            <a-form-model-item label="地址">
                <a-select v-model="form.addr" :options="citiesConfig" style="width: 390px"></a-select>
                <!--<a-cascader :options="citiesConfig" :allowClear="false" v-model="form.addr"/>-->
            </a-form-model-item>
            <a-form-model-item label="公司名称">
                <a-input v-model="form.companyName" placeholder="请输入您的公司名称"/>
            </a-form-model-item>
            <a-form-model-item label="姓名">
                <a-input v-model="form.userName" placeholder="请输入您的称呼"/>
            </a-form-model-item>
            <a-form-model-item label="手机号" prop="phone">
                <a-input v-model="form.phone" placeholder="请输入您的号码"/>
            </a-form-model-item>
            <a-form-model-item label="业务内容">
                <a-input v-model="form.content" placeholder="请输入您公司的主营业务"/>
            </a-form-model-item>
        </a-form-model>
        <a-button type="primary" shape="round" @click="onSubmit">加入智会算</a-button>
        <c-z-modal :config="modalConfig"></c-z-modal>
    </div>
</template>

<script>
    /*import citiesConfig from '@/config/proviceCity.json';*/
    import {FormModel,Cascader,Input,Button,Select} from 'ant-design-vue';
    import CZModal from '@/components/common/CZModal';
    const FormModelItem=FormModel.Item;
    export default {
        name: "Apply",
        components:{
            [FormModel.name]: FormModel,
            [FormModelItem.name]: FormModelItem,
            [Input.name]: Input,
            [Select.name]: Select,
            [Cascader.name]: Cascader,
            [Button.name]: Button,
            CZModal
        },
        data(){
            let phoneCheck=(rule, value, callback)=>{
                if(!(/^[1][3,4,5,7,8][0-9]{9}$/.test(value))){
                    callback(new Error('请填写正确的手机号码'))
                }else{
                    callback()
                }
            };
            return {
                /*citiesConfig:citiesConfig,*/
                form:{
                    addr:'福州',
                    companyName:'',
                    userName:'',
                    phone:'',
                    content:''
                },
                rules:{
                    phone:[
                        {required: true, message: '请填写手机号',trigger: 'blur'},
                        {validator: phoneCheck, trigger: 'blur'}
                    ]
                },
                modalConfig:{
                    showModal:false,
                    hideHeader:true
                }
            }
        },
        computed:{
            citiesConfig(){
                return this.$store.state.cities.map(item=>{
                    return {
                        label:item.cityName,
                        value:item.cityName
                    }
                })
            }
        },
        methods:{
            onSubmit(){
                this.$refs.applyForm.validate(async (valid) => {
                    if (valid) {
                        let form=this.form;
                        let data={
                            applicantName:form.userName,
                            applicantTel:form.phone,
                            cityCode:form.addr,
                            companyName:form.companyName,
                            serviceContent:form.content,
                            serviceName:'加盟我们'
                        }
                        let res=await this.$api.toApplyService(data);
                        if(res===undefined){
                            this.modalConfig.showModal=true;
                        }
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>

<style lang="less" scoped>
    .apply-wrapper{
        padding-bottom: 50px;
        /deep/.ant-form-explain{
            text-align: left;
        }
    }

    /*.ant-form-item{*/
    /*    margin-bottom: 18px;*/
    /*}*/
</style>