<template>
    <div class="the-progress-wrapper">
        <div class="the-progress-title">{{data.title}}</div>
        <div class="the-progress-content">
            <div class="unit" v-for="(item,index) in data.list" :key="index">
                <span class="tag"></span>
                <span class="index">{{index+1}}</span>
                <div class="unit-title">{{item.title}}</div>
                <div class="unit-content">{{item.content}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TheProgress",
        props:{
            data:{
                type:Object,
                default:()=>{
                    return {title:'', list:[]}
                }
            }
        },
        data(){
            return {}
        }
    }
</script>

<style lang="less" scoped>
    .the-progress-wrapper{
        box-sizing: border-box;
        padding: 40px 70px;
        height: 580px;
        .the-progress-title{
            margin-bottom: 50px;
            font-size: 22px;
        }
        .the-progress-content{
            display: flex;
            flex-wrap: wrap;
            justify-items: center;
            .unit{
                box-sizing: border-box;
                padding: 20px;
                position: relative;
                width: 170px;
                height: 190px;
                margin-right: 50px;
                margin-bottom: 30px;
                box-shadow: 0 2px 4px 1px rgba(0,0,0,.1);

                &:nth-child(3n){
                    margin-right: 0;
                }

                .tag{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 0;
                    height: 0;
                    border-top: 40px solid @primary-color;
                    border-right: 30px solid transparent;
                }
                .index{
                    position: absolute;
                    left: 3px;
                    top: 2px;
                    color: #fff;
                }

                .unit-title{
                    position: relative;
                    margin: 20px 0;
                    color: #000;
                    &:after{
                        position: absolute;
                        content: '';
                        width: 30px;
                        height: 2px;
                        left: 50%;
                        bottom: -5px;
                        margin-left: -15px;
                        background-color: @primary-color;
                    }
                }

                .unit-content{
                    color: #656565;
                    font-size: 14px;
                    text-align: left;
                }
            }
        }
    }
</style>