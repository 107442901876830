<template>
    <div class="news-view-wrapper">
        <div class="banner bg-cc"></div>
        <div class="safe-content">
            <div class="tabs">
                <div class="tab hover-point" v-for="(tab,index) in tabs"
                     :key="index"
                     :class="{'tab-active':tab.id===cId}"
                     @click="changeTab(tab.id)">{{tab.label}}
                </div>
            </div>
            <div class="tabs-content">
                <div class="news-box" v-for="(item,index) in newsList" :key="index">
                    <router-link :to="`/news/${item.id}`">
                        <img :src="item.img">
                    </router-link>
                    <div class="news-info">
                        <router-link :to="`/news/${item.id}`">
                            <div class="news-title">{{item.title}}</div>
                        </router-link>
                        <div class="news-summary">{{item.summary}}</div>
                        <div class="time">{{item.publishTime}}</div>
                    </div>
                </div>
            </div>
            <a-pagination v-show="newsList.length>0" class="pagination" v-model="page" :total="total" :pageSize="pageSize" @change="changePage"/>
        </div>
    </div>
</template>

<script>
    import {Pagination} from 'ant-design-vue';
    export default {
        name: "News",
        components:{
            [Pagination.name]:Pagination
        },
        data() {
            return {
                cId: '',
                tabs: [],
                newsList:[],
                total:0,
                page:1,
                pageSize:10
            }
        },
        async created() {
            await this.getNewsType();
            let id=this.$route.query.id;

            // 无参数情况
            if(!id){
                this.getNews();
                return false;
            }

            // 有参数情况，匹配id
            this.tabs.some(tab=>{
                if(tab.id===id){
                    console.log(tab.id===id)
                    this.cId=id;
                    return true;
                }
            });
            this.getNews();

        },
        methods:{
            async getNewsType(){
              let res = await this.$api.getNewsType();
              let tabs=[{id:'',label:'全部资讯'}];
              let resData=(res || []).map(item=>{
                  return {
                      id:item.newsMoldGuid,
                      label:item.newsMoldName
                  }
              });
              this.tabs=tabs.concat(resData);
            },
            async getNews(page=1){
                let res = await this.$api.getNews({id:this.cId,page});
                this.total=res.total;
                this.page=res.pageNum;
                this.newsList=(res.list || []).map(item=>{
                    return {
                        id:item.guid,
                        img:item.picture,
                        publishTime:item.publishTime,
                        title:item.newsContentName,
                        summary:item.summary
                    }
                })
            },
            changeTab(id){
                if(!id && this.cId){
                    this.$router.push({query:{}});
                }else if(this.cId===id){
                    return false;
                }else{
                    this.$router.push({query:{id}});
                }
                this.cId=id;
                this.getNews();
            },
            changePage(index){
                this.getNews(index);
            }
        }
    };
</script>
<style lang="less">
    .news-view-wrapper {
        background-color: #F5FAFE;
        .banner {
            background-image: url("../assets/news-banner.png");
        }

        .tabs {
            float: right;
            box-sizing: border-box;
            padding: 15px 0;
            margin-top: -54px;
            border-radius: 10px 10px 0 0;
            background-color: #fff;
            font-size: 16px;

            .tab {
                display: inline-block;
                padding: 0 20px;
                border-right: 2px solid #CBCFD2;
                color: #212121;

                &:last-child {
                    border-right: none;
                }
            }

            .tab-active {
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    width: 90%;
                    height: 3px;
                    left: 6%;
                    bottom: -10px;
                    background-color: @primary-color;
                }
            }
        }

        .tabs-content {
            box-sizing: border-box;
            min-height: 400px;
            padding-top: 50px;
            .news-box{
                display: flex;
                box-sizing: border-box;
                padding: 20px;
                border-bottom: 1px solid #EFEFEF;
                background-color: #fff;

                &:last-child{
                    border-bottom: none;
                }
                img{
                    width: 320px;
                    height: 180px;
                }
                .news-info{
                    flex: 1;
                    box-sizing: border-box;
                    padding: 10px 30px 0 40px;
                    text-align: left;
                    color: #999;
                    .news-title{
                        margin-bottom: 20px;
                        font-size: 16px;
                        font-weight: bold;
                        color: #000;
                    }
                    .news-summary{
                        height: 90px;
                    }
                    .time{
                        margin: 12px 0;
                    }
                }
            }
        }

        .pagination{
            padding: 20px 0;
        }
    }
</style>
