<template>
    <div class="footer-wrapper">
        <div class="footer-sub">
            <div class="safe-content">
                <div class="cities">
                    <div class="title">城市服务</div>
                    <div>
                        <span @click="navToSite(item.provinceEnglishName)" v-for="(item,index) in cities" :key="index">{{item.cityName}}</span>
                    </div>
                </div>
                <div class="advantages">
                    <div class="advantage" v-for="(item,index) in Config.advantages" :key="index">
                        <div class="icon-box" :style="`background-image:url(${require('../assets/'+item.icon)})`"></div>
                        <div class="info">
                            <div class="title">{{item.title}}</div>
                            <p>{{item.desc1}}</p>
                            <p>{{item.desc2}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="footer-nav safe-content">
                <div class="footer-menus footer-contain">
                    <div class="menu" v-for="(menu,index) in Config.menus" :key="index">
                        <router-link v-if="menu.name" class="title" :to="{name: menu.name}">{{menu.label}}</router-link>
                        <div v-else class="title">{{menu.label}}</div>
                        <div class="footer-submenu">
                            <div v-for="(sub,si) in menu.sub" :key="`${index}-${si}`">
                                <router-link class="sub" v-if="sub.param" :to="{name: sub.name,params:{id:sub.paramVal}}">{{sub.label}}</router-link>
                                <router-link class="sub" v-else :to="{name: sub.name}">{{sub.label}}</router-link>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="divide"></div>
                <div class="contact-box footer-contain">
                    <div class="attention">
                        <div class="title">关注抖音</div>
                        <div class="desc">财税小知识</div>
                        <img src="@/assets/douyin.png">
                    </div>
                    <div class="attention">
                        <div class="title">关注服务号</div>
                        <div class="desc">安全账健康税</div>
                        <img src="@/assets/wx-gzh-new.png">
                    </div>
                    <!--<div class="attention">
                        <div class="title">关注小程序</div>
                        <div class="desc">财税业务小锦囊</div>
                        <img src="@/assets/wx-xcx.png">
                    </div>-->
                    <div class="contact">
                        <div>
                            <div class="notice">400-168-5560</div>
                            <div>咨询热线</div>
                        </div>
                        <div>
                            <div class="notice">400-168-5560</div>
                            <div>投诉热线</div>
                        </div>
                        <div>
                            <div class="notice">8:30 - 21:00</div>
                            <div>随时服务</div>
                        </div>
                    </div>
                </div>
            </div>
            <p class="web-info"><a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">闽ICP备18026488号-1</a>丨Copyright©2017-2021 福建智会算信息科技有限公司</p>
        </div>
    </div>
</template>

<script>
    import Config from '../config/footer.json';
    export default {
        name: 'AppFooter',
        components: {
        },
        props: {},
        data(){
            return {
                Config:Config,
                cities:[]
            }
        },
        async created(){
            await this.getCities();
        },
        methods: {
            async getCities(){
                this.cities=await this.$api.getCities();
                this.$store.commit('changeStateVal',{key:'cities',value:this.cities})
            },
            navToSite(provice){
                if(provice!==this.$route.query.provice){
                    this.$router.push({ name: 'contact',query: {provice:provice}})
                }
                /*if(this.$route.params.site!==site){
                    this.$store.commit('changeStateVal',{key:'site',value:site});
                    setTimeout(()=>{
                        this.$router.push({ name: 'indexSecondary',params:{site:site},query: {}})
                    },500)
                }*/

            }

        },
        watch:{
        }
    }
</script>

<style lang="less" scoped>
    .footer-wrapper {
        .footer-sub{
            text-align: left;
            .cities{
                box-sizing: border-box;
                padding-top: 50px;
                padding-bottom: 30px;
                .title{
                    margin-bottom: 20px;
                    font-size: 24px;
                    color: #333;
                }
                span{
                    display: inline-block;
                    margin-right: 30px;
                    color: #656565;
                    cursor: pointer;
                }
            }
            .advantages{
                display: flex;
                box-sizing: border-box;
                padding: 30px 0;
                border-top: 1px solid #E0E0E0;
                background-color: #fff;
                .advantage{
                    flex: 1;
                    display: flex;
                    align-items: center;
                    .icon-box{
                        width: 47px;
                        height: 40px;
                        margin-left: 24px;
                    }
                    .info{
                        flex: 1;
                        margin-left: 12px;
                        .title{
                            margin-bottom: 10px;
                            font-size: 16px;
                            color: #343434;
                        }
                        p{
                            margin-bottom: 0;
                            line-height: 1.8;
                            color: #666666;
                        }
                    }
                }
            }
        }
        .footer{
            background-color: #595959;
            text-align: center;
            color: #ccc;
            .footer-nav{
                display: flex;
                padding: 35px 0 10px;
                border-bottom: 1px solid rgba(255,255,255,.2);
                .footer-contain{
                    display: flex;
                    box-sizing: border-box;
                    padding: 0 20px 0 70px;
                }
                .footer-menus{
                    flex: 1;
                    text-align: left;
                    .menu{
                        flex: 1;
                        .title{
                            display: block;
                            color: #fff;
                            margin-bottom: 15px;

                        }
                        .sub{
                            display: block;
                            margin-bottom: 10px;
                            color: #ccc;
                        }
                    }
                }
                .divide{
                    width: 1px;
                    height: 174px;
                    margin-top: 20px;
                    background-color: rgba(255,255,255,.2);
                }
                .contact-box{
                    width: 600px;
                    padding-left: 40px;
                    .attention{
                        padding-left: 20px;
                        text-align: center;
                        .title{
                            font-size: 18px;
                            color: #fff;
                        }
                        .desc{
                            margin-top: 4px;
                            margin-bottom: 12px;
                            font-size: 14px;
                        }
                        img{
                            width: 130px;
                            height: 130px;
                        }
                    }
                    .contact{
                        margin-left: 35px;
                        text-align: left;
                        &>div{
                            margin-bottom: 30px;
                        }
                        .notice{
                            font-size: 18px;
                            color: #fff;
                        }
                    }
                }
            }
            .web-info{
                padding: 20px 0;
                margin: 0;
                a{
                    color: #ccc;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
</style>