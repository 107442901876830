<template>
    <div class="modal-form-wrapper">
        <z-modal :config="formConfig" class="modal-box modal-box-wrapper">
            <a-form-model ref="applyForm" :model="form" :label-col="{span: 4}" :wrapperCol="{span: 16,offset:1}">
                <a-form-model-item v-if="!config.hideArr" label="地址">
                    <a-select v-model="form.addr" :options="citiesConfig"></a-select>
                </a-form-model-item>
                <a-form-model-item label="公司名称">
                    <a-input v-model="form.companyName" placeholder="请输入您的公司名称"/>
                </a-form-model-item>
                <a-form-model-item label="姓名">
                    <a-input v-model="form.userName" placeholder="请输入您的称呼"/>
                </a-form-model-item>
                <a-form-model-item label="手机号*" prop="phone">
                    <a-input v-model="form.phone" placeholder="请输入您的号码"/>
                </a-form-model-item>
            </a-form-model>
            <a-button type="primary" style="width: 150px" @click="onSubmit">提交</a-button>
        </z-modal>
        <c-z-modal :config="modalConfig"></c-z-modal>
    </div>

</template>

<script>
    import ZModal from "../ZModal";
    import CZModal from "./CZModal";
    import {FormModel,Cascader,Input,Button,Select} from 'ant-design-vue';
    const FormModelItem=FormModel.Item;
    export default {
        name: "CZModalForm",
        components:{
            [FormModel.name]: FormModel,
            [FormModelItem.name]: FormModelItem,
            [Input.name]: Input,
            [Select.name]: Select,
            [Cascader.name]: Cascader,
            [Button.name]: Button,
            ZModal,
            CZModal
        },
        props:{
            config:{
                type:Object,
                required:true
            },
            otherData:{
                type:Object,
                default:null
            }
        },
        data(){
            return {
                modalConfig:{
                    showModal:false,
                    hideHeader:true
                },
                form:{
                    addr:'福州',
                    userName:'',
                    phone:'',
                    companyName:''
                },
                formConfig:{
                    showModal:false,
                    hideHeader:true
                }
            }
        },
        computed:{
            citiesConfig(){
                return this.$store.state.cities.map(item=>{
                    return {
                        label:item.cityName,
                        value:item.cityName
                    }
                })
            }
        },
        methods:{
            async onSubmit(){
                let form=this.form;
                if(!/^[1][3,4,5,7,8][0-9]{9}$/.test(form.phone)){
                    this.$message.error('请填写正确的手机号');
                    return false
                }
                let data={
                    applicantName:form.userName,
                    applicantTel:form.phone,
                    cityCode:form.addr,
                    companyName:form.companyName
                }
                this.otherData && Object.assign(data,this.otherData);
                let res=await this.$api.toApplyService(data);
                if(res===undefined){
                    this.formConfig.showModal=false;
                    this.modalConfig.showModal=true;
                }
            }
        },
        watch:{
            'config.showModal'(newVal){
                console.log(newVal);
                this.formConfig.showModal = newVal ? true :false;
            }
        }
    }
</script>

<style lang="less">
    .modal-box-wrapper{
        .modal-icon{
            margin-bottom: 20px;
        }
        .modal-title{
            font-size: 24px;
        }
        .desc{
            color: #999;
        }
    }
</style>