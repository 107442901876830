<template>
    <div class="safe-content header-wrapper">
        <div class="page-header">
            <router-link :to="{name: 'index'}"><img class="logo" src="@/assets/logo.png"></router-link>
            <div class="header-contact">
                <div class="tel">
                    <img class="animate-icon" src="../assets/icon-phone-grey.png">400-168-5560
                </div>
                <div class="miniprogram other">
                    <img class="miniicon" src="../assets/icon-gzh.png">
                    <img class="contact-img" src="../assets/wx-gzh-new.png">
                </div>
                <div class="dy other">
                    <img class="miniicon" src="../assets/icon-douyin.png">
                    <img class="contact-img" src="../assets/douyin.png">
                </div>
            </div>
        </div>
        <div class="nav-box">
            <div class="menu-list">
                <a-dropdown :trigger="['click']"
                            :visible="showDropDown">
                    <a class="ant-dropdown-link" @click="e => e.preventDefault()">全部服务
                        <a-icon class="icon" type="menu" @click="handleDropDown"/>
                    </a>
                    <a-menu slot="overlay" class="header-menu" style="width: 250px">
                        <a-sub-menu v-for="(menu,index) in menus" :key="index">
                            <div class="submenu" slot="title">
                                <div class="title">
                                    <a-icon class="icon" :type="menu.icon || 'bank'" style="fontSize:22px;marginRight:10px" />{{menu.label}}
                                </div>
                                <div class="desc">{{menu.desc}}</div>
                            </div>
                            <a-sub-menu class="hide-menu-icon">
                                <div slot="title">
                                    <div class="detail-menu-box">
                                        <div class="title">{{menu.title}}</div>
                                        <div class="detail">
                                            <router-link class="unit" v-for="(sub,si) in menu.subMenu" :key="`${index}-${si}`"
                                                         :to="{name: 'service',query: {id: sub.id}}">{{sub.label}}</router-link>
                                        </div>
                                    </div>
                                </div>
                            </a-sub-menu>
                        </a-sub-menu>
                    </a-menu>
                </a-dropdown>
            </div>
            <div class="tabs">
                <router-link class="tab" v-for="(tab,index) in Config.tabs"
                             :key="index"
                             :class="{'tab-active':tab.name===cRoute}"
                             :to="{name: tab.name}">{{tab.label}}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import Config from '../config/header.json';
    import {Dropdown, Icon, Menu} from 'ant-design-vue';

    const SubMenu = Menu.SubMenu;
    export default {
        name: 'AppHeader',
        components: {
            [Dropdown.name]: Dropdown,
            [Icon.name]: Icon,
            [Menu.name]: Menu,
            [SubMenu.name]: SubMenu
        },
        props: {
            showMenus:{
                type:Number
            }
        },
        data(){
            return {
                showDropDown:true,
                Config:Config,
                menus:[],
                cRoute:'index'
            }
        },
        mounted(){
            // 设置初始路由
            this.cRoute=this.$route.matched[0] && this.$route.matched[0].name || this.$route.name;
            this.$store.commit('changeStateVal',{key:'isSpinning',value:true});
            this.getMenus();
            this.$store.commit('changeStateVal',{key:'isSpinning',value:false});

            // 设置初始是否展示菜单
            // this.showDropDown = this.showMenus===0;  只展示首页，暂时要关闭
            this.showDropDown = false;
        },
        methods: {
            async getMenus(){
                let res=await this.$api.getHeaderMenus();
                this.menus=res;
                this.$store.commit('changeStateVal',{key:'headerMenus',value:res});
            },
            handleDropDown(){
                this.showDropDown=!this.showDropDown;
            }
        },
        watch:{
            '$route'(to){
                // TODO: 辣鸡设计上可能会有一些页面既有头，还要选中，可能要进额外判断
                this.cRoute=to.matched[0].name;
            },
            'showMenus'(val){
                if(val){
                    this.showDropDown=false;
                }else{
                    this.showDropDown=true;
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .header-wrapper {
        text-align: left;
        @keyframes triggle{
            25%{
                transform: rotate(10deg);
            }
            50%{
                transform: rotate(-10deg);
            }
            75% {
                transform: rotate(10deg);
            }
            100% {
                transform: rotate(-10deg);
            }
        }
        .page-header{
            .logo{
                width: 180px;
                margin: 10px 0;
            }
            .header-contact{
                float: right;
                display: flex;
                align-items: center;
                height: 80px;
                font-size: 18px;
                .animate-icon{
                    width: 24px;
                    height: 24px;
                    margin-top: -2px;
                    margin-right: 5px;
                    animation: triggle 1s linear infinite;
                }
                .other{
                    position: relative;
                    margin-left: 35px;
                    .miniicon{
                       position: absolute;
                        top: -14px;
                        left: -20px;
                        width: 26px;
                        height: 26px;
                        animation: triggle 1s linear infinite;
                    }
                    .contact-img{
                        width: 48px;
                        height: 48px;
                    }
                }
                .dy{
                    .miniicon{
                        left: -15px;
                    }
                }
            }
        }

        .nav-box{
            display: flex;
            width: 100%;
            height: 60px;

            .menu-list {
                width: 250px;
                .ant-dropdown-link {
                    display: inline-block;
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    line-height: 60px;
                    padding-left:40px;
                    background-color: @primary-color;
                    color: #fff;
                    font-size: 20px;

                    .icon {
                        margin-left: 85px;
                    }
                }
                .header-menu{
                    .submenu{
                        .desc{
                            color: #E5E5E5;
                            font-size: 14px;
                        }
                    }
                }
            }

            .tabs{
                flex: 1;
                padding-left: 45px;
                padding-top: 16px;
                font-size: 18px;
                .tab{
                    padding: 0 15px;
                    color: #333333;

                    &:hover{
                        color: @primary-color;
                    }
                }
                .tab-active{
                    color: @primary-color;
                }
            }
        }

    }

    .ant-dropdown{
        .ant-dropdown-menu-vertical{
            box-sizing: border-box;
            padding-top: 20px;
            padding-bottom: 0;
            border-radius: 0;
            margin-top: -4px;
            line-height: 2;
            background-color: rgba(0, 0, 0, 0.48);
        }
        .detail-menu-box{
            box-sizing: border-box;
            padding-left: 8px;
            padding-bottom: 20px;
            width: 440px;
            .title{
                padding: 15px 0;
                border-bottom: 1px solid #DCDBDA;
                &:before{
                    display: inline-block;
                    content: '';
                    width: 3px;
                    height: 11px;
                    margin-right: 20px;
                    background-color: #4E4B45;
                }
            }
            .detail{
                overflow: hidden;
                .unit{
                    float: left;
                    box-sizing: border-box;
                    padding-right: 10px;
                    padding-top: 15px;
                    min-width: 140px;
                    font-size: 14px;
                    color: #666;
                    &:before{
                        display: inline-block;
                        content: '';
                        width: 1px;
                        height: 11px;
                        margin-right: 10px;
                        background-color: #A6A5A2;
                    }

                    &:hover{
                        color: @primary-color;
                    }
                }
            }
        }

    }
</style>