<template>
    <div class="the-advantages-wrapper">
        <div class="advantages-title">{{data.title}}</div>
        <div class="advantages-contain">
            <div class="unit" v-for="(item,index) in data.list" :key="index">
                <div class="unit-title">{{item.title}}</div>
                <div class="unit-content" v-html="item.content"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TheAdvantages",
        props:{
            data:{
                type:Object,
                default:()=>{
                    return {title:'', list:[]}
                }
            }
        },
        data(){
            return {}
        }
    }
</script>

<style lang="less" scoped>
    .the-advantages-wrapper{
        box-sizing: border-box;
        height: 520px;
        padding: 60px 70px 0;
        .advantages-title{
            margin-bottom: 46px;
            font-size: 22px;
        }
        .advantages-contain{
            display: flex;
            flex-wrap: wrap;
            .unit{
                box-sizing: border-box;
                width: 150px;
                height: 150px;
                padding: 36px 14px 0;
                border-radius: 50%;
                margin-bottom: 40px;
                margin-right: 80px;
                color: #fff;
                text-align: left;
                background-color: @primary-color;

                &:nth-child(3n){
                    margin-right: 0;
                }

                .unit-title{
                    padding-bottom: 8px;
                    border-bottom: 2px solid #fff;
                    margin-bottom: 8px;
                    text-align: center;
                    font-size: 20px;
                }
                .unit-content{
                    box-sizing: border-box;
                    padding: 0 4px;
                    font-size: 12px;
                }
            }
        }
    }
</style>