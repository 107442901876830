<template>
    <div class="knowledge-wrapper bg-cc"
         :style="`background-image:url(${require('../../assets/registration-knowledge-bg.png')})`">
        <div class="safe-content">
            <a-tabs class="tabs" default-active-key="1" :tabBarGutter="130">
                <a-tab-pane key="1" tab="注册公司要做的">
                    <div class="tab-content flex-box reg-need">
                        <div class="flex-unit" v-for="(need,ni) in regNeedArr" :key="ni">
                            <div>{{need.title}}</div>
                            <div class="flex-box classify-box">
                                <div class="bg-cc" v-for="(item,index) in need.list" :key="`${ni}-${index}`"
                                     :style="`background-image:url(${require('../../assets/registration-tabs-need'+item+'.png')})`"></div>
                            </div>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="服务流程">
                    <div class="tab-content progress">
                        <div style="font-size: 18px">公司注册流程</div>
                        <div class="desc">【省时】最快3天拿证    【省力】摆脱繁复，让创业更简单</div>
                        <div class="flex-box">
                            <div class="flex-unit" v-for="(item,index) in progress" :key="index">
                                <div class="bg-cc"
                                     :style="`background-image:url(${require('../../assets/registration-tabs-progress'+index+'.png')})`"></div>
                                <div class="label">{{item.label}}</div>
                                <div>{{item.days}}个工作日</div>
                            </div>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="3" tab="您能拿到的资料">
                    <div class="tab-content materail">
                        <div style="font-size: 18px">您能拿到以下资料</div>
                        <div class="desc">办理营业执照是开公司的第一步，高效便捷，放心可靠</div>
                        <div>
                            <div class="unit" v-for="(item,index) in materail" :key="index">
                                <div class="bg-cc"
                                     :style="`background-image:url(${require('../../assets/registration-tabs-materail'+index+'.png')})`"></div>
                                <div>{{item}}</div>
                            </div>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="4" tab="售后保障">
                    <div class="tab-content after-sale">
                        <div class="flex-box">
                            <div class="label">我们承诺</div>
                            <div class="flex-unit">关于服务质量的反馈，我们会第一时间专人处理。保证及时解决您的问题。</div>
                        </div>
                        <div class="flex-box">
                            <div class="label">消费保障</div>
                            <div class="flex-unit">用户享全方位服务保障，因平台过失、差错及疏忽行为导致用户直接经济损失，依法判决、裁定的平台责任或连带责任，<br/>将由平台在限额内进行退款或赔付。</div>
                        </div>
                        <div class="flex-box">
                            <div class="label">联系渠道</div>
                            <div class="flex-unit">1、登陆智会算官网留言反馈<br/>2、拨打 4001685560 进行举报投诉</div>
                        </div>
                        <div class="flex-box">
                            <div class="label">处理流程</div>
                            <div class="flex-unit flex-box">
                                <div>服务中遇到困难</div>
                                <div class="bg-cc" :style="`background-image:url(${require('../../assets/registration-tabs-arrow.png')})`"></div>
                                <div>1.电话联系智会算或者<br/>专属财税顾问（推荐）<br/>2.在线留言反馈</div>
                                <div class="bg-cc" :style="`background-image:url(${require('../../assets/registration-tabs-arrow.png')})`"></div>
                                <div>1、核实问题具体情况<br/>2、确定责任人责任划分<br/>（是否涉退款赔付问题）</div>
                                <div class="bg-cc" :style="`background-image:url(${require('../../assets/registration-tabs-arrow.png')})`"></div>
                                <div>1、财务专家问题咨询<br/>2、按责进行相关赔付<br/>3、正常财务退款</div>
                                <div>处理完毕</div>
                            </div>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="5" tab="常见问题">
                    <div class="tab-content faq">
                        <div class="faq-unit" v-for="(faq,fi) in faqs" :key="fi">
                            <div class="question"><span>问</span>{{faq.question}}</div>
                            <div>{{faq.answer}}</div>
                        </div>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
    import {Tabs} from 'ant-design-vue';
    const TabPane=Tabs.TabPane;
    export default {
        name: "Knowledge",
        components: {
            [TabPane.name]: TabPane,
            [Tabs.name]: Tabs
        },
        data(){
            return {
                regNeedArr:[
                    {
                        title:'您要提供的：',
                        list:[1,2,3,4]
                    },
                    {
                        title:'我们要做的：',
                        list:[5,6,7,8]
                    }
                ],
                progress:[
                    {
                        label:'1.材料准备',
                        days:'2-3'
                    },
                    {
                        label:'2.网上注册',
                        days:'5-7'
                    },
                    {
                        label:'3.领取执照',
                        days:'1'
                    },
                    {
                        label:'4.刻章',
                        days:'1-2'
                    }
                ],
                materail:['营业执照正本','营业执照副本','企业统一社会信用代码','公司章程','公司印章及印鉴存留卡'],
                faqs:[
                    {
                        question:'公司注册一般需要多长时间？',
                        answer:'一般来说代办公司注册需要15-20个工作日。各地有所不同，具体请与工商顾问沟通确认。'
                    },
                    {
                        question:'注册资本写多少合适？',
                        answer:'建议参考行业内同类公司，主要考虑未来各类资质的要求，如：ICP经 营许可 证需要100万注册资本，视频网站牌照需要1000万注册资本。无特殊需求的，10-100万都可以。'
                    },
                    {
                        question:'谁当公司法定代表人更合适？',
                        answer:'创始人或者团队核心人员。法定代表人意味着权利和责任，由团队灵魂人物 担任最合适不过。'
                    },
                    {
                        question:'注册虚拟地址的公司要多久?',
                        answer:'虚拟地址采用购买式，一般只要付钱并提供名称核准通知书照片（截 图）后， 当天即可发送房间号，3个工作日内一般会收到房租合同。'
                    },
                    {
                        question:'没有注册地址怎么办？',
                        answer:'智会算可以为您介绍入驻集中办公区，提供安全、可靠、低价的虚拟注册地 址帮您注册公司。'
                    }
                ]
            }
        }
    }
</script>

<style lang="less">
    .knowledge-wrapper{
        width: 100%;
        height: 420px;
        .safe-content{
            box-sizing: border-box;
            padding: 0 50px;
            .tabs{
                /*样式更正*/
                .ant-tabs-bar{
                    border-bottom: none;
                }
                .ant-tabs-ink-bar{
                    background-color: #fff;
                }
                .ant-tabs-tab{
                    padding: 12px 0;
                    color: #fff;
                    font-size: 22px;
                }
            }
            .tab-content{
                box-sizing: border-box;
                padding: 20px 5px 0;
                color: #fff;
            }
            .reg-need{
                text-align: left;
                font-size: 20px;
                .classify-box{
                    flex-wrap: wrap;
                    .bg-cc{
                        width: 220px;
                        height: 120px;
                        margin-right: 30px;
                        margin-top: 20px;
                    }
                }
            }
            .progress{
                .desc{
                    margin-top: 20px;
                    margin-bottom: 40px;
                    font-size: 16px;
                }
                .flex-unit{
                    .bg-cc{
                        width: 92px;
                        height: 92px;
                        margin: 0 auto;
                    }
                    .label{
                        margin-top: 30px;
                        margin-bottom: 10px;
                        font-size: 20px;
                    }
                }
            }
            .materail{
                .desc{
                    margin-top: 20px;
                    margin-bottom: 20px;
                    font-size: 16px;
                }
                .unit{
                    margin-right: 50px;
                    display: inline-block;
                    .bg-cc{
                        width: 146px;
                        height: 190px;
                        margin: 0 auto 10px;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
            .after-sale{
                font-size: 16px;
                .flex-box{
                    margin-bottom: 30px;
                    align-items: center;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    .label{
                        width:90px;
                        height:42px;
                        margin-right: 23px;
                        line-height: 42px;
                        background:#fe6313;
                        border-radius:3px;
                    }
                    .flex-unit{
                        text-align: left;
                        line-height: 1.5;
                        .bg-cc{
                            width: 25px;
                            height: 12px;
                            margin: auto 20px;
                        }
                    }
                }
            }
            .faq{
                overflow: hidden;
                font-size: 14px;
                .faq-unit{
                    float: left;
                    width: 522px;
                    margin-bottom: 20px;
                    text-align: left;

                    &:nth-child(odd){
                        margin-right: 45px;
                    }
                    .question{
                        margin-bottom: 10px;
                        span{
                            display: inline-block;
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            border-radius: 3px;
                            margin-right: 20px;
                            background-color: #fe6313;
                            text-align: center;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }
</style>