<template>
    <div class="contact-wrapper safe-content grey-bg flex-box">
        <div class="info-box flex-unit">
            <div class="scroll-box">
                <div class="each-company" v-for="(item,index) in selectedInfo" :key="index">
                    <p class="info-title">{{item.company}}</p>
                    <p>{{item.location}}<a-icon class="location-icon" type="environment" theme="filled" @click="navToMap(item.location)"/></p>
                </div>
            </div>
        </div>
        <div class="map-box bg-cc" :style="`background-image:url(${require('../../assets/about-contact-map.png')})`">
            <div class="icon-box" v-for="(item,li) in locations" :key="li" :style="{top:item.top,left: item.left}">
                <a-icon class="location-icon" type="environment" theme="filled"
                        :style="{color:item.selected ? '#fe6313' : '#fff'}"
                        @click="changeLocation(item,li)"/>
                <span>{{item.provice}}</span>
                <span class="remarks">{{item.remarks}}</span>
            </div>


        </div>
    </div>
</template>

<script>
    import {Icon} from 'ant-design-vue';
    export default {
        name: "Contact",
        components:{
            [Icon.name]: Icon
        },
        data(){
            return {
                locations:[],
                selectedIndex:0,
                selectedInfo:[],
            }
        },
        async created(){
            await this.getProvinces();
            let targetProvice=this.$route.query.provice;
            let targetIndex=0;
            if(targetProvice){
                this.locations.some((item,index)=>{
                    if(item.name===targetProvice){
                        targetIndex=index;
                        return true
                    }
                })
            }
            this.selectedIndex=targetIndex;
            this.locations[targetIndex].selected=true;
            this.selectedInfo = this.locations[targetIndex].children = await this.getContactCompanies(this.locations[targetIndex].id);

        },
        methods:{
            async getProvinces(){
                let res=await this.$api.getContactProvince();
                this.locations=(res || []).map(item=>{
                    return {
                        id:item.guid,
                        selected:false,
                        top:item.locationPointUp + 'px',
                        left:item.locationPointLeft + 'px',
                        provice:item.provinceName,
                        name:item.provinceEnglishName,
                        remarks:item.remark,
                        children:[]
                    }
                });
            },
            async getContactCompanies(id){
                let res=await this.$api.getContactCompanies(id);
                return (res || []).map(item=>{
                    return {
                        company:item.companyName,
                        location:item.companyLocation
                    }
                })
            },

            async changeLocation(item,index){
                // 重置上一个选择为false，再更新新的选中
                this.locations[this.selectedIndex].selected=false;
                this.locations[index].selected=true;
                this.selectedIndex=index;
                if(item.children.length>0){
                    this.selectedInfo=item.children;
                }else{
                    this.selectedInfo = this.locations[index].children = await this.getContactCompanies(item.id);
                }
            },

            navToMap(name){
                window.open('https://ditu.amap.com/search?query='+name);
            }
        },
        watch:{
            '$route.query.provice'(provice){
                let targetIndex=0;
                if(provice){
                    this.locations.some((item,index)=>{
                        if(item.name===provice){
                            targetIndex=index;
                            return true
                        }
                    })
                }
                this.changeLocation(this.locations[targetIndex],targetIndex)
            }
        }
    }
</script>

<style lang="less" scoped>
    .contact-wrapper{
        box-sizing: border-box;
        padding: 10px 30px;
        height: 645px;
        .info-box{
            box-sizing: border-box;
            width: 400px;
            padding: 30px 0;
            height: 550px;
            margin: 30px 40px;
            text-align: left;
            font-size: 18px;
            overflow: hidden;
            .scroll-box{
                /*display: flex;*/
                /*flex-direction: column;*/
                /*justify-content: center;*/
                width: 412px;
                height: 100%;
                overflow-y: scroll;

                .each-company{
                    .info-title{
                        font-weight: bold;
                    }
                    p{
                        margin: 0;
                        line-height: 1.8;
                    }
                }
            }

        }
        .map-box{
            position: relative;
            width: 665px;
            .icon-box{
                position: absolute;
                .location-icon{
                    font-size: 20px;
                }
                .remarks{
                    display: block;
                    font-size: 10px;
                }
            }

        }
    }
</style>