<template>
    <div class="the-services-wrapper bg-cc" :style="`background-image:url(${require('../../assets/service-content-bg1.png')})`">
        <div class="services-title">{{data.title}}</div>
        <div class="services-contain">
            <div class="unit" v-for="(item,index) in data.list" :key="index">
                <div class="unit-title">{{item.title}}</div>
                <div class="unit-content">{{item.content}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TheServices",
        props:{
            data:{
                type:Object,
                default:()=>{
                    return {title:'', list:[]}
                }
            }
        },
        data(){
            return {}
        }
    }
</script>

<style lang="less" scoped>
    .the-services-wrapper{
        box-sizing: border-box;
        height: 510px;
        padding: 1px 77px;
        .services-title{
            margin: 40px 0;
            font-size: 22px;
        }
        .services-contain{
            display: flex;
            flex-wrap: wrap;
            .unit{
                box-sizing: border-box;
                width: 268px;
                height: 150px;
                padding: 15px 28px;
                margin-bottom: 40px;
                margin-right: 60px;
                color: #fff;
                text-align: left;
                background-color: @primary-color;

                &:nth-child(even){
                    margin-right: 0;
                }

                .unit-title{
                    display: inline-block;
                    padding-bottom: 5px;
                    border-bottom: 2px solid #fff;
                    margin-bottom: 10px;
                }
                .unit-content{
                    font-size: 14px;
                }
            }
        }
    }
</style>