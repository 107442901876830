<template>
    <div class="info-form-wrapper">
        <div class="order-box flex-unit flex-box">
            <div v-for="(item,index) in list" :key="index">
                <a-select v-if="item==='addr'" v-model="userInfo.addr" :options="citiesConfig" style="width: 100px"></a-select>
               <!-- <a-cascader v-if="item==='addr'" v-model="userInfo.addr" :options="citiesConfig" :allowClear="false"/>-->
                <a-input v-if="item==='phone'" v-model="userInfo.phone" placeholder="请输入您的电话号码" :max-length="11" style="width: 170px"/>
                <a-input v-if="item==='companyNo'" v-model="userInfo.companyNo" placeholder="请输入企业号" style="width: 170px"/>
                <a-input v-if="item==='name'" v-model="userInfo.name" placeholder="请输入您的称呼" style="width: 170px"/>
                <a-select v-if="item==='taxIdentify'" v-model="userInfo.taxIdentify" :options="taxIdentify" style="width: 140px"></a-select>
                <a-select v-if="item==='companyType'" v-model="userInfo.companyType" :options="companyType" style="width: 130px"></a-select>
                <a-popover v-if="item==='industry'" trigger="click" placement="bottom">
                    <template slot="content">
                        <div class="type-container">
                            <div class="type-unit" v-for="(type,ti) in industryType" :key="ti">
                                <div class="label">{{type.label}}</div>
                                <span v-for="(item,index) in type.list" :key="index" @click="selectIndustry(item)">{{item}}</span>
                            </div>
                        </div>
                        <div><a-icon type="exclamation-circle" style="color: #EA9534;margin-right: 10px" />如果没有找到您想要的行业，可以选择直接输入</div>
                    </template>
                    <a-input placeholder="所属行业" style="width: 130px" v-model="userInfo.industry"/>
                </a-popover>
            </div>
        </div>
        <div class="orderBtn hover-point" @click="toApplyService">{{btnMsg}}</div>
    </div>
</template>

<script>
    /*import citiesConfig from '@/config/proviceCity.json';*/
    import {Cascader, Input, Select,Popover,Icon} from 'ant-design-vue';

    export default {
        name: "InfoForm",
        components: {
            [Cascader.name]: Cascader,
            [Input.name]: Input,
            [Select.name]: Select,
            [Popover.name]: Popover,
            [Icon.name]: Icon
        },
        props: {
            list: {
                type: Array,
                required: true
            },
            btnMsg: {
                type:String,
                default:'点击咨询'
            },
            isCustomerService:{
                type:Boolean,
                default:false
            }
        },
        data() {
            return {
                userInfo:{
                    addr:'福州',
                    phone:'',
                    name:'',
                    taxIdentify: '小规模纳税人',
                    companyNo:'',
                    companyType: '有限责任公司',
                    industry:''
                },
                taxIdentify: [
                    {
                        label:'小规模纳税人',
                        value:'小规模纳税人'
                    },
                    {
                        label:'一般纳税人',
                        value:'一般纳税人'
                    }
                ],
                industryType:[
                    {
                        label:'科技类',
                        list:['网络科技','生物医药科技','信息科技','通信科技','新能源科技','自动化科技','激光科技','新材料','游戏科技','海洋科技','其他科技']
                    },
                    {
                        label:'服务类',
                        list:['生活服务','企业服务','翻译服务','礼仪服务','劳动服务','教育服务','旅游服务','运输服务','居民服务','租赁服务','其他服务']
                    },
                    {
                        label:'管理类',
                        list:['企业管理','酒店管理','投资管理','餐饮管理','物业管理','人力资源管理','商业管理']
                    },
                    {
                        label:'咨询类',
                        list:['战略咨询','IT咨询','创新设计咨询','财务咨询','技术咨询','商务咨询']
                    },
                    {
                        label:'其他',
                        list:['建筑装潢','实业','贸易','软件开发','批发采购','农林牧渔','工程','采矿业','生产和供应业']
                    }
                ],
                companyType: [
                    {
                        label:'有限责任公司',
                        value:'有限责任公司'
                    },
                    {
                        label:'股份有限公司',
                        value:'股份有限公司'
                    },
                    {
                        label:'合伙企业',
                        value:'合伙企业'
                    },
                    {
                        label:'有限公司',
                        value:'有限公司'
                    }
                ]
            }
        },
        computed:{
            citiesConfig(){
                return this.$store.state.cities.map(item=>{
                    return {
                        label:item.cityName,
                        value:item.cityName
                    }
                })
            }
        },
        methods:{
            selectIndustry(item){
                this.userInfo.industry=item;
            },
            async toApplyService(){
                let data=this.userInfo;
                if(this.isCustomerService){
                    let title=`企业核名-${data.addr}-${data.companyNo}-${data.industry}-${data.companyType}`;
                    window.triggleZc(title,true);
                    return false;
                }
                if(!/^[1][3,4,5,7,8][0-9]{9}$/.test(data.phone)){
                    this.$message.error('请填写正确的手机号');
                    return false
                }
                let req={
                    applicantTel:data.phone,
                    applicantName:data.name,
                    cityCode:data.addr,
                    serviceName:'加盟我们'
                }
                let res=await this.$api.toApplyService(req);
                if(res===undefined){
                    this.$store.commit('changeStateVal',{key:'modalConfig',value:{showModal:true}});
                }
            },
        }
    }
</script>

<style lang="less" scoped>
    .info-form-wrapper {
        display: flex;
        width: 100%;
        height: 60px;
        border-radius: 3px;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
        background-color: #fff;
        overflow: hidden;
        .order-box{
            box-sizing: border-box;
            padding: 14px 0;
            &>div{
                box-sizing: border-box;
                padding:0 14px;
                border-right: 1px solid #999;

                &:last-child{
                    border-right: 0;
                }
            }
        }
        .orderBtn{
            width: 155px;
            height: 60px;
            line-height: 60px;
            background-color: #FE6313;
            font-size: 18px;
            color: #fff;
        }
    }
    .type-container{
        width: 1010px;
        .type-unit{
            margin-bottom: 10px;
            height: 26px;
            line-height: 26px;
            span{
                display: inline-block;
                margin-right: 24px;
                cursor: pointer;

                &:hover{
                    color: #EA9534;
                }

            }

            .label{
                display: inline-block;
                margin-right: 24px;
                width: 64px;
                text-align: center;
                background-color: #e8effc;
            }
        }

    }
</style>