<template>
    <div class="train-detail-wrapper">
        <div class="safe-content detail" v-html="content"></div>
    </div>
</template>

<script>
    export default {
        name: "TrainDetail",
        async created() {
            let id=this.$route.query.id;
            let res=await this.$api.getTrainDetail(id);
            this.content=res.content;
        },
        data(){
            return {
                content:''
            }
        }
    };
</script>
<style lang="less">
    .train-detail-wrapper{
        text-align: left;
        .detail{
            box-sizing: border-box;
            padding: 20px 0;
            min-height: 200px;
        }
    }
</style>
