<template>
    <div class="reasons-wrapper">
        <div class="reasons-title">为什么选择智会算</div>
        <div class="safe-content">
            <div class="tabs-box">
                <span v-for="(tab,index) in tabs" :key="index"
                      class="hover-point"
                      :class="{'tab-active':index===cTab}"
                      @click="cTab=index">{{tab}}</span>
            </div>
            <div class="tabs-content-box">
                <div class="tab-content safe-content" v-show="cTab===0">
                    <div class="content-title">运用大数据和“互联网+”的思维，将碎片化的需求痛点进行封装，致力于为企业提供安全有保障，高性价比的财税服务。每月记账报税公司均有专职会计小组分工处理，精细化运作力求给您最专业的财税服务。</div>
                    <div class="platform flex-box">
                        <div class="img bg-cc" :style="`background-image:url(${require('../../assets/a-agent-reasons-platform.png')})`"></div>
                        <div class="flex-unit">
                            <div class="advantage" v-for="(item,index) in platformAdvantages" :key="index">
                                <span class="icon" :style="`background-image:url(${require('../../assets/a-agent-reasons-platform-icon.png')})`"></span>
                                {{item}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content safe-content" v-show="cTab===1">
                    <div class="content-title">随时随地使用手机APP快速查询其公司财税状态，实时掌握代账服务进度，每月账表数据一目了然，收入支出利润一手掌握，有效降低代账公司客服成本86.5%。</div>
                    <div class="platform flex-box">
                        <div class="img img-status bg-cc" :style="`background-image:url(${require('../../assets/a-agent-reasons-taxstatus.png')})`"></div>
                        <div class="flex-unit">
                            <div class="advantage flex-box" v-for="(item,index) in taxStatus" :key="index">
                                <span class="icon" :style="`background-image:url(${require('../../assets/a-agent-reasons-platform-icon.png')})`"></span>
                                <span class="flex-unit" v-html="item"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content safe-content" v-show="cTab===2">
                    <div class="content-title">多年会计从业经验，专业培训后持证上岗，月月考核；内审部门严格查账。</div>
                    <div class="flex-box team">
                        <div class="flex-unit" v-for="(item,index) in team" :key="index">
                            <div class="team-header bg-cc" :style="`background-image:url(${require('../../assets/'+item.img)})`"></div>
                            <div class="team-content">
                                <div class="team-name">{{item.name}}</div>
                                <div>{{item.desc}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content safe-content" v-show="cTab===3">
                    <div class="content-title">智会算在短短两年多的时间，以加盟和合营两种方式目前在全国已经成立十余家公司，团队规模超过500人，服务中小企业超20000家。统一代理记账收费标准，灵活定制代理记账方案，秉持“为中小企业发展赋能”的使命，为客户提供涵盖企业全生命周期各种记账报税及企业疑难财务问题的服务。</div>
                    <div class="flex-box risk">
                        <div class="flex-unit" v-for="(item,index) in risk" :key="index">
                            <div class="risk-header bg-cc" :style="`background-image:url(${require('../../assets/a-agent-reasons-risk'+index+'.png')})`"></div>
                            <div class="risk-content">
                                <div class="team-title">{{item.title}}</div>
                                <div>{{item.desc}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "Reasons",
        data(){
            return {
                cTab:0,
                tabs:['智能作业平台','实时财税状态','专业服务团队','全面风险把控'],
                platformAdvantages:['电子发票极速处理','自动记账','对接各大银行实现互联网财税服务','直连税务局系统，规避税务风险'],
                taxStatus:[
                    '实时查看财务状况<br/>应收应付账款、纳税情况、本月利润等信息一手掌握',
                    '日常财务问题咨询<br/>免费电话随时咨询、极速响应客户需求',
                    '实时监控财务状况<br/>手机实时监控企业财务情况'
                ],
                team:[
                    {
                        img:'a-agent-reasons-team0.jpg',
                        name:'颜真真',
                        desc:'丰富的会计、审计理论和实务经验。为众多行业客户提供专业服务。积极参与制定行业规范。'
                    },
                    {
                        img:'a-agent-reasons-team1.jpg',
                        name:'陈莫',
                        desc:'具有较强的专业技术技能和管理技巧及广泛的知识视野，本着务实、求是、实干、热情的工作态度解客户之忧，维客户之利。'
                    },
                    {
                        img:'a-agent-reasons-team2.jpg',
                        name:'张喆锡',
                        desc:'能够充分将理论联系实际，结合熟练的计算机操作技术，在工作上独当一面，应用自如，并累积了丰富的金融行业管理经验和各行各业财经审计案例。'
                    },
                    {
                        img:'a-agent-reasons-team3.jpg',
                        name:'孟小茜',
                        desc:'工作认真、热忱，责任心强，创新意识好，合作精神佳。熟悉财务制度和会计工作流程，掌握企业全套账务处理程序；熟悉税收法规，可合理安排纳税筹划工作，善于利用税收优惠政策。'
                    }
                ],
                risk:[
                    {
                        title:'安全做账',
                        desc:'智会算是具有代理记账资质的代账服务公司，严格遵守相关国家法律法规及会计制度，建立专属企业财务档案库，对客户信息进行严格保密。'
                    },
                    {
                        title:'双重核查',
                        desc:'记账报税结果首先系统自检，然后会计再次核查比对，确保财务会计报税过程不出现问题。'
                    },
                    {
                        title:'实时保障',
                        desc:'晓智代账APP即可随时查看账务细节，工作进度，相关票据扫描件，保障账务明细、进度随时可查，清晰明了。'
                    },
                    {
                        title:'规避风险',
                        desc:'公司自主创新的CRM客户管理系统实现财务系统自动对记账、报税、收费等进度审核检测，实时预警财税风险,不遗漏任何一笔业务。'
                    }
                ]
            }
        }
    }
</script>

<style lang="less" scoped>
.reasons-wrapper{
    height: 805px;
    background-image: url("../../assets/a-agent-reasons-bg.png");
    background-position: center top;
    background-size: initial;
    background-repeat: no-repeat;
    .reasons-title{
        padding-top: 70px;
        margin-bottom: 60px;
        font-size: 36px;
        color: #fff;

        &:after{
            display: block;
            content: '';
            width: 30px;
            height: 4px;
            margin: 4px auto 30px;
            background-color: #fff;
            color: #fff;
        }
    }
    .safe-content{
        .tabs-box{
            width: 855px;
            border-bottom: 1px solid #fff;
            margin: 0 auto;
            color: #fff;
            span{
                display: inline-block;
                width:147px;
                height:48px;
                margin-right: 20px;
                line-height: 48px;
                font-size: 18px;
            }
            .tab-active{
                background-color: #fff;
                color: @primary-color;
            }
        }
        .tabs-content-box{
            font-size: 16px;
            .tab-content{
                .content-title{
                    box-sizing: border-box;
                    width: 855px;
                    padding-top: 40px;
                    height: 160px;
                    margin: 0 auto;
                    text-align: left;
                    color: #fff;
                    line-height: 1.8;
                }
                .platform{
                    display: flex;
                    margin-left: 170px;
                    .img{
                        width: 510px;
                        height: 362px;
                        margin-right: 80px;
                        transform: translateY(-60px);
                    }
                    .img-status{
                        width: 438px;
                        height: 421px;
                    }
                    .advantage{
                        margin-top: 30px;
                        text-align: left;
                        align-items: center;
                        .icon{
                            display: inline-block;
                            width: 31px;
                            height: 31px;
                            margin-right: 20px;
                        }
                        span{
                            vertical-align: middle;
                        }
                    }
                }
                .team{
                    margin-top: 20px;
                    font-size: 14px;
                    .flex-unit{
                        margin-right: 80px;
                    }
                    .team-header{
                        height: 160px;
                        background-color: #2c3e50;
                    }
                    .team-content{
                        box-sizing: border-box;
                        padding: 0 20px;
                        height: 200px;
                        background-color: #fff;
                        text-align: left;
                        .team-name{
                            font-size: 22px;
                            padding: 14px 0;
                            color: #E59027;
                        }
                    }
                }
                .risk{
                    margin-top: 20px;
                    padding-top: 10px;
                    background-color: #fff;
                    .risk-header{
                        width: 273px;
                        height: 173px;
                        margin: 0 auto;
                    }
                    .risk-content{
                        padding: 25px 30px 20px;
                        color: #666;
                        text-align: left;
                        .team-title{
                            margin-bottom: 15px;
                            font-size: 18px;
                            text-align: center;
                            color: #343434;
                        }
                    }
                }
            }
        }
    }
}
</style>