import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isSpinning:false,
    backgroundColor:'#fff',
    headerMenus:[],
    modalConfig:{showModal:false},
    cities:[],
    site:'fz',
    showPage:true
  },
  mutations: {
    changeStateVal(state,obj) {
      state[obj.key] =obj.value;
    }
  },
  actions: {},
  modules: {}
});
