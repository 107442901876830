<template>
    <div class="honour-wrapper safe-content">
        <div class="unit" v-for="(item,index) in honor" :key="index">
            <div class="img"><img :src="item.img"></div>
            <div>{{item.desc}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Honour",
        data(){
            return {
                honor:[]
            }
        },
        created(){
            this.getHonor();
        },
        methods:{
            async getHonor(){
                let res=await this.$api.getHonor();
                this.honor=(res.list || []).map(item=>{
                    return {
                        desc:item.title,
                        img:item.picture
                    }
                })
                console.log(this.honor)
            }
        }
    }
</script>

<style lang="less" scoped>
    .honour-wrapper{
        overflow: hidden;
        .unit{
            float: left;
            box-sizing: border-box;
            width: calc(~"(100% - 45px)/4");
            margin-right: 15px;
            margin-bottom: 40px;

            &:nth-child(4n){
                margin-right: 0;
            }
            .img{
                height: 201px;
                margin-bottom: 20px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
</style>