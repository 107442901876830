<template>
    <div class="xzdz-wrapper">
        <div class="banner bg-cc"></div>
        <section>
            <img src="../assets/xzdz-content1.png">
        </section>
        <section class="grey-bg" style="padding-top: 40px;padding-bottom: 40px">
            <img src="../assets/xzdz-content2.png">
        </section>
        <section>
            <img src="../assets/xzdz-content3.png">
        </section>
        <section class="grey-bg" style="padding-top: 40px;padding-bottom: 40px">
            <img src="../assets/xzdz-content4.png">
        </section>
        <section>
            <img src="../assets/xzdz-content5.png">
        </section>
        <section class="grey-bg" style="padding-top: 40px;padding-bottom: 40px">
            <img src="../assets/xzdz-content6.png">
        </section>
    </div>
</template>

<script>
    export default {
        name: "Xzdz",
        data(){
            return {

            }
        }
    };
</script>
<style lang="less">
    .xzdz-wrapper{
        .banner {
            background-image: url("../assets/xzdz-banner.png");
        }
    }
</style>
