<template>
    <div class="flex-box hotservice-wrapper">
        <div class="flex-unit">
            <div class="header" :style="`background-image:url(${require('../../assets/registration-hotservice-bg1.jpg')})`">
                <div class="header-title">工商注册</div>
                <div>智会算工商注册服务包含：免费核名，地址，网登，领取执照，刻章 领取一证通，免费赠送税务报到和税务咨询等服务。</div>
            </div>
            <div class="content registra">
                <div class="flex-box">
                    <div class="flex-unit">
                        <div class="type-title">公司注册类型</div>
                        <div class="radio-box">
                            <a-radio-group name="hasRegAddr" v-model="regOption.companyType">
                                <a-radio class="radio" v-for="(type, index) in companyType"
                                         :key="index" :value="type">{{type}}</a-radio>
                            </a-radio-group>
                        </div>
                    </div>
                    <div class="divide"></div>
                    <div class="flex-unit">
                        <div class="type-title">纳税类型</div>
                        <div class="radio-box">
                            <a-radio-group name="hasRegAddr" v-model="regOption.taxType">
                                <a-radio class="radio" v-for="(type, index) in taxType"
                                         :key="index" :value="type">{{type}}</a-radio>
                            </a-radio-group>
                        </div>
                    </div>
                </div>
                <div class="req-btn hover-point" @click="reqPrice">咨询价格</div>
            </div>
        </div>
        <div class="flex-unit">
            <div class="header" :style="`background-image:url(${require('../../assets/registration-hotservice-bg2.jpg')})`">
                <div class="header-title">变更</div>
                <div>智会算变更：因地域原因各地的政策和详细服务内容有所不同，详细 情况还请咨询顾问了解最新信息</div>
            </div>
            <div class="content change">
                <div class="company-change flex-box">
                    <div class="hover-point">
                        <div class="bg-cc" :style="`background-image:url(${require('../../assets/registration-hotservice-change1.png')})`"></div>
                        公司名称变更
                    </div>
                    <div class="hover-point">
                        <div class="bg-cc" :style="`background-image:url(${require('../../assets/registration-hotservice-change2.png')})`"></div>
                        公司注销
                    </div>
                </div>
                <div class="info-change flex-box">
                    <div class="change-btn hover-point" v-for="(type,index) in changeType" :key="index"
                         :class="{'btn-active':changeSelect===type}"
                         @click="reqChange(type,true)">{{type}}</div>
                </div>
                <div class="req-btn hover-point" @click="reqChange('咨询其他')">咨询其他</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Radio} from 'ant-design-vue';
    const RadioGroup=Radio.Group;
    export default {
        name: "HotService",
        components: {
            [Radio.name]: Radio,
            [RadioGroup.name]: RadioGroup
        },
        data(){
            return {
                companyType:['股份有限公司注册','有限合伙公司注册','集团公司注册','内资公司注册','合资公司注册'],
                taxType:['小规模代理记账','一般纳税人代理记账'],
                changeType:['监事变更','地址变更','法人变更','股权变更','银行基本户注销','注册资本变更','经营范围变更'],
                regOption:{
                    companyType:'股份有限公司注册',
                    taxType:'小规模代理记账'
                },
                changeSelect:''
            }
        },
        methods:{
            reqPrice(){
                let regOption=this.regOption;
                let title=`工商注册-${regOption.companyType}-${regOption.taxType}`;
                window.triggleZc(title,true);
            },
            reqChange(service,isChecked){
                if(isChecked){
                    this.changeSelect=service;
                }
                let title=`公司注册-变更-${service}`;
                window.triggleZc(title,true);
            }
        }
    }
</script>

<style lang="less" scoped>
    .hotservice-wrapper{
        font-size: 16px;
        .req-btn{
            width: 140px;
            height: 40px;
            margin: 0 auto;
            line-height: 40px;
            border-radius: 20px;
            color: #fff;
        }
        .flex-unit{
            margin-right: 40px;
            .header{
                box-sizing: border-box;
                padding: 20px 80px 0 35px;
                height: 178px;
                background-position: left bottom;
                background-size: cover;
                text-align: left;
                color: #fff;
                .header-title{
                    margin: 10px 0;
                    font-size: 30px;
                }
            }
            .content{
                box-sizing: border-box;
                height: 400px;
                padding-top: 34px;
                background-color: #fff;
                .type-title{
                    margin-bottom: 30px;
                    text-align: center;
                }
            }
            .registra{
                .radio-box{
                    .radio{
                        display: block;
                        margin-bottom: 20px;
                        text-align: left;
                    }
                }
                .divide{
                    margin-top: 14px;
                    width: 1px;
                    height: 210px;
                    background-color: #E8E8E8;
                }
                .req-btn{
                    margin-top: 30px;
                    background-color: @primary-color;
                }
            }

            .change{
                padding: 40px;
                .company-change{
                    justify-content: center;
                    &>div:first-child{
                        margin-right: 100px;
                    }

                    .bg-cc{
                        width: 50px;
                        height: 50px;
                        margin: 0 auto 10px;
                    }
                }

                .info-change{
                    margin: 40px 0;
                    flex-wrap:wrap;
                    .change-btn{
                        box-sizing: border-box;
                        padding: 0 20px;
                        height: 40px;
                        margin-bottom: 20px;
                        margin-right: 18px;
                        line-height: 40px;
                        border:1px solid #D3D3D3;
                        border-radius: 20px;
                    }
                    .btn-active,.change-btn:hover{
                        border-color: #F17862;
                        background-color: #F17862;
                        color: #fff;
                    }
                }

                .req-btn{
                    margin-top: 30px;
                    background-color: #F17862;
                }
            }

        }
    }
</style>