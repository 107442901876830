<template>
    <div class="advisor-wrapper">
        <a-carousel :draggable="true" :dots="true">
            <div v-for="(ad,ai) in advisors" :key="ai" class="advisor-box">
                <img class="img" :src="ad.img">
                <div class="header">
                    <span>{{ad.name}}</span>{{ad.identity}}
                </div>
                <div>{{ad.desc}}</div>
                <div class="btn hover-point" @click="reqAdvisor(ad.name)">向TA咨询</div>
            </div>
        </a-carousel>
    </div>
</template>

<script>
    import {Carousel} from 'ant-design-vue';
    export default {
        name: "Advisor",
        components:{
            [Carousel.name]: Carousel
        },
        data(){
            return {
                advisors:[]
            }
        },
        created(){
            this.getAdvisors();
        },
        methods:{
            async getAdvisors(){
                let res=await this.$api.getAdvisors();
                this.advisors=(res || []).map(item=>{
                    return {
                        img:item.picture,
                        name:item.expertName,
                        identity:item.occupation,
                        desc:item.description
                    }
                })
            },
            reqAdvisor(name){
                let title=`公司注册-财税顾问咨询-${name}`;
                window.triggleZc(title,true);
            }
        }
    }
</script>

<style lang="less" scoped>
    .advisor-wrapper{
        width: 340px;
        height: 100%;
        text-align: left;
        font-size: 16px;
        color: #666;
        /deep/.slick-slide {
            height: 540px;
            .img{
                width: 337px;
                height: 227px;
            }
        }
        /deep/.slick-slide h3 {
            color: #fff;
        }

        .advisor-box{
            height: 540px;
            position: relative;
            .header{
                margin: 30px 0 20px;
                span{
                    display: inline-block;
                    margin-right: 20px;
                    font-size: 24px;
                }
            }
            .btn{
                position: absolute;
                left: 90px;
                bottom: 30px;
                width: 160px;
                height: 40px;
                border-radius: 20px;
                line-height: 40px;
                background-color: #FE6313;
                color: #fff;
                text-align: center;
            }
        }


    }
</style>