<template>
    <div class="dissolve-wrapper">
        <div class="banner bg-cc"></div>
        <section>
            <div class="title">公司注销费用咨询</div>
            <div class="ask">
                <info-form :list="orderList" btnMsg="获取价格"></info-form>
            </div>
        </section>
        <section class="safe-content">
            <div class="title-underline">公司不注销的危害</div>
            <div class="damage-box">
                <div v-for="(item,index) in damage" :key="index">
                    <div class="icon" :style="`background-image:url(${require('../assets/'+item.icon)})`"></div>
                    <div>{{item.label}}</div>
                </div>
            </div>
        </section>
        <section class="grey-bg">
            <div class="title">注销公司要跑的机构</div>
            <div class="swiper-box">
                <organization></organization>
            </div>
        </section>
        <section class="safe-content">
            <div class="title">智会算注销公司服务</div>
            <div class="dissolve-service">
                <div class="unit" v-for="(item,index) in service" :key="index">
                    <div class="service-icon bg-cc" :style="`background-image:url(${require('../assets/'+item.icon)})`"></div>
                    <div class="service-content">
                        <div class="service-title">{{item.title}}</div>
                        <div>{{item.desc}}</div>
                    </div>
                </div>
            </div>
        </section>
        <section class="grey-bg">
            <div class="title">需求准备的材料</div>
            <div class="materail-box">
                <div class="unit-box" v-for="(mate,index) in materail" :key="index">
                    <div class="unit" v-for="(item,index) in mate" :key="index">
                        <div class="materail-icon bg-cc" :style="`background-image:url(${require('../assets/'+item.icon)})`"></div>
                        <div class="label">{{item.label}}</div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="title">注销公司流程</div>
            <div class="dissolve-progress flex-box">
                <div class="flex-unit" v-for="(item,index) in progress" :key="index">
                    <div class="bg-cc icon" :style="`background-image:url(${require('../assets/'+item.icon)})`"></div>
                    <div class="progress-title">{{item.title}}</div>
                    <div>{{item.desc}}</div>
                </div>
            </div>
        </section>
        <section class="grey-bg">
            <div class="title">注销公司相关问题</div>
            <div class="faqs safe-content">
                <div class="faq" v-for="(faq,fi) in faqs" :key="fi">
                    <div class="question flex-box">
                        <div class="label">A</div>
                        <div class="content flex-unit">{{faq.question}}</div>
                    </div>
                    <div class="answer flex-box">
                        <div class="label">Q</div>
                        <div class="content flex-unit">{{faq.answer}}</div>
                    </div>
                </div>
            </div>
        </section>
        <advertisement style="margin-top: 0"></advertisement>
    </div>
</template>

<script>
    import InfoForm from '../components/common/InfoForm';
    import Organization from '../components/dissolve/Organization';
    import Advertisement from '@/components/common/Advertisement';
    export default {
        name: "Dissolve",
        components:{
            InfoForm,
            Organization,
            Advertisement
        },
        data(){
            return {
                orderList:['addr','phone','name','taxIdentify'],
                damage:[
                    {
                        icon:'dissolve-damage-ecom.png',
                        label:'进入工商黑名单'
                    },
                    {
                        icon:'dissolve-damage-job.png',
                        label:'无法担任企业高管'
                    },
                    {
                        icon:'dissolve-damage-credit.png',
                        label:'个人征信污点'
                    },
                    {
                        icon:'dissolve-damage-loan.png',
                        label:'无法办理贷款'
                    },
                    {
                        icon:'dissolve-damage-immigrant.png',
                        label:'无法办理移民'
                    },
                    {
                        icon:'dissolve-damage-consume.png',
                        label:'被限制高消费'
                    }
                ],
                service:[
                    {
                        icon:'dissolve-service1.png',
                        title:'公司注销速度快',
                        desc:'注销流程业务标准化，省去大量梳理流程时间，最快三个月即可完成注销。'
                    },
                    {
                        icon:'dissolve-service2.png',
                        title:'公司注销流程短',
                        desc:'一次性提供注销所需材料，按时按计划帮您跑公司注销流程，时间段效率高。'
                    },
                    {
                        icon:'dissolve-service3.png',
                        title:'专业注销团队',
                        desc:'专业注销服务团队，提供多对一服务，专人专项，保证服务质量。'
                    },
                    {
                        icon:'dissolve-service4.png',
                        title:'安全无忧服务',
                        desc:'安全无忧，公司注销服务过程中由我们产生的额外损失全额赔付，一站式注销服务。'
                    }
                ],
                materail:[
                    [
                        {
                            icon:'dissolve-material1.png',
                            label:'账本账套、凭证'
                        },
                        {
                            icon:'dissolve-material2.png',
                            label:'税控盘'
                        },
                        {
                            icon:'dissolve-material3.png',
                            label:'营业执照正副本'
                        }
                    ],
                    [
                        {
                            icon:'dissolve-material4.png',
                            label:'银行开户许可证'
                        },
                        {
                            icon:'dissolve-material5.png',
                            label:'公司五章'
                        },
                        {
                            icon:'dissolve-material6.png',
                            label:'法人、股东身份证复印件'
                        }
                    ],
                ],
                progress:[
                    {
                        icon:'dissolve-progress-demand.png',
                        title:'确定需要',
                        desc:'确定需要提供注销服务的公司及相关需求'
                    },
                    {
                        icon:'dissolve-progress-assist.png',
                        title:'业务协助',
                        desc:'公司注销团队评估需求并提供必要协助'
                    },
                    {
                        icon:'dissolve-progress-sign.png',
                        title:'签署合同',
                        desc:'确保合作后签署合同，保障客户信息及权益'
                    },
                    {
                        icon:'dissolve-progress-submit.png',
                        title:'提交材料',
                        desc:'整理并提交公司注销代办所需要的相关材料'
                    },
                    {
                        icon:'dissolve-progress-end.png',
                        title:'开始注销',
                        desc:'公司注销团队定制注销计划，开始启动注销流程'
                    }
                ],
                faqs:[
                    {
                        question:'福州地区注销公司多少钱？',
                        answer:'由于福州地区各区政策的差别，以及注销公司的实际情况，公司注销流程及费用都不相同，详细公司注销代办价格您可以报名咨询，也可以直接联系在线客服询价。'
                    },
                    {
                        question:'整个公司注销流程都是由你们负责办理吗？',
                        answer:'是的，我们对业务评估并确定合作后，由智会算注销代办团队按照标准的公司注销流程帮您注销公司，其中除必要流程外，全部由我们负责办理。'
                    },
                    {
                        question:'公司注销的流程顺序可以随意更改吗？',
                        answer:'原则上可以的，但公司注销一般需要按照注销税控、税务、工商、银行等大致流程逐一办理，如果在工商还未注销的情况下注销开户行账户，通常银行的处理速度会非常慢，影响整体进度。'
                    },
                    {
                        question:'公司注销前未做税务清缴会影响注销进度吗？',
                        answer:'会的，注销公司前需要核查是否有未缴清税款或费用，否则会影响之后在税务局注销国地税，所以建议在启动公司注销流程前，检查公司税务清缴情况。'
                    }
                ]
            }
        }
    };
</script>
<style lang="less">
    .dissolve-wrapper {
        box-sizing: border-box;

        .banner {
            background-image: url("../assets/dissolve-banner.png");
        }

        /*公共样式*/
        section {
            .title,.title-underline {
                margin-top: 60px;
                font-size: 24px;
            }
            .title-underline:after{
                display: block;
                content: '';
                width: 30px;
                height: 4px;
                margin: 4px auto 40px;
                background-color: @primary-color;
            }
        }

        .ask{
            width: 850px;
            margin: 47px auto;
        }

        .damage-box{
            display: flex;
            justify-content:space-between;
            margin-bottom: 60px;
            font-size: 16px;
            .icon{
                width: 100px;
                height: 100px;
                margin: 0 auto 20px;
            }
        }

        .swiper-box{
            box-sizing: border-box;
            padding-top: 30px;
            padding-bottom: 70px;
        }

        .dissolve-service{
            display: flex;
            flex-wrap: wrap;
            box-sizing: border-box;
            padding-top: 35px;
            padding-bottom: 10px;
            .unit{
                box-sizing: border-box;
                display: flex;
                align-items: center;
                width: 500px;
                padding: 40px;
                margin: 0 40px 10px;
                background-color: #F9F9FE;
                text-align: left;
                .service-content{
                    width: 300px;
                    line-height: 1.8;
                }
                .service-icon{
                    width: 86px;
                    height: 86px;
                    margin-right: 20px;
                }
                .service-title{
                    margin-bottom: 10px;
                    font-size: 20px;
                }
            }
        }

        .materail-box{
            width: 760px;
            padding-bottom: 1px;
            margin: 25px auto 60px;
            .unit-box{
                display: flex;
                justify-content: space-between;
                margin-bottom: 50px;
                .unit{
                    min-width: 200px;
                }
                .materail-icon{
                    width: 100px;
                    height: 100px;
                    margin:0 auto 20px;
                }
            }
        }

        .dissolve-progress{
            box-sizing: border-box;
            width: 1200px;
            margin: 0 auto;
            padding: 40px 60px 60px;
            .flex-unit{
                margin-right: 60px;
                text-align: left;
                .icon{
                    width: 84px;
                    height: 84px;
                    margin:0 auto 40px;
                }
                .progress-title{
                    margin-bottom: 10px;
                    font-size: 24px;
                    text-align: center;
                }
            }
        }

        .faqs{
            padding-bottom: 20px;
            overflow: hidden;
            .faq{
                float: left;
                box-sizing: border-box;
                width: 50%;
                padding-right: 60px;
                margin-top: 40px;
                margin-bottom: 30px;

                .flex-box{
                    .label{
                        width: 24px;
                        height: 24px;
                        margin-right: 15px;
                        line-height: 24px;
                        color: #fff;
                    }
                    .content{
                        text-align: left;
                    }
                }

                .question{
                    .label{
                        background-color: #1D3CDB;
                    }
                }

                .answer{
                    height: 84px;
                    margin-top: 20px;
                    .label{
                        background-color: @primary-color;
                    }
                }
            }
        }
    }
</style>
