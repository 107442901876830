import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Registration from "../views/Registration.vue";
import AccountAgent from "../views/AccountAgent.vue";
import Service from "../views/Service.vue";
import TrainDetail from "../views/TrainDetail";
import Dissolve from "../views/Dissolve.vue";
import Check from "../views/Check.vue";
import CheckCommon from "../views/check/Common";
import CheckRule from "../views/check/Rule";
import Join from "../views/Join.vue";
import About from "../views/About.vue";
import AboutCompany from "../components/about/Company";
import Development from "../components/about/Development";
import Honour from "../components/about/Honour";
import Contact from "../components/about/Contact";
import Recruitment from "../components/about/Recruitment";
import RecruitmentDetail from "../views/RecruitmentDetail";
import News from "../views/News";
import NewsDetail from "../views/NewsDetail";
import Xzdz from "../views/Xzdz";
import Article from "../views/Article";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "index",
        meta: { title: "福建智会算信息科技有限公司" },
        component: Home
    },
   /* {
        path: "/:site/home",
        name: "indexSecondary",
        meta: { title: "福建智会算信息科技有限公司" },
        component: Home
    },*/
    {
        path: "/registration",
        name: "registration",
        meta: { title: "公司注册" },
        component: Registration
    },
    {
        path: "/account-agent",
        name: "accountAgent",
        meta: { title: "代理记账" },
        component: AccountAgent
    },
    {
        path: "/service",
        name: "service",
        meta: { title: "全部服务" },
        component: Service
    },
    {
        path: "/train-detail",
        name: "trainDetail",
        meta: { title: "公司培训" },
        component: TrainDetail
    },
    {
        path: "/dissolve",
        name: "dissolve",
        meta: { title: "公司注销" },
        component: Dissolve
    },
    {
        path: "/xzdz",
        name: "xzdz",
        meta: { title: "晓智代账" },
        component: Xzdz
    },
    {
        path: "/join",
        name: "join",
        meta: { title: "加盟我们" },
        component: Join
    },
    {
        path: "/check",
        name: "check",
        meta: { title: "企业核名" },
        component: Check
    },
    {
        path: "/check/common",
        name: "checkCommon",
        meta: { title: "公司核名常见规则" },
        component: CheckCommon
    },
    {
        path: "/check/rule",
        name: "checkRule",
        meta: { title: "国家工商总局关于企业名称核准相关事项具体规定" },
        component: CheckRule
    },
    {
        path: "/about",
        name: "about",
        redirect: { name: 'aboutCompany' },
        component: About,
        children:[
            {
                path: "",
                name: "aboutCompany",
                meta: { title: "了解智会算" },
                component: AboutCompany,
            },
            {
                path: "development",
                name: "development",
                meta: { title: "发展历程" },
                component: Development,
            },
            {
                path: "honour",
                name: "honour",
                meta: { title: "荣誉一览" },
                component: Honour,
            },
            {
                path: "contact",
                name: "contact",
                meta: { title: "联系我们" },
                component: Contact,
            },
            {
                path: "recruitment",
                name: "recruitment",
                meta: { title: "诚聘英才" },
                component: Recruitment,
            }
        ]
    },
    {
        path: "/about/recruitment/detail",
        name: "recruitmentDetail",
        meta: { title: "招聘详情" },
        component: RecruitmentDetail
    },
    {
        path: "/news",
        name: "news",
        meta: { title: "资讯" },
        component: News
    },
    {
        path: "/news/:id",
        name: "newsDetail",
        meta: { title: "资讯详情" },
        component: NewsDetail
    },
    {
        path: "/article/:id",
        name: "article",
        component: Article
    }
];
const router = new VueRouter({
    /*mode: "history",*/
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if(!to.name){
        router.push({ name: 'index'})
    }
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();

})
export default router;
