<template>
    <div class="comment-wrapper flex-box">
        <div class="flex-unit unit" v-for="(item,index) in list" :key="index"
             :style="{'background-color':item.bgColor}">
            <div class="avarter" :style="`background-image:url(${require('../../assets/'+item.avarter)})`"></div>
            <div class="user">{{item.name}}</div>
            <div class="comment-star">
                <a-icon class="star-icon" type="star" theme="filled" />
                <a-icon class="star-icon" type="star" theme="filled" />
                <a-icon class="star-icon" type="star" theme="filled" />
                <a-icon class="star-icon" type="star" theme="filled" />
                <a-icon class="star-icon" type="star" theme="filled" />
            </div>
            <div class="comment">{{item.content}}</div>
        </div>
    </div>
</template>

<script>
    import {Icon} from 'ant-design-vue';
    export default {
        name: "Comment",
        components: {
            [Icon.name]: Icon
        },
        data(){
            return {
                list:[
                    {
                        avarter:'a-agent-comment-avarter1.png',
                        bgColor:'#F17862',
                        name:'王先生',
                        company:'福州融业有限公司',
                        content:'直接把办理需要的原始证件提供给代办人员，感觉没过多久服务就办好了”自17年选择智会算财税公司办理代理记账，全方位的做账报税服务不仅让我们实现了财税管理的智能化、无忧化，还给予我们企业更多的发展助力。王老板从没费过神。'
                    },
                    {
                        avarter:'a-agent-comment-avarter2.png',
                        bgColor:'#FA9B24',
                        name:'蔡女士',
                        company:'艺柯园林创始人',
                        content:'还好当时有你们会计，她不厌其烦地提醒我什么时候要给她票据。如今又有了APP+PC多端实时查看财税状况的晓智代账APP，真真切切做到对利润情况、纳税情况、资金情况等实时了解，还能根据变化频繁的税收政策，帮公司合理节税，让我省心又放心。”谈起创业，蔡老板不忘感谢智会算财税。'
                    },
                    {
                        avarter:'a-agent-comment-avarter3.png',
                        bgColor:'#54ACFC',
                        name:'刘先生',
                        company:'漳州格田装饰公司',
                        content:'我不用管公司财务，小到取发票，大到接受工商、税务的检查，所有账务你们会计全都帮我处理了”说起和智会算财税的合作，刘老板很放心。'
                    }
                ]
            }
        }
    }
</script>

<style lang="less" scoped>
    .comment-wrapper{
        padding-top: 90px;
        .unit{
            box-sizing: border-box;
            height: 395px;
            padding: 0 30px;
            margin-right: 70px;
            color: #fff;
            .avarter{
                width: 131px;
                height: 131px;
                margin: -88px auto 0;
            }
            .user{
                margin-top: 30px;
                margin-bottom: 10px;
                font-size: 20px;
                span{
                    display: inline-block;
                    margin-left: 10px;
                }
            }
            .comment-star{
                color: #EACC00;
                font-size: 28px;
                .star-icon{
                    display: inline-block;
                    margin-right: 12px;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
            .comment{
                font-size: 16px;
                line-height: 1.8;
                text-indent:2em;
                text-align: left;
            }
        }
    }
</style>