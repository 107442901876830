<template>
    <div class="document-wrapper grey-bg">
        <div class="safe-content">
            <div class="doc-header">{{title}}<br>{{remark}}</div>
            <div class="doc-content" v-html="content"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Article",
        data() {
            return {
                title:'',
                remark:'',
                content:''
            }
        },
        created() {
            let id=this.$route.params.id;
            this.getDocument(id);
        },
        methods:{
            async getDocument(id){
                let res=await this.$api.getDocument(id);
                if(!res){
                    this.$message.error('该标识下暂无文章内容');
                    return false;
                }
                this.title=res.documentTitle;
                this.remark=res.remark;
                this.content=res.documentContent;
            }
        }
    };
</script>
<style lang="less">
    .document-wrapper {
        padding-top: 30px !important;
        padding-bottom: 80px;
        .safe-content{
            background-color: #fff;
            .doc-header{
                font-size: 28px;
                padding-top: 40px;
                padding-bottom: 20px;
                border-bottom: 3px solid #f5f5f5;
            }
            .doc-content{
                padding: 40px;
                text-align: left;
            }
        }
    }
</style>
