<template>
    <div class="join-wrapper">
        <div class="banner bg-cc"></div>
        <div class="safe-content info-form">
            <info-form :list="orderList" btnMsg="我要加盟"></info-form>
        </div>
        <section class="join-location"></section>
        <section class="grey-bg worry">
            <div class="safe-content">
                <div class="title">您是否有以下担忧</div>
                <div class="flex-box">
                    <div class="flex-unit unit" v-for="(item,index) in worry" :key="index">
                        <div class="icon bg-cc"
                             :style="`background-image:url(${require('../assets/'+item.icon)})`"></div>
                        <div>
                            <div class="worry-title">{{item.title}}</div>
                            <div>{{item.desc}}</div>
                        </div>
                    </div>
                </div>
                <div class="btn hover-point" @click="modalConfig.showModal+=1">立刻帮我解决</div>
            </div>
        </section>
        <section>
            <div class="title">合作优势</div>
            <advantage></advantage>
        </section>
        <section class="grey-bg join-progress">
            <div class="title">加盟流程 三步搞定</div>
            <div class="flex-box">
                <div v-for="(item,index) in progress" :key="index">
                    <div class="right-arrow" v-if="index!==0"
                         :style="`background-image:url(${require('../assets/join-right-arrow.png')})`"></div>
                    <div class="circle-box">
                        <div v-html="item"></div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="title">加盟支持</div>
            <div class="flex-box supports safe-content">
                <div class="flex-unit" v-for="(item,index) in supports" :key="index">
                    <a-icon class="icon" :type="item.icon" :theme="item.iconTheme"/>
                    <div class="support-title">{{item.title}}</div>
                    <div>{{item.desc}}</div>
                </div>
            </div>
        </section>
        <section class="grey-bg" style="padding-bottom: 40px">
            <div class="title">加盟条件</div>
            <div class="factor bg-cc" :style="`background-image:url(${require('../assets/join-factor.png')})`"></div>
        </section>
        <advertisement style="margin-top: 0"></advertisement>
        <section>
            <div class="title">申请加盟</div>
            <div class="apply">
                <apply></apply>
            </div>
        </section>
        <section style="display: none">
            <div class="title">合作商风采</div>
            <div class="partner">
                <partner></partner>
            </div>
        </section>
        <c-z-modal-form :config="modalConfig" :otherData="otherData"></c-z-modal-form>
    </div>
</template>

<script>
    import InfoForm from '../components/common/InfoForm';
    import CZModalForm from '../components/common/CZModalForm';
    import Apply from '../components/join/Apply';
    import Partner from '../components/join/Partner';
    import Advantage from '../components/join/Advantage';
    import Advertisement from '@/components/common/Advertisement';
    import {Icon} from 'ant-design-vue';

    export default {
        name: "Join",
        components: {
            InfoForm,
            Apply,
            Partner,
            Advantage,
            CZModalForm,
            Advertisement,
            [Icon.name]: Icon,
        },
        data() {
            return {
                modalConfig:{
                    showModal:0
                },
                otherData:{serviceName:'加盟我们-担忧'},
                orderList:['addr','name','phone'],
                worry: [
                    {
                        icon: 'join-worry1.png',
                        title: '创业缺乏项目',
                        desc: '想创业，却不知道做什么项目好'
                    },
                    {
                        icon: 'join-worry2.png',
                        title: '从业经验欠缺',
                        desc: '对代账行业不了解，不知道从何入手'
                    },
                    {
                        icon: 'join-worry3.png',
                        title: '团队建设缺失',
                        desc: '销售及服务团队体系建设不知如何搭建'
                    }
                ],
                progress: ['申请加盟', '资质审核<br/>现场考察', '合约签署'],
                supports: [
                    {
                        icon: 'safety-certificate',
                        iconTheme: 'filled',
                        title: '团队建设',
                        desc: '销售团队搭建，拓宽获客渠道；会计团队培训，升级服务体验'
                    },
                    {
                        icon: 'fund',
                        iconTheme: 'filled',
                        title: '品牌授权',
                        desc: '加盟即享互联网财税品牌强力支持，有效提升您的市场占有率'
                    },
                    {
                        icon: 'contacts',
                        iconTheme: 'filled',
                        title: '运营推广',
                        desc: '线上广告代投放、新媒体热点传播、线上、线下活动支持'
                    },
                    {
                        icon: 'like',
                        iconTheme: 'filled',
                        title: '经营扶持',
                        desc: '标准化流程建立、模式输出、客户满意度调查、日常疑问解答'
                    }
                ]
            }
        }
    };
</script>
<style lang="less">
    .join-wrapper {
        box-sizing: border-box;
        padding-bottom: 20px;

        .banner {
            background-image: url("../assets/join-banner.jpg");
        }

        .info-form{
            position: absolute;
            top: 200px;
            left: 50%;
            width: 680px;
            margin-left: -340px;
        }
        /*公共样式*/

        section {
            .title {
                margin-top: 60px;
                font-size: 24px;

                &:after {
                    display: block;
                    content: '';
                    width: 30px;
                    height: 4px;
                    margin: 4px auto 30px;
                    background-color: @primary-color;
                }
            }
        }

        .worry {
            padding-bottom: 10px;

            .unit {
                text-align: left;
                display: flex;
                box-sizing: border-box;
                height: 120px;
                padding: 20px 20px 0 30px;
                margin-right: 40px;
                background-color: #fff;
                color: #666;

                .icon {
                    width: 48px;
                    height: 48px;
                    margin-right: 18px;
                    margin-top: 5px;
                }

                .worry-title {
                    margin-bottom: 8px;
                    font-size: 16px;
                    color: #333;
                }
            }

            .btn {
                width: 160px;
                height: 40px;
                border-radius: 20px;
                line-height: 40px;
                margin: 30px auto;
                background-color: #FE6313;
                color: #fff;
            }
        }

        .join-progress {
            padding-bottom: 60px;

            .flex-box {
                justify-content: center;
                align-items: center;

                .circle-box {
                    display: inline-block;
                    /*display: table-cell;*/
                    width: 100px;
                    height: 100px;
                    /*line-height: 100px;*/
                    border-radius: 50%;
                    background-color: @primary-color;
                    color: #fff;
                    font-size: 18px;
                    vertical-align: middle;
                    div{
                        display: table-cell;
                        width: 100px;
                        height: 100px;
                        vertical-align: middle;
                    }
                }

                .right-arrow{
                    width: 56px;
                    height: 21px;
                    margin: auto 36px;
                    display: inline-block;
                }
            }
        }

        .supports {
            padding-bottom: 50px;
            .flex-unit {
                box-sizing: border-box;
                padding: 30px;
                margin-right: 40px;
                border: 1px solid #E0E0E0;
                color: #666;

                .icon {
                    padding-bottom: 20px;
                    border-bottom: 1px solid #E0E0E0;
                    font-size: 52px;
                    color: #28A7E1;
                }

                .support-title {
                    margin: 15px auto 10px;
                    font-size: 16px;
                    color: #333;
                }
            }
        }

        .factor {
            width: 932px;
            height: 410px;
            margin: 0 auto;
        }

        .apply {
            width: 550px;
            margin: 0 auto 30px;
        }
    }
</style>
