<template>
    <div class="services-wrapper">
        <div class="service-header safe-content">
            <div class="left-img bg-cc" :style="`background-image:url(${require('../assets/service-header-bg.png')})`"></div>
            <div class="apply-box" v-if="service">
                <div class="service-name">{{service.name}}</div>
                <div>{{service.desc}}</div>
                <div class="tags">
                    <span class="tag" v-for="(tag,ti) in service.tags" :key="ti">{{tag}}</span>
                </div>
                <div class="form-box flex-box">
                    <div class="form-unit service-price" v-if="service.price">服务价格：{{service.price}}</div>
                    <div class="form-unit">服务地址：<a-select v-model="info.addr" :options="citiesConfig" style="width: 80px"></a-select></div>
                    <div class="form-unit">联系方式：<a-input v-model="info.phone" style="width: 130px"/></div>
                </div>
                <div>
                    <div class="btn btn-focus hover-point" @click="submitService">提交材料</div>
                    <div class="btn hover-point" @click="reqService(service.name)">免费咨询</div>
                </div>
            </div>
        </div>
        <div class="service-content safe-content">
            <div class="service-menus-box">
                <div v-for="(menu,mi) in menus" :key="mi" class="menu-box" v-show="menu.subMenu.length>0">
                    <div class="menu-name">{{menu.label}}</div>
                    <div class="submenu hover-point" v-for="(sub,si) in menu.subMenu" :key="`${mi}-${si}`" @click="changeService(sub.id)">{{sub.label}}</div>
                </div>
            </div>
            <div class="service-info-box" v-if="service">
                <div class="tabs flex-box" :class="[isFixed]">
                    <div class="flex-unit" v-for="(tab,ti) in tabs" :key="ti"
                         :class="{'tab-active': ti===cTab}" @click="tabNav(tab.href)">{{tab.label}}</div>
                </div>
                <div ref="detail" class="service-table">
                    <div class="table-box">
                        <div class="table-header">
                            <div class="table-title">{{service.detail.title}}</div>
                            <div>{{service.detail.desc}}</div>
                        </div>
                        <div class="table-content">
                            <div class="unit">
                                <div class="label">服务内容</div>
                                <div class="content" v-html="service.detail.content"></div>
                            </div>
                            <div class="unit">
                                <div class="label">服务时间</div>
                                <div class="content" v-html="service.detail.time"></div>
                            </div>
                            <div class="unit">
                                <div class="label">服务费用</div>
                                <div class="content" v-html="service.detail.expense"></div>
                            </div>
                            <div class="unit">
                                <div class="label">准备材料</div>
                                <div class="content" v-html="service.detail.materail"></div>
                            </div>
                            <div class="unit">
                                <div class="label">服务地区</div>
                                <div class="content" v-html="service.detail.area"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <the-services v-if="serviceRelative.advantage" ref="advantage" :data="serviceRelative.advantage"></the-services>
                <the-progress v-if="serviceRelative.process" ref="process" :data="serviceRelative.process"></the-progress>
                <the-faqs v-if="serviceRelative.reason" ref="reason" :data="serviceRelative.reason"></the-faqs>
                <the-advantages v-if="serviceRelative.question" ref="question" :data="serviceRelative.question"></the-advantages>
                <div ref="afterSales">
                    <after-sales></after-sales>
                </div>
            </div>
        </div>
        <c-z-modal :config="modalConfig"></c-z-modal>
    </div>
</template>

<script>
    import AfterSales from '../components/service/AfterSales';
    import TheServices from '../components/service/TheServices';
    import TheProgress from '../components/service/TheProgress';
    import TheFaqs from '../components/service/TheFaqs';
    import TheAdvantages from '../components/service/TheAdvantages';
    import {Input,Select} from 'ant-design-vue';
    import CZModal from '@/components/common/CZModal';
    export default {
        name: "Service",
        components: {
            TheServices,
            TheProgress,
            TheFaqs,
            TheAdvantages,
            AfterSales,
            CZModal,
            [Select.name]: Select,
            [Input.name]: Input
        },
        data(){
            return {
                id:this.$route.query.id,
                modalConfig:{
                    showModal:false,
                    hideHeader:true
                },
                info:{
                    addr:'福州',
                    phone:''
                },
                service:null,
                serviceRelative:{},
                menus:this.$store.state.headerMenus,
                cTab:0,
                tabs:[
                    {
                        label:'服务详情',
                        href:'detail'
                    },
                    {
                        label:'问题须知',
                        href:'faq'
                    },
                    {
                        label:'服务保障',
                        href:'afterSales'
                    }
                ],
                tabScrollHeight:null,
                isFixed:'',
                scrollTop:0,
                scrollStartTime:''
            }
        },
        computed:{
            citiesConfig(){
                return this.$store.state.cities.map(item=>{
                    return {
                        label:item.cityName,
                        value:item.cityName
                    }
                })
            }
        },
        async created() {
            this.$store.commit('changeStateVal',{key:'backgroundColor',value:'#F5FAFE'});
            await this.getServiceDetail();
        },
        mounted(){
            // let _this=this;
            window.addEventListener('scroll', ()=>{
                let top = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
                this.scrollTop=top;
            });
        },
        destroyed(){
            this.$store.commit('changeStateVal',{key:'backgroundColor',value:'#fff'});
        },
        methods:{
            async getServiceDetail(){
                let res=await this.$api.getServiceDetail(this.id);
                this.service={
                    name:res.serviceTypeName,
                    desc:res.alias,
                    tags:res.keywords && res.keywords.split(';'),
                    price:res.price,
                    detail:{
                        title:res.title,
                        desc:res.subheading,
                        content:res.serviceContent,
                        time:res.svctm,
                        expense:res.costDescription,
                        materail:res.material,
                        area:res.serviceArea
                    }
                }
                let relative={};
                (res.serviceTypeItemVOList || []).forEach(item=>{
                    let key=item.category.split('_')[1];
                    relative[key]={
                        title:item.title,
                        list:item.serviceTypeItemVOList
                    }
                });
                this.serviceRelative=relative;
                let _this=this;
                setTimeout(function () {
                    _this.getAreaHeight();
                },1000)
            },
            async submitService(){
                let info=this.info;
                if(!/^[1][3,4,5,7,8][0-9]{9}$/.test(info.phone)){
                    this.$message.error('请填写正确的手机号');
                    return false
                }
                let reqData={
                    cityCode:info.addr,
                    applicantTel:info.phone,
                    serviceName:`全部服务-${this.service.name}`
                };
                let res=await this.$api.toApplyDetailService(reqData);
                if(res===undefined){
                    this.modalConfig.showModal=true;
                }
            },
            reqService(service){
                let title=`全部服务-${service}`;
                window.triggleZc(title,true);
            },
            changeService(id){
                if(id!==this.id){
                    this.$router.push({query:{id}});
                }

            },
            tabNav(href){
                document.documentElement.scrollTop = document.body.scrollTop = this.tabScrollHeight[href];
            },
            getAreaHeight(){
                // 获取各部分高度，计算出各个tab的高度
                // 内容区域距顶部高度标准值
                let baseHeight=540;
                if(!this.$refs.detail){
                    setTimeout(this.getAreaHeight,1000);
                }else{
                    let detailHeight=this.$refs.detail.offsetHeight;
                    // 其他几块的高度
                    let advantageHeight=this.$refs.advantage ? (parseInt(window.getComputedStyle(this.$refs.advantage.$el).height) || 0) : 0;
                    let processHeight=this.$refs.process ? (parseInt(window.getComputedStyle(this.$refs.process.$el).height) || 0) : 0;
                    let reasonHeight=this.$refs.reason ? (parseInt(window.getComputedStyle(this.$refs.reason.$el).height) || 0) : 0;
                    let questionHeight=this.$refs.question ? (parseInt(window.getComputedStyle(this.$refs.question.$el).height) || 0) : 0;
                    let detailOffset=baseHeight+50;
                    let faqOffset=detailOffset+detailHeight+advantageHeight+processHeight;
                    let afterSales=faqOffset+reasonHeight+questionHeight-50;
                    this.tabScrollHeight={
                        detail:detailOffset,
                        faq:faqOffset,
                        afterSales:afterSales
                    }
                }
            },
            debounce(delay=100){
                let timer=null;
                return ()=>{
                    if(timer){
                        clearTimeout(timer)
                    }
                    timer=setTimeout(()=>{
                        let top = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
                        this.scrollTop=top;
                    },delay)
                }
            }
        },
        watch:{
            '$route.query'(newVal){
                this.id=newVal.id;
                this.getServiceDetail();
            },
            '$store.state.headerMenus'(newVal){
                this.menus=newVal;
            },
            'scrollTop'(newVal){
                // 内容区域距顶部高度标准值
                let baseHeight=540;
                if(!this.tabScrollHeight){
                    return false;
                }
                let {faq,afterSales}=this.tabScrollHeight;
                this.isFixed=newVal> baseHeight ? 'tabs-fixed' : '';
                if(newVal<faq){
                    this.cTab=0;
                }else if(newVal+10>afterSales){
                    this.cTab=2;
                }else{
                    this.cTab=1;
                }
            }
        }
    };
</script>
<style lang="less">
    .services-wrapper {
        font-size: 16px;
        .service-header{
            display: flex;
            box-sizing: border-box;
            height: 330px;
            padding-top: 30px;
            .left-img{
                width: 400px;
                margin-right: 50px;
                background-color: #54ABFC;
            }
            .apply-box{
                flex: 1;
                text-align: left;
                color: #656565;
                .service-name{
                    margin-bottom: 10px;
                    font-size: 26px;
                    color: #1A1A1A;
                }
                .tags{
                    margin-top: 20px;
                    .tag{
                        box-sizing: border-box;
                        padding: 5px 15px;
                        border: 1px solid #EE6860;
                        margin-right: 10px;
                        margin-bottom: 30px;
                        color: #EE6860;
                    }
                }
                .form-box{
                    margin-top: 30px;
                    margin-bottom: 40px;
                    .form-unit{
                        margin-right: 40px;
                    }
                    .service-price{
                        padding: 0 11px;
                        height: 30px;
                        line-height: 30px;
                        font-size: 14px;
                        position: relative;
                    }
                }
                .btn{
                    display: inline-block;
                    width: 134px;
                    height: 44px;
                    border: 1px solid @primary-color;
                    border-radius: 2px;
                    margin-right: 30px;
                    color: @primary-color;
                    line-height: 44px;
                    text-align: center;
                }
                .btn-focus{
                    background-color: @primary-color;
                    color: #fff;
                }
            }
        }
        .service-content{
            display: flex;
            margin-top: 70px;
            .service-menus-box{
                width: 400px;
                margin-right: 50px;
                text-align: left;
                .menu-box{
                    margin-bottom: 30px;
                    background-color: #fff;

                    &>div{
                        box-sizing: border-box;
                        height: 60px;
                        padding-left: 40px;
                        line-height: 60px;
                    }

                    .menu-name{
                        position: relative;
                        &:before{
                            content: '';
                            position: absolute;
                            top: 18px;
                            left: 15px;
                            width: 6px;
                            height: 24px;
                            background-color: @primary-color;
                        }
                    }
                    .submenu{
                        border-top: 1px solid #E8E8E8;
                    }
                }
            }
            .service-info-box{
                width: 750px;
                position: relative;
                box-sizing: border-box;
                padding-top: 50px;
                background-color: #fff;
                .tabs{
                    position: absolute;
                    top: 0;
                    display: flex;
                    width: 758px;
                    height: 50px;
                    border-bottom: 1px solid #EFEFEF;
                    line-height: 50px;
                    background-color: #fff;
                    z-index: 1;
                    .tab-active{
                        position: relative;
                        &:before{
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 4px;
                            left: 0;
                            background-color: @primary-color;
                        }
                    }
                }
                .tabs-fixed{
                    position: fixed;
                }
                .service-table{
                    box-sizing: border-box;
                    padding: 30px;
                    .table-box{
                        border: 1px solid #EFEFEF;
                        .table-header{
                            box-sizing: border-box;
                            padding: 30px 50px;
                            color: #fff;
                            text-align: left;
                            line-height: 1.8;
                            background-color: @primary-color;
                            .table-title{
                                font-size: 22px;
                            }
                        }
                        .table-content{
                            .unit{
                                display: flex;
                                border-top: 1px solid #EFEFEF;
                                align-items: center;
                                .label{
                                    width: 110px;
                                }
                                .content{
                                    width: 578px;
                                    box-sizing: border-box;
                                    padding: 25px 15px;
                                    border-left: 1px solid #EFEFEF;
                                    text-align: left;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
