<template>
    <div class="home-wrapper">
        <div class="safe-content">
            <section style="height: 420px">
                <a-carousel effect="fade" :autoplay="true">
                    <img class="banner-bg" v-for="(banner,index) in banners" :key="index" :src="banner.img" @click="handleBannerNav(banner.link)">
                </a-carousel>
            </section>
            <section class="order-block">
                <the-orders :list="orders"></the-orders>
            </section>
            <section>
                <div class="title">热门服务</div>
                <div class="flex-box hot-content">
                    <div class="flex-unit" v-for="(service,index) in Config.hotService"
                         :key="index"
                         :style="{background:`linear-gradient(140deg,${service.colorFrom},${service.colorTo})`}">
                        <div class="icon" :style="`background-image:url(${require('../assets/'+service.icon)})`"></div>
                        <p>{{service.title}}</p>
                        <p>{{service.desc}}</p>
                        <div class="btn hover-point" @click="askHotService(service.title)">{{service.btnText}}</div>
                    </div>
                </div>
            </section>
        </div>
        <div class="grey-bg">
            <div class="safe-content">
                <section>
                    <menus></menus>
                </section>
                <section>
                    <div class="title">我们的优势</div>
                    <div class="flex-box advantages">
                        <div class="flex-unit" v-for="(advantage,index) in Config.advantages" :key="index">
                            <div class="title">{{advantage.title}}</div>
                            <div class="desc">
								<div  v-for="(desc,idx) in advantage.desc" :key="idx">
									<div class="descTitle" v-html="desc.title"></div>
									<div class="descCont" v-html="desc.content"></div>
								</div>
							</div>
							<div class="advantagesImg">
								<img  v-if="advantage.icon" :src="require('../assets/'+advantage.icon)">
							</div>
                            
                        </div>
                    </div>
                </section>
                <section>
                    <div class="title">公司培训</div>
                    <train :list="trainList"></train>
                </section>
                <section style="display: none">
                    <div class="title">合作伙伴</div>
                    <div class="flex-box partner">
                        <img v-for="(partner,pi) in partners" :key="pi" :src="partner">
                    </div>
                </section>
                <section>
                    <div class="title">资讯动态</div>
                    <div class="flex-box news">
                        <div class="flex-unit" v-for="(item,index) in newsConfig" :key="index">
                            <div class="new-header" :style="{'background-color':item.color}">
                                <div class="icon" :style="`background-image:url(${require('../assets/'+item.icon)})`"></div>
                                <div>{{item.label}}</div>
                            </div>
                            <div class="new-content">
                                <p v-for="(each,ei) in item.list" :key="`${index}-${ei}`">
                                    <router-link class="new-title" :to="`/news/${each.id}`">{{each.title}}</router-link>
                                </p>
                                <router-link class="link" :to="{name: 'news',query: {id: item.id}}"><a-icon type="arrow-right" /></router-link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Config from '../config/index.json';
    import {Carousel,Icon} from 'ant-design-vue';
    import TheOrders from "@/components/home/TheOrders.vue";
    import Menus from "@/components/home/Menus.vue";
    import Train from "@/components/home/Train.vue";

    export default {
        name: "Home",
        components: {
            TheOrders:TheOrders,
            Train:Train,
            Menus:Menus,
            [Carousel.name]: Carousel,
            [Icon.name]: Icon
        },
        data(){
            return {
                Config:Config,
                banners:[{},{}],
                trainList:[{},{},{},{},{},{}],
                partners:[],
                orders:[
                    {
                        label: '极速预约',
                        name: 'book',
                        btnMsg: '免费预约',
                        tipLeft: '24px',
                        showModal:false,
                        callback:this.toApplyService
                    },
                    {
                        label: '公司注册核名',
                        name: 'register',
                        btnMsg: '免费核名',
                        tipLeft: '134px',
                        showModal:false,
                        callback:this.toConfirmName
                    },
                    {
                        label: '增值税测算',
                        name: 'tax',
                        btnMsg: '开始计算',
                        tipLeft: '254px',
                        showModal:false,
                        modalData:'',
                        callback:this.calcTax
                    },
                    {
                        label: '节税计算器',
                        name: 'saveTax',
                        btnMsg: '开始计算',
                        tipLeft: '360px',
                        showModal:false,
                        modalData:'',
                        callback:this.saveTax
                    },
                    {
                        label: '企业所得税测算',
                        name: 'incomeTax',
                        btnMsg: '开始计算',
                        tipLeft: '488px',
                        showModal:false,
                        modalData:'',
                        callback:this.calcCompanyTax
                    }
                ],
                newsConfig:[
                    {
                        id:'',
                        icon:'home-news1.png',
                        label:'财税资讯',
                        color:'#53ABFB'
                    },
                    {
                        id:'',
                        icon:'home-news2.png',
                        label:'代理记账',
                        color:'#1BC9AD'
                    },
                    {
                        id:'',
                        icon:'home-news3.png',
                        label:'公司注册',
                        color:'#E58F25'
                    },
                    {
                        id:'',
                        icon:'home-news4.png',
                        label:'公司动态',
                        color:'#F17761'
                    }
                ]
            }
        },
        async mounted(){
            this.$store.commit('changeStateVal',{key:'isSpinning',value:true});
            this.getBanner();
            this.getTraining();
            this.getPartners();
            this.getHomeNews();
            this.$store.commit('changeStateVal',{key:'isSpinning',value:false});
        },
        methods:{
            async getBanner(){
                let res=await this.$api.getHomeBanner();
                this.banners=(res.list || []).map(item=>{
                    return {
                        link:item.pageLink,
                        img:item.picture
                    }
                })
            },
            async getTraining(){
                let res=await this.$api.getHomeTraining();
                this.trainList=res;
            },
            async getHomeNews(){
                let res=await this.$api.getHomeNews();
                (res || []).forEach((item,index)=>{
                    let list=(item.newsContentNameList || []).map(each=>{
                        return {
                            id:each.newsContentGuid,
                            title:each.newsContentName
                        }
                    })
                    let cNews=this.newsConfig[index];
                    cNews['label']=item.newsMoldName;
                    cNews['id']=item.newsMoldGuid;
                    cNews['list']=list;
                })

            },
            handleBannerNav(url){
                window.location.href=url;
            },
            async getPartners(){
                let res=await this.$api.getPartners();
                this.partners=(res || []).map(item=>{
                    return item.picture
                })
            },
            askHotService(service){
                let title=`首页-热门服务-${service}`;
                window.triggleZc(title,true);
            },
            // order部分的方法
            async toApplyService(data,item){
                if(!/^[1][3,4,5,7,8][0-9]{9}$/.test(data.phone)){
                    this.$message.error('请填写正确的手机号');
                    return false
                }
                let req={
                    applicantTel:data.phone,
                    applicantName:data.name,
                    cityCode:data.addr,
                    serviceName:'极速预约'
                }
                let res=await this.$api.toApplyService(req);
                if(res===undefined){
                    item.showModal=true;
                }
            },
            toConfirmName(data){
                let title=`首页-免费核名-${data.addr}-${data.companyCode}-${data.industry}-${data.companyType}`;
                window.triggleZc(title,true);
            },
            calcTax(data,item){
                let sales=Number(data.sales);
                if(!sales){
                    this.$message.error('请填写含税销售额');
                    return false;
                }
                if(sales<0){
                    this.$message.error('请填写正确的含税销售额');
                    return false;
                }
                let taxRate=data.tax/100;
                item.modalData=((sales/(1 + taxRate)) * taxRate).toFixed(2);
                item.showModal=true;
            },
            saveTax(data,item){
                let {yearSales,yearProfit,taxIdentify}=data;
                if(!yearSales){
                    this.$message.error('请填写年含销售额');
                    return false;
                }
                if(yearSales<0){
                    this.$message.error('请填写正确的年含销售额');
                    return false;
                }
                if(!yearProfit){
                    this.$message.error('请填写年含销售额');
                    return false;
                }
                taxIdentify=taxIdentify / 100;
                let divided=yearSales * taxIdentify / (1 + taxIdentify) - yearSales * 0.01 / 1.01 + yearProfit * 0.2;
                let divideNum=yearSales * taxIdentify / (1 + taxIdentify) + yearProfit * 0.25;
                item.modalData=Math.abs(divided / divideNum * 100).toFixed(2);
                item.showModal=true;
            },
            calcCompanyTax(data,item){
                let companyTax=0;
                let amount=data.companyProfit;
                if(data.companyTaxType){
                    companyTax=amount * data.companyTaxType / 100;
                }else{
                    if(amount<1000000){
                        companyTax=amount * 0.05;
                    }else if(amount<3000000){
                        companyTax=50000 + (amount - 1000000) * 0.1;
                    }else{
                        companyTax=250000 + (amount - 3000000) * 0.2;
                    }
                }
                item.modalData=companyTax.toFixed(2);
                item.showModal=true;
            }

        },
        watch:{
            '$route.params.site'(){
                this.$store.commit('changeStateVal',{key:'showPage',value:false});
                setTimeout(()=>{
                    this.$store.commit('changeStateVal',{key:'showPage',value:true});
                },100)
            }
        }
    };
</script>
<style lang="less">
    .home-wrapper {
        font-size: 16px;

        /*公共样式*/
        section {
            box-sizing: border-box;
            margin-bottom: 30px;
            .title{
                padding: 20px 0;
                color: #333;
                font-size: 24px;
                text-align: left;
            }

        }
        .icon{
            background-position: center center;
            background-size: cover;
        }

        .ant-carousel {
            .slick-slide {
                text-align: center;
                height: 420px;
                overflow: hidden;
                .banner-bg{
                    width: 100%;
                    height: 420px;
                }
            }
        }

        .order-block {
            box-sizing: border-box;
            padding: 20px 170px;
            height: 160px;
            background-color: @primary-color;
        }

        .hot-content{
            color: #fff;
            .flex-unit{
                height: 230px;
                margin-right: 12px;
                .icon{
                    width: 70px;
                    height: 70px;
                    margin: 20px auto 15px;
                }
                p{
                    margin-bottom: 5px;
                }
                .btn{
                    box-sizing: border-box;
                    width: 114px;
                    height: 32px;
                    margin: 20px auto 16px;
                    border: 1px solid #fff;
                    border-radius: 2px;
                    line-height: 30px;
                }
                &:hover{
                    transform: translateY(-3px);
                }
            }

        }

        .grey-bg{
            padding-top: 30px;
            padding-bottom: 10px;

            .advantages{
                height: 400px;
				.flex-unit{
                    height: 100%;
                    margin-right: 12px;
                    background-color: #fff;
					position: relative;
					border-radius: 15px;
                    .title{
                        margin-top: 20px;
                        margin-bottom: 5px;
                        padding: 0;
                        font-size: 18px;
                        color: @primary-color;
                        text-align: center;
                    }
                    .desc{
						padding: 0 20px;
                        font-size: 14px;
                        color: #666666;
						text-align: left;
						min-height: 135px;
						.descTitle{
							line-height: 27px;
							// font-weight: bold;
							padding-left: 8px;
							position: relative;
							font-size: 14px;
							
							&::before{
								content: "";
								position: absolute;
								left: 0;
								top: 12px;
								
								width: 3px;
								height: 3px;
								border-radius: 50%;
								background-color: #999;
							}
							
						}
						.descCont{
							padding-left: 8px;
							font-size: 12px;
						}
                    }
					.advantagesImg{
						position: absolute;
						width: 100%;
						bottom: 30px;
						text-align: center;
					}
                    img{
						
						
                       
                    }
                }
            }

            .partner{
                justify-content: space-between;
                img{
                    width: 220px;
                    height:120px;
                }
            }

            .news{
                height: 380px;
                .flex-unit{
                    margin-right: 12px;
                    &:hover{
                        transform: translateY(-3px);
                        .link{
                            color: @primary-color;
                        }
                        /*transition: transform 1s;*/
                    }
                }

                .new-header{
                    box-sizing: border-box;
                    height: 120px;
                    padding-top: 10px;
                    color: #fff;
                    font-size: 18px;
                    .icon{
                        width: 40px;
                        height: 40px;
                        margin: 10px auto;
                    }
                }

                .new-content{
                    position: relative;
                    box-sizing: border-box;
                    height: 260px;
                    padding: 30px 20px;
                    background-color: #fff;
                    p{
                        width: 250px;
                        font-size: 14px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        word-break: break-all;

                        .new-title{
                            color: #333;
                            &:hover{
                                color: @primary-color;
                            }
                        }
                    }
                    .link{
                        position: absolute;
                        bottom: 20px;
                        left: 0;
                        width: 100%;
                        color: #999;
                        font-size: 24px;
                    }
                }
            }
        }
    }
</style>
