<template>
    <div class="advantage-wrapper">
        <div class="tabs">
            <div class="tab hover-point" :class="{'tab-active':cTab==='product'}" @click="cTab='product'">精准的市场运营</div>
            <div class="tab hover-point" :class="{'tab-active':cTab==='source'}" @click="cTab='source'">强大的技术支撑</div>
        </div>
        <div class="tabs-content">
            <div v-show="cTab==='product'" class="img bg-cc"
                 :style="`background-image:url(${require('../../assets/join-advantage-product.png')})`"></div>
            <div v-show="cTab==='source'" class="img bg-cc"
                 :style="`background-image:url(${require('../../assets/join-advantage-source.png')})`"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Advantage",
        data(){
            return {
                cTab:'product'
            }
        }
    }
</script>

<style lang="less" scoped>
    .advantage-wrapper{
        font-size: 18px;
        .tabs{
            display: flex;
            justify-content: center;
            padding: 10px 0 30px;
            .tab{
                margin: 0 60px;
                &:after{
                    display: block;
                    content: '';
                    width: 150px;
                    height: 2px;
                    margin-top: 4px;
                    background-color: #A9A9A9;
                }
            }
            .tab-active{
                color: @primary-color;
                &:after{
                    background-color: @primary-color;
                }
            }
        }
        .tabs-content{
            .img{
                width: 740px;
                height: 210px;
                margin: 40px auto;
            }
        }
    }
</style>