<template>
    <div class="the-progress-wrapper">
        <div class="service-progress">
            <div class="wrapper-title">服务流程</div>
            <div class="progress-content safe-content flex-box">
                <div v-for="(item,index) in progress" :key="index">
                    <div v-if="index!==0" class="bg-cc right-arrow"
                         :style="`background-image:url(${require('../../assets/a-agent-progress-right-arrow.png')})`"></div>
                    <div class="content-box">
                        <div class="bg-cc img"
                             :style="`background-image:url(${require('../../assets/'+item.icon)})`"></div>
                        <div class="content-title">{{item.title}}</div>
                        <div>{{item.desc}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="faqs">
            <div class="wrapper-title">常见问题</div>
            <swiper ref="mySwiper" :options="swiperOptions">
                <swiper-slide v-for="(faq,fi) in faqs" :key="fi">
                    <div class="slide-content">
                        <div class="question">{{faq.question}}</div>
                        <div>{{faq.answer}}</div>
                    </div>
                </swiper-slide>
            </swiper>
            <div :class="{'hide-swiper-btn':activeIndex===0}" class="swiper-button-prev swiper-button-white" @click="slickNav(-1)"></div>
            <div :class="{'hide-swiper-btn':activeIndex>=swiperMaxNum}" class="swiper-button-next swiper-button-white" @click="slickNav(1)"></div>
        </div>

    </div>

</template>

<script>
    import { Swiper, SwiperSlide  } from 'vue-awesome-swiper'
    import 'swiper/swiper-bundle.css'
    export default {
        name: "Progress",
        components: {
            Swiper,
            SwiperSlide
        },
        data() {
            return {
                progress:[
                    {
                        icon:'a-agent-progress-icon0.png',
                        title:'签订代账服务合同',
                        desc:'与客户沟通签订合同，根据企业详情进行专属顾问派工。'
                    },
                    {
                        icon:'a-agent-progress-icon1.png',
                        title:'取票通知',
                        desc:'APP推送取票通知，客户便捷提交票据专属财税专家核票。'
                    },
                    {
                        icon:'a-agent-progress-icon2.png',
                        title:'专属会计开始做账',
                        desc:'APP推送取票通知，可智能企业门户，随时随地了解做账进度。'
                    },
                    {
                        icon:'a-agent-progress-icon3.png',
                        title:'税金确定',
                        desc:'APP推送税金确认通知，合理控制税负降低企业经营风险。'
                    },
                    {
                        icon:'a-agent-progress-icon4.png',
                        title:'企业纳税申报',
                        desc:'专属主办会计迅速申报附加税、个税、增值税及所得税等。'
                    },
                    {
                        icon:'a-agent-progress-icon5.png',
                        title:'财务报表智能推送',
                        desc:'APP实时监控当月财务情况及资产负债，总账明细账全掌控。'
                    },
                    {
                        icon:'a-agent-progress-icon6.png',
                        title:'售后服务',
                        desc:'专属财税顾问随时免费提供日常企业经营中的财税咨询服务。'
                    }
                ],
                swiperOptions: {
                    centeredSlides: true,
                    slidesPerView: 3,
                    initialSlide:1,
                    spaceBetween:100,
                    grabCursor:true
                },
                swiper:{},
                faqs:[
                    {
                        question:'代理记账都要做哪些事情？',
                        answer:'包括每月做帐、出具凭证、报表、账本、每月每季报税、编制季报和年报、处理工商税务问题、规避企业财税风险等。'
                    },
                    {
                        question:'选择代理记账公司记账报税安全吗？',
                        answer:'智会算财税是具有代理记账资质的代账服务公司，严格遵守相关国家法律法规及会计制度，建立专属企业财务档案库，对客户信息进行严格保密。'
                    },
                    {
                        question:'刚注册的新公司需要做代账报税吗？',
                        answer:'根据《中华人民共和国税收征收管理法》、《税务登记管理办法》的规定，自领取营业执照后30日内应办理税务登记，每个月必须及时向税务局申报纳税。'
                    },
                    {
                        question:'企业一般要交哪些税？税率是多少？',
                        answer:'不同公司所属行业以及公司规模都不同，具体税种和税率要根据实际情况以及公司所在地相关法律法规而定。'
                    }
                ]
            }
        },
        computed: {
            activeIndex(){
                return this.swiper.activeIndex
            },
            swiperMaxNum(){
                return this.faqs.length - 1
            }
        },
        mounted(){
            this.swiper=this.$refs.mySwiper.$swiper;
        },
        methods:{
            slickNav(num){
                this.swiper.slideTo(this.activeIndex+num, 1000, false);
            }
        }
    }
</script>

<style lang="less" scoped>
    .the-progress-wrapper{
        box-sizing: border-box;
        height: 640px;
        padding-top: 40px;
        margin-bottom: 180px;
        background-color: #FE9E6C;
        .wrapper-title{
            font-size: 24px;
            color: #fff;

            &:after{
                display: block;
                content: '';
                width: 30px;
                height: 4px;
                margin: 4px auto 20px;
                background-color: #fff;
            }
        }
        .progress-content{
            .content-box{
                display: inline-block;
                width: 132px;
                font-size: 12px;
                color: #fff;
                text-align: left;
                .img{
                    width: 98px;
                    height: 98px;
                    margin: 0 auto;
                }
                .content-title{
                    margin-top: 15px;
                    margin-bottom: 5px;
                    font-size: 16px;
                    text-align: center;
                }
            }
            .right-arrow{
                display: inline-block;
                width: 43px;
                height: 16px;
                margin-top: 42px;
                vertical-align: top;
            }
        }
        .faqs{
            box-sizing: border-box;
            padding: 0 70px;
            transform: translateY(110px);
            .slide-content{
                box-sizing: border-box;
                padding: 30px;
                height: 200px;
                border-radius:10px;
                box-shadow: 0 0 2px 2px rgba(0,0,0,.2);
                background-color: #fff;
                color: #999;
                text-align: left;
                .question{
                    margin-top: 10px;
                    margin-bottom: 20px;
                    &:after{
                        content: '';
                        display: block;
                        width: 80px;
                        height: 2px;
                        margin-top: 8px;
                        background-color: #FE9E6C;
                        opacity: .5;
                    }
                }
            }
            .swiper-container{
                padding-top: 24px;
                height: 250px;
            }
            .hide-swiper-btn{
                opacity: .2;
            }

            .swiper-button-prev{
                left: 32%;
                top: 60%;
            }
            .swiper-button-next{
                right: 32%;
                top: 60%;
            }
            .swiper-slide-active{
                transform: scale(1.2);
                color: #666;
                .question{
                    color: #333;
                    &:after{
                        background-color: #FE9E6C;
                        opacity: 1;
                    }
                }
            }
        }
    }
</style>