<template>
    <div class="the-faqs-wrapper bg-cc" :style="`background-image:url(${require('../../assets/service-content-bg2.png')})`">
        <div class="the-faqs-title">{{data.title}}</div>
        <div class="the-faqs-content">
            <div class="faq" v-for="(faq,fi) in data.list" :key="fi">
                <div class="question">{{faq.title}}</div>
                <div class="answer">{{faq.content}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TheFaqs",
        props:{
            data:{
                type:Object,
                default:()=>{
                    return {title:'', list:[]}
                }
            }
        },
        data(){
            return {}
        }
    }
</script>

<style lang="less" scoped>
    .the-faqs-wrapper{
        box-sizing: border-box;
        padding: 10px 30px;
        height: 610px;
        .the-faqs-title{
            margin: 30px 0;
            font-size: 22px;
        }
        .the-faqs-content{
            font-size: 18px;
            text-align: left;
            .question{
                box-sizing: border-box;
                padding-left: 30px;
                width: 380px;
                height: 44px;
                border-radius: 22px;
                line-height: 44px;
                color: #fff;
                background-color: @primary-color;
                font-size: 16px;
            }
            .answer{
                box-sizing: border-box;
                padding: 20px 30px;
            }
        }
    }
</style>