<template>
    <div class="about-company-wrapper">
        <div class="flex-box">
            <div class="company-img-part bg-cc" :style="`background-image:url(${require('../../assets/about-company1.png')})`"></div>
            <div class="flex-unit">
				<div>智会算成立于2017年6月，是一个基于“数字化+企业生态”服务，满足中小微企业全生命周期各项需求的综合性企服平台。业务涵盖代理记账、财务外包、税务合规、证件资质、知识产权、金融法务、项目申报、同行培训、系统定制开发等。累计服务客户3万+。</div>
				<div>智会算致力于用科技赋能代账行业，现有自研信息化产品如“销售管理系统”“财税管理系统”、“会计管理系统”、“票账税一体化作业系统”、“外勤服务系统”、“培训系统”、“供应商系统”等。</div>
				<div> 同时，智会算借助数字化工具+服务落地搭建“晓智AI会计共享中心”，实现人工+智能全流程做账、报税，帮助代账公司降本增效。并塔建“晓智财会兼职平台”，解决代账企业不同层级员工招聘难、用工成本高、财会不专业等问题，将社会闲置劳动力充分发挥，完美适配。智会算致力于与您一起，共创行业新的未来！</div>
			</div>
        </div>
        <div class="company-img-full bg-cc" :style="`background-image:url(${require('../../assets/about-company2.png')})`"></div>
    </div>
</template>



<script>
    export default {
        name: "Company"
    }
</script>

<style lang="less" scoped>
    .about-company-wrapper{
        font-size: 16px;
        text-align: left;
        text-indent: 32px;
        .company-img-part{
            width: 550px;
            height: 350px;
            margin-right: 60px;
        }
        .company-img-full{
            height: 300px;
            margin-top: 50px;
        }
    }
</style>