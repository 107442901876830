<template>
    <div class="expense-req-wrapper">
        <div class="tabs flex-box">
            <div class="flex-unit hover-point" :class="{'tab-active':cTab===0}" @mouseenter="cTab=0">工商注册类</div>
            <div class="flex-unit hover-point" :class="{'tab-active':cTab===1}" @mouseenter="cTab=1">工商变更类</div>
        </div>
        <div class="tabs-content">
            <div class="tab-content" v-show="cTab===0">
                <div class="flex-box" v-for="(req,ri) in regOptions" :key="ri">
                    <div class="label">{{req.label}}</div>
                    <div>
                        <div class="option hover-point" v-for="(item,index) in req.options" :key="`${ri}-${index}`"
                             :class="{'option-active':reqVal[req.name]==item}"
                             @click="reqVal[req.name]=item">{{item}}</div>
                    </div>
                </div>
            </div>
            <div class="tab-content change-content" v-show="cTab===1">
                <div class="label">服务内容</div>
                <div class="change-options">
                    <div class="option hover-point" v-for="(item,index) in servicesContent" :key="index"
                         :class="{'option-active':reqVal['serviceList'].includes(item)}"
                         @click="handleService(item)">{{item}}</div>
                </div>
            </div>
            <div class="flex-box">
                <div class="label">注册区域</div>
                <div>
                    <div class="option">
                        <a-select v-model="reqVal.addr" :options="citiesConfig"></a-select>
                    </div>
                    <div class="option option-active hover-point" @click="openModal">一键查询</div>
                </div>
            </div>
        </div>
        <c-z-modal-form :config="modalConfig" :otherData="otherData"></c-z-modal-form>
    </div>
</template>

<script>
    import {Select} from 'ant-design-vue';
    import CZModalForm from '@/components/common/CZModalForm';
    export default {
        name: "ExpenseReq",
        components:{
            [Select.name]: Select,
            CZModalForm
        },
        data(){
            return {
                cTab:0,
                modalConfig:{
                    showModal:0,
                    hideArr:true
                },
                otherData:{},
                reqVal:{
                    hasAddr:'',
                    operation:'',
                    service:'',
                    taxType:'',
                    addr:'福州',
                    serviceList:[]
                },
                regOptions:[
                    {
                        label:'有无地址',
                        name:'hasAddr',
                        options:['有地址','无地址']
                    },
                    {
                        label:'经营范围',
                        name:'operation',
                        options:['含销售类','不含销售类']
                    },
                    {
                        label:'服务内容',
                        name:'service',
                        options:['公司注册一证五章','公司注册一证不含章']
                    },
                    {
                        label:'纳税类型',
                        name:'taxType',
                        options:['一般纳税人','小规模纳税人']
                    }
                ],
                servicesContent:['五证合一','同区地址变更','经营范围变更','公司名称变更','减资/增资变更','跨区变更',
                    '有限公司股权转让','股份有限公司股权转让','有限合伙企业股权转让','执照全套丢失']
            }
        },
        computed:{
            citiesConfig(){
                return this.$store.state.cities.map(item=>{
                    return {
                        label:item.cityName,
                        value:item.cityName
                    }
                })
            }
        },
        methods:{
            handleService(val){
                let index=this.reqVal.serviceList.indexOf(val);
                if(index>-1){
                    this.reqVal.serviceList.splice(index,1)
                }else{
                    this.reqVal.serviceList.push(val)
                }
            },
            openModal(){
                let data=this.reqVal;
                if(this.cTab===0){
                    this.otherData={
                        cityCode:data.addr,
                        hasRegAddr:data.hasAddr,
                        serviceContent:data.service,
                        taxType:data.taxType,
                        businessScope:data.operation,
                        serviceName:'公司注册-工商注册类'
                    }
                }else{
                    this.otherData={
                        cityCode:data.addr,
                        serviceContent:data.serviceList.join() || '',
                        serviceName:'公司注册-工商变更类'
                    }
                }
                this.modalConfig.showModal=true;
            }
        }
    }
</script>

<style lang="less" scoped>
    .expense-req-wrapper{
        transition: all 1s;
        font-size: 16px;
        .tabs{
            height: 60px;
            line-height: 60px;
            font-size: 24px;

            .tab-active{
                border-bottom: 3px solid @primary-color;
            }
        }
        .tabs-content{
            padding-top: 50px;
            padding-left: 74px;
            .flex-box{
                align-items: center;
                margin-bottom: 30px;
            }
            .label{
                text-align: left;
                width: 120px;
            }
            .option{
                display: inline-block;
                width: 170px;
                height: 54px;
                margin-right: 44px;
                border: 1px solid #D4D4D4;
                border-radius: 27px;
                line-height: 54px;
                .ant-select{
                    width: 60%;
                    /deep/.ant-select-selection{
                        box-shadow:none;
                        border: none;
                    }

                    /deep/.ant-select-selection-selected-value{
                        font-size: 16px;
                        color: #333;
                    }
                }

            }
            .option-active{
                border-color: @primary-color;
                color: @primary-color;
            }

            .change-content{
                display: flex;
                .label{
                    padding-top: 15px;
                }
                .change-options{
                    width: 430px;
                    .option{
                        margin-bottom: 25px;
                    }
                }
            }
        }
    }
</style>