<template>
    <div class="registration-wrapper">
        <div class="banner"></div>
        <section class="safe-content" style="margin-bottom: 30px">
            <div class="title">公司注册</div>
            <div class="flex-box registration-container">
                <div class="user">
                    <registration-form @showModal="modalConfig.showModal=true"></registration-form>
                </div>
                <div class="flex-unit">
                    <notice-event></notice-event>
                </div>
            </div>
        </section>
        <div class="grey-bg">
            <section class="safe-content">
                <div class="title">工商热门服务</div>
                <hot-service></hot-service>
            </section>
            <section>
                <advertisement></advertisement>
            </section>
            <section class="safe-content">
                <div class="title">费用测算快速通道</div>
                <div class="flex-box expense-req">
                    <div class="advisor">
                        <advisor></advisor>
                    </div>
                    <div class="flex-unit expense-box">
                        <expense-req></expense-req>
                    </div>
                </div>
            </section>
        </div>
        <section>
            <knowledge></knowledge>
        </section>
        <section>
            <div class="title">相关政策咨询</div>
            <policy-req></policy-req>
        </section>
        <c-z-modal :config="modalConfig"></c-z-modal>
    </div>
</template>

<script>
    import RegistrationForm from '../components/registration/RegistrationForm';
    import NoticeEvent from '../components/registration/NoticeEvent';
    import HotService from '../components/registration/HotService';
    import Advisor from '../components/registration/Advisor';
    import ExpenseReq from '../components/registration/ExpenseReq';
    import Knowledge from '../components/registration/Knowledge';
    import PolicyReq from '../components/registration/PolicyReq';
    import CZModal from '@/components/common/CZModal';
    import Advertisement from '@/components/common/Advertisement';
    import {Icon} from 'ant-design-vue';
    export default {
        name: "Registration",
        components: {
            RegistrationForm,
            NoticeEvent,
            HotService,
            ExpenseReq,
            Advisor,
            Knowledge,
            PolicyReq,
            CZModal,
            Advertisement,
            [Icon.name]:Icon
        },
        data() {
            return {
                modalConfig:{
                    showModal:false,
                    hideHeader:true
                }
            }
        }
    };
</script>
<style lang="less">
    .registration-wrapper{
        .banner{
            background-image: url("../assets/registration-banner.png");
        }

        /*公共样式*/
        section{
            .title{
                margin-top: 60px;
                font-size: 24px;

                &:after{
                    display: block;
                    content: '';
                    width: 30px;
                    height: 4px;
                    margin: 4px auto 30px;
                    background-color: @primary-color;
                }
            }
        }

        .registration-container{
            height: 490px;
            &>div{
                box-shadow:0 0 2px 1px rgba(0,0,0,.1);
            }
            .user{
                width: 420px;
                margin-right: 20px;
            }
        }
        .grey-bg{
            padding-bottom: 50px;
            .expense-req{
                height: 600px;
                background-color: #fff;
                .advisor{
                    box-sizing: border-box;
                    width: 480px;
                    padding: 28px 55px;
                }
                .expense-box{
                    padding-top: 16px;
                }
            }
        }


    }
</style>
