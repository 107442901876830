<template>
    <div class="check-wrapper">
        <div class="banner bg-cc"></div>
        <div class="safe-content info-form">
            <info-form :list="orderList" btnMsg="免费核名" :isCustomerService="true"></info-form>
        </div>
        <div class="grey-bg" style="padding-bottom: 50px">
            <section class="safe-content">
                <div class="title">公司核名注意事项</div>
                <div class="flex-box check-notice">
                    <div class="flex-unit bg-cc" v-for="(item,index) in noticeItems" :key="index">
                        <div class="notice-title">{{item.title}}</div>
                        <div class="desc">{{item.desc}}</div>
                    </div>
                </div>
            </section>
        </div>
        <section class="safe-content check-need">
            <div class="title">公司核名必知事项</div>
            <div class="flex-box">
                <router-link class="flex-unit" v-for="(item,index) in needItems" :key="index"
                             :to="{name: 'article',params:{id:item.param}}">
                    <div class="icon" :style="`background-image:url(${require('../assets/'+item.icon)})`"></div>
                    <div class="desc">{{item.desc}}</div>
                </router-link>
            </div>
            <div class="btn hover-point" @click="moreKnowledge">更多相关知识</div>
        </section>
        <div v-if="false" class="grey-bg" style="padding-bottom: 50px">
            <section class="safe-content">
                <div class="title">专业企业服务提供商</div>
                <div class="flex-box support">
                    <div class="support-unit" v-for="(item,index) in supports" :key="index">
                        <img :src="require('../assets/'+item.icon)">
                        <div class="desc">{{item.title}}</div>
                    </div>
                </div>
            </section>
        </div>
        <section>
            <advertisement></advertisement>
        </section>
    </div>
</template>

<script>
    import InfoForm from '../components/common/InfoForm';
    import Advertisement from '@/components/common/Advertisement';
    export default {
        name: "Check",
        components: {
            InfoForm:InfoForm,
            Advertisement
        },
        data(){
            return {
                orderList:['addr','companyNo','industry','companyType'],
                noticeItems:[
                    {
                        bg:'check-notice1.png',
                        title:'与其他公司重名',
                        desc:'如：华为科技与华为技术算重名'
                    },
                    {
                        bg:'check-notice2.png',
                        title:'触犯驰名商标',
                        desc:'如：万科、老干妈'
                    },
                    {
                        bg:'check-notice3.png',
                        title:'与知名公司名混淆',
                        desc:'如：微软、腾讯、阿里巴巴'
                    },
                    {
                        bg:'check-notice4.png',
                        title:'使用繁体/数字/英文',
                        desc:'如：會/123/abc'
                    },
                    {
                        bg:'check-notice5.png',
                        title:'使用行业通用词',
                        desc:'如：电脑、科技、企业等'
                    },
                    {
                        bg:'check-notice6.png',
                        title:'带有宗教色彩',
                        desc:'如：耶稣、伊斯兰等'
                    }
                ],
                needItems:[
                    {
                        icon:'check-need1.png',
                        desc:'公司核名常见规则',
                        param:2
                    },
                    {
                        icon:'check-need2.png',
                        desc:'公司名称业务范围及服务支持',
                        param:3
                    },
                    {
                        icon:'check-need3.png',
                        desc:'公司名称相同相近对比规则',
                        param:4
                    },
                    {
                        icon:'check-need4.png',
                        desc:'注册公司的流程简版',
                        param:5
                    },
                    {
                        icon:'check-need5.png',
                        desc:'企业名称禁限用规则',
                        param:6
                    }
                ],
                supports:[
                    {
                        icon:'check-support1.png',
                        title:'公司注册'
                    },
                    {
                        icon:'check-support2.png',
                        title:'代理记账'
                    },
                    {
                        icon:'check-support3.png',
                        title:'企业税筹'
                    },
                    {
                        icon:'check-support4.png',
                        title:'个人税筹'
                    },
                    {
                        icon:'check-support5.png',
                        title:'财税审计'
                    }
                ]
            }
        },
        methods:{
            moreKnowledge(){
                let title=`企业核名-公司核名更多必知事项`;
                window.triggleZc(title,true);
            }
        }
    };
</script>
<style lang="less">
    .check-wrapper {
        box-sizing: border-box;
        padding-bottom: 50px;

        .banner {
            background-image: url("../assets/check-banner.png");
        }

        /*公共样式*/

        section {
            .title{
                margin-top: 60px;
                font-size: 24px;

                &:after{
                    display: block;
                    content: '';
                    width: 30px;
                    height: 4px;
                    margin: 4px auto 40px;
                    background-color: @primary-color;
                }
            }
        }

        .info-form{
            position: absolute;
            top: 200px;
            left: 50%;
            width: 800px;
            margin-left: -400px;
        }

        .check-notice{
            .flex-unit{
                box-sizing: border-box;
                height: 286px;
                padding: 60px 20px 0;
                background-color: #f8f8f8;
                .notice-title{
                    margin-bottom: 30px;
                    font-size: 16px;
                }
                &:nth-child(2n){
                    background-color: #fff;
                }
            }

        }

        .check-need{
            .icon{
                width: 79px;
                height: 79px;
                margin: 30px auto;
            }
            .btn{
                width: 163px;
                height: 45px;
                margin: 70px auto 60px;
                background-color: #F17861;
                border-radius: 23px;
                color: #fff;
                line-height: 45px;
            }
            .desc{
                color: #333;
            }
        }

        .support{
            justify-content: space-between;
            .support-unit{
                box-sizing: border-box;
                width: 225px;
                height: 160px;
                padding-top: 30px;
                background-color: #fff;
                img{
                    width: 56px;
                    height: 56px;
                    margin: 0 auto 10px;
                }
            }
        }
    }
</style>
