<template>
    <z-modal :config="modalConfig" class="modal-box modal-box-wrapper">
        <img src="@/assets/icon-success-primary.png" class="modal-icon">
        <div class="modal-title">操作成功</div>
        <div class="desc">提交成功，我们会尽快联系您！</div>
        <div class="modal-btn hover-point" @click="modalConfig.showModal=false">好的</div>
    </z-modal>
</template>

<script>
    import ZModal from "../ZModal";
    export default {
        name: "CZModal",
        components:{
            ZModal
        },
        props:{
            config:{
                type:Object,
                required:true
            }
        },
        data(){
            return {
                modalConfig:this.config
            }
        },
        watch:{
            'config.showModal'(newVal){
                this.modalConfig.showModal = newVal;
            }
        }
    }
</script>

<style lang="less">
    .modal-box-wrapper{
        .modal-icon{
            margin-bottom: 20px;
        }
        .modal-title{
            font-size: 24px;
        }
        .desc{
            color: #999;
        }
    }
</style>