<template>
    <div class="news-detail-wrapper">
        <div class="breadcrumb">
            <div class="safe-content">
                <a-breadcrumb>
                    <a-breadcrumb-item><router-link to="/index">首页</router-link></a-breadcrumb-item>
                    <a-breadcrumb-item><router-link :to="{name:'news',query: {id: detail.newsMoldGuid}}">{{detail.newsMoldName}}</router-link></a-breadcrumb-item>
                    <a-breadcrumb-item><router-link :to="`/news/${detail.guid}`">{{detail.newsContentName}}</router-link></a-breadcrumb-item>
                </a-breadcrumb>
            </div>
        </div>
        <div class="safe-content detail-box">
            <div class="news-title">{{detail.newsContentName}}</div>
            <div class="author">文章作者：{{detail.author}} | 发表时间：{{detail.publishTime}}</div>
            <div class="summary">{{detail.summary}}</div>
            <div class="content" v-html="detail.content"></div>
        </div>
    </div>
</template>

<script>
    import {Breadcrumb} from 'ant-design-vue';
    const BreacrumbItem=Breadcrumb.Item;
    export default {
        name: "NewsDetail",
        components:{
            [Breadcrumb.name]:Breadcrumb,
            [BreacrumbItem.name]:BreacrumbItem
        },
        data() {
            return {
                detail:{}
            }
        },
        created() {
            let id=this.$route.params.id;
            console.log(id);
            this.getNewsDetail(id);
        },
        methods:{
            async getNewsDetail(id){
                let res=await this.$api.getNewsDetail(id);
                console.log(res);
                this.detail=res;
            }
        }
    };
</script>
<style lang="less">
    .news-detail-wrapper {
        text-align: left;
        .breadcrumb{
            padding: 20px 0;
            line-height: 60px;
            background-color: #F5FAFE;
        }
        .detail-box{
            padding-top: 50px;
            background-color: #fff;
            .news-title{
                font-size: 25px;
                font-weight: 700;
            }
            .author{
                margin: 20px 0;
                color: #999;
            }
            .summary{
                margin-bottom: 30px;
                color: #999;
            }
        }
    }
</style>
