<template>
    <div class="train-wrapper">
        <div class="aside-box">
            <div class="bg-cc top-img train-img" :style="`background-image:url(${list[0].picture})`">
                <router-link class="cover" :to="{name: 'trainDetail',query: {id: list[0].companyTrainingGuid}}">{{list[0].title}}</router-link>
            </div>
            <div class="bg-cc bottom-img train-img" :style="`background-image:url(${list[3].picture})`">
                <router-link class="cover" :to="{name: 'trainDetail',query: {id: list[3].companyTrainingGuid}}">{{list[3].title}}</router-link>
            </div>
        </div>
        <div class="center-box">
            <div class="bg-cc top-img train-img" :style="`background-image:url(${list[1].picture})`">
                <router-link class="cover" :to="{name: 'trainDetail',query: {id: list[1].companyTrainingGuid}}">{{list[1].title}}</router-link>
            </div>
            <div class="bg-cc bottom-img train-img" :style="`background-image:url(${list[4].picture})`">
                <router-link class="cover" :to="{name: 'trainDetail',query: {id: list[4].companyTrainingGuid}}">{{list[4].title}}</router-link>
            </div>
        </div>
        <div class="aside-box">
            <div class="bg-cc top-img train-img" :style="`background-image:url(${list[2].picture})`">
                <router-link class="cover" :to="{name: 'trainDetail',query: {id: list[2].companyTrainingGuid}}">{{list[2].title}}</router-link>
            </div>
            <div class="bg-cc bottom-img train-img" :style="`background-image:url(${list[5].picture})`">
                <router-link class="cover" :to="{name: 'trainDetail',query: {id: list[5].companyTrainingGuid}}">{{list[5].title}}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Train",
        props:{
            list:{
                type:Array,
                required:true
            }
        }
    }
</script>

<style lang="less" scoped>
    .train-wrapper{
        display: flex;
        width: 100%;
        height: 510px;
        .top-img{
            margin-bottom: 10px;
        }
        .train-img{
            width: 100%;
            overflow: hidden;
            &:hover{
                .cover{
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }

        .cover{
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,.1);
            color:#fff;
            transform: translateY(-100%);
            opacity: 0;
        }
        .aside-box{
            width: 360px;
            .top-img{
                height: 200px;
                line-height: 200px;
            }
            .bottom-img{
                height: 300px;
                line-height: 300px;
            }
        }
        .center-box{
            width: 440px;
            margin: 0 20px;
            .top-img{
                height: 300px;
                line-height: 300px;
            }
            .bottom-img{
                height: 200px;
                line-height: 200px;
            }
        }
    }
</style>