<template>
    <div class="notice-event-wrapper">
        <div class="flex-box tabs">
            <div class="flex-unit" :class="{'tab-active':cTab===0}" @click="cTab=0">注册地址须知</div>
            <div class="flex-unit" :class="{'tab-active':cTab===1}" @click="cTab=1">企业核名须知</div>
        </div>
        <div class="tabs-content">
            <div class="tab-content" v-show="cTab===0">
                <div>虽说公司注册地址后期可以变更，但跨城区的税务变更会比较麻烦，所以在选择注册地址时，最好先咨询专业人员，确定好城区。</div>
                <div class="btn"><span class="hover-point" @click="openZc('选址方案咨询')">选址方案咨询</span></div>
                <div class="flex-box">
                    <div class="flex-unit">
                        <div class="bg-cc" :style="`background-image:url(${require('../../assets/registration-addr1.png')})`"></div>
                        <div class="label" @click="openZc('税收优惠地域')">税收优惠地域<a-icon type="right" /></div>
                    </div>
                    <div class="flex-unit">
                        <div class="special-part">
                            <div class="bg-cc" :style="`background-image:url(${require('../../assets/registration-addr2.png')})`"></div>
                            <div class="label" @click="openZc('商业楼')">商业楼<a-icon type="right" /></div>
                        </div>
                        <div class="special-part">
                            <div class="bg-cc" :style="`background-image:url(${require('../../assets/registration-addr3.png')})`"></div>
                            <div class="label" @click="openZc('写字楼')">写字楼<a-icon type="right" /></div>
                        </div>
                    </div>
                    <div class="flex-unit">
                        <div class="bg-cc" :style="`background-image:url(${require('../../assets/registration-addr4.png')})`"></div>
                        <div class="label" @click="openZc('孵化器（集中办公）')">孵化器（集中办公）<a-icon type="right" /></div>
                    </div>
                </div>
            </div>
            <div class="tab-content" v-show="cTab===1">
                <div>准备1-5个企业名字，注册公司初步就是核名，了解核名规则能有效降低企业注册周期，并规避后期商标等知识产权的风险。</div>
                <div class="btn"><span class="hover-point" @click="openZc('极速核名')">极速核名</span></div>
                <div class="flex-box">
                    <div class="flex-unit">
                        <div class="bg-cc" :style="`background-image:url(${require('../../assets/registration-verify1.png')})`"></div>
                        <div class="label" @click="openZc('公司常见核名规则')">公司常见核名规则<a-icon type="right" /></div>
                    </div>
                    <div class="flex-unit">
                        <div class="bg-cc" :style="`background-image:url(${require('../../assets/registration-verify2.png')})`"></div>
                        <div class="label" @click="openZc('企业名称相同相近对比规则')">企业名称相同相近对比规则<a-icon type="right" /></div>
                    </div>
                    <div class="flex-unit">
                        <div class="bg-cc" :style="`background-image:url(${require('../../assets/registration-verify3.png')})`"></div>
                        <div class="label" @click="openZc('注册公司的流程简版')">注册公司的流程简版<a-icon type="right" /></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Icon} from 'ant-design-vue';
    export default {
        name: "NoticeEvent",
        components: {
            [Icon.name]: Icon
        },
        data(){
            return {
                cTab:0
            }
        },
        methods:{
            openZc(service){
                let title=`公司注册-${service}`;
                window.triggleZc(title,true);
            }
        }
    }
</script>

<style lang="less" scoped>
    .notice-event-wrapper{
        transition: all 1s;
        .tabs{
            width:100%;
            height: 50px;
            line-height: 50px;
            font-size: 16px;
            .flex-unit{
                cursor: pointer;
            }

            .tab-active{
                background-color: @primary-color;
                color: #fff;
            }
        }
        .tabs-content{
            box-sizing: border-box;
            padding: 30px 100px;
            font-size: 14px;
            text-align: left;
            .btn{
                margin-top: 10px;
                margin-bottom: 25px;
                text-align: right;
                span{
                    display: inline-block;
                    width:164px;
                    height: 40px;
                    border-radius: 20px;
                    line-height: 40px;
                    background-color: #FE6313;
                    text-align: center;
                    color: #fff;
                }
            }
            .flex-box{
                text-align: center;
                cursor: pointer;
                font-size: 12px;

                .flex-unit{
                    box-sizing: border-box;
                    height: 270px;
                    padding-bottom: 30px;
                    margin-right: 20px;
                    .special-part{
                        box-sizing: border-box;
                        height: 140px;
                        padding-bottom: 40px;
                    }

                    .bg-cc{
                        width: 100%;
                        height: 100%;
                        margin-bottom: 10px;
                    }
                    .label:hover{
                        color:@primary-color;
                    }
                }
            }
        }

    }
</style>