<template>
    <a-modal class="reset-modal-wrapper" v-model="config.showModal" :centered="true">
        <div class="z-modal-header" v-show="!config.hideHeader">
            <slot name="header"></slot>
        </div>
        <div class="z-modal-content">
            <slot></slot>
        </div>
    </a-modal>
</template>

<script>
    import {Modal} from 'ant-design-vue';
    export default {
        name: "ZModal",
        components: {
            [Modal.name]: Modal
        },
        props:{
            config:{
                type:Object,
                default:()=>{}
            }
        }
    }
</script>

<style lang="less">
    .reset-modal-wrapper{
        // 重置原生样式
        .ant-modal{
            width: 480px !important;
        }
        .ant-modal-content{
            border-radius: 10px;
            overflow: hidden;
        }
        .ant-modal-body{
            padding: 0;
            overflow: hidden;
        }
        .ant-modal-close-x{
            height: 70px;
            font-size: 27px;
            color: #fff;
            line-height: 70px;
        }
        .ant-modal-footer{
            display: none;
        }

        .z-modal-header{
            height: 70px;
            color: #fff;
            background-color: @primary-color;
        }
        .z-modal-content{
            padding: 45px 30px 30px;
            text-align: center;
            font-size: 16px;
            color: #333;
        }

    }
</style>