<template>
    <div class="sidebar-wrapper">
        <div class="menu-list">
            <div>
                <a-icon class="hover-point" type="wechat" theme="outlined" :style="{fontSize:'24px'}"/>
                <p class="hover-point">微信沟通</p>
                <div class="popup-card">
                    <div class="info">
                        <div><a-icon class="icon" type="wechat" />微信沟通</div>
                        <div class="info-desc">专属顾问，随时咨询</div>
                    </div>
                    <img class="qr-code" src="@/assets/wx-gzh.jpg">
                </div>
            </div>
            <div>
                <a-icon class="hover-point" type="phone" :style="{fontSize:'22px'}"/>
                <p class="hover-point">免费电话</p>
                <div class="popup-card">
                    <div class="free-phone">免费电话：<span>400-168-5560</span></div>
                    <div class="desc">请输入您的联系电话</div>
                    <a-input v-model="concact" placeholder="请输入您的联系电话"/>
                    <a-button class="btn" @click="submitPhone">免费通话</a-button>
                </div>
            </div>
            <div class="back-top">
                <a-back-top :visibilityHeight="10">
                    <a-icon type="arrow-up" :style="{fontSize:'20px'}"/>
                    <p>回到顶部</p>
                </a-back-top>
            </div>
        </div>
    </div>
</template>

<script>
    import {Icon,BackTop,Input,Button} from 'ant-design-vue';
    export default {
        name: 'AppSidebar',
        components: {
            [Icon.name]: Icon,
            [BackTop.name]: BackTop,
            [Input.name]: Input,
            [Button.name]: Button
        },
        data(){
            return {
                concact:'',
            }
        },
        methods:{
            async submitPhone(){
                if(!/^[1][3,4,5,7,8][0-9]{9}$/.test(this.concact)){
                    this.$message.error('请填写正确的手机号');
                    return false
                }
                let req={
                    applicantTel:this.concact,
                    serviceName:'免费电话'
                }
                let res=await this.$api.toApplyService(req);
                if(res===undefined){
                    this.$store.commit('changeStateVal',{key:'modalConfig',value:{showModal:true}});
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .sidebar-wrapper{
        position: fixed;
        top: 40%;
        right: 20px;
        transform: translateY(-50%);
        color: #fff;
        z-index: 999;

        .menu-list{
            box-sizing: border-box;
            width: 70px;
            height: 132px;
            background-color: rgba(0,0,0,.45);
            /*transition: all 1s ease-in-out;*/
            &>div{
                position: relative;
                box-sizing: border-box;
                padding-top: 10px;
                padding-bottom: 10px;

                /*&:hover{*/
                /*    background-color: @primary-color;*/
                /*}*/
                p{
                    font-size: 14px;
                    margin-bottom: 0;
                }

                .popup-card{
                    display: none;
                    position: absolute;
                    right: 70px;
                    top: 0;
                    box-sizing: border-box;
                    width: 290px;
                    padding: 16px 18px;
                    background-color: #fff;
                    font-size: 16px;
                    color: #333;

                    &:after{
                        display: inline-block;
                        content: '';
                        position: absolute;
                        right: -16px;
                        top: 10px;
                        border-top: 8px solid transparent;
                        border-right: 8px solid transparent;
                        border-bottom: 8px solid transparent;
                        border-left: 8px solid #fff;
                    }
                    .info{
                        display: inline-block;
                        width: 150px;
                        margin-right: 15px;
                        text-align: left;
                        vertical-align: middle;
                        font-size: 18px;
                        .icon{
                            margin-right: 10px;
                            font-size: 24px;
                            color: green;
                        }
                        .info-desc{
                            font-size: 16px;
                            color: #999;
                        }
                    }
                    .qr-code{
                        width: 70px;
                        height: 70px;
                        vertical-align: middle;
                    }

                    .free-phone{
                        font-size: 18px;
                        text-align: left;
                        span{
                            color: @primary-color;
                        }
                    }
                    .desc{
                        margin: 6px 0;
                        font-size: 16px;
                        line-height: 1.5;
                        color: #999;
                        text-align: left;
                    }
                    .btn{
                        width: 100%;
                        height: 36px;
                        line-height: 36px;
                        margin-top: 10px;
                        background-color: @primary-color;
                        border-color: @primary-color;
                        color: #fff;
                        font-size: 16px;
                    }

                }

                &:hover{
                    .popup-card{
                        display: block;
                    }
                }
            }

            .back-top{
                padding-top: 0;
                padding-bottom: 0;
                background-color: rgba(0,0,0,.45);
                .ant-back-top{
                    position: relative;
                    left: 0;
                    bottom: 0;
                    width: auto;
                    height: auto;
                    padding-bottom: 10px;
                    text-align: center;
                    color: #fff;
                    font-size: 14px;

                    .anticon-arrow-up{
                        padding-top: 10px;
                    }
                }
            }
        }

    }
</style>