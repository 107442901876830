<template>
    <div id="app" :style="{background:backgroundColor}">
        <app-header :showMenus="showMenus"/>
        <a-spin size="large" :spinning="spinning">
            <router-view v-show="showPage"></router-view>
        </a-spin>
        <app-sidebar></app-sidebar>
        <app-footer/>
        <div class="modal-box">
            <z-modal :config="modalConfig">
                <template v-slot:header>
                    <div>
                        <div class="title">您的申请我们已经收到</div>
                        <div class="title-desc">请耐心等候，我们将第一时间与您联系！</div>
                    </div>
                </template>
                <div>
                    <img src="@/assets/wx-gzh.png">
                    <div>扫码关注公众号，为您的财税保驾护航</div>
                    <div class="modal-btn" @click="modalConfig.showModal=false">好的</div>
                </div>
            </z-modal>
        </div>
    </div>
</template>

<script>
    import AppHeader from './components/AppHeader.vue'
    import AppFooter from './components/AppFooter.vue'
    import AppSidebar from './components/AppSidebar.vue'
    import ZModal from "./components/ZModal";
    import {Spin} from 'ant-design-vue';

    export default {
        name: 'App',
        components: {
            AppHeader,
            AppFooter,
            AppSidebar,
            [Spin.name]:Spin,
            ZModal
        },
        created() {
            // 全局promise的reject捕获，主要针对API
            let _this=this;
            window.addEventListener('unhandledrejection', e => {
                console.log(e.reason,_this);
                _this.$message.error(e.reason,2);
            });
            console.log('init');
            if(this.$route.name!=='index'){
                this.showMenus+=1;
            }
            console.log(this.showMenus)
        },
        computed:{
            spinning(){
                return this.$store.state.isSpinning
            },
            backgroundColor(){
                return this.$store.state.backgroundColor
            },
            modalConfig(){
                return this.$store.state.modalConfig
            },
            showPage(){
                return this.$store.state.showPage
            }
        },
        data(){
            return {
                showMenus:0,
                site:this.$store.state.site
            }
        },
        watch:{
            '$route.name'(val){
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                if(val==='index'){
                    this.showMenus=0
                }else{
                    this.showMenus+=1;
                }
            }
        }
    }
</script>

<style lang="less">
    #app {
        min-width: 1200px;
        font-family:"Microsoft YaHei", Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;

        .modal-box{
            font-size: 16px;
            line-height: 1.5;
            .title{
                padding-top: 6px;
                margin-left: 25px;
                font-size: 20px;
            }
            .title-desc{
                margin-left: 25px;
            }
            .modal-btn{
                width: 277px;
                height: 50px;
                margin: 20px auto 0;
                line-height: 50px;
                background: @primary-color;
                border-radius: 10px;
                font-size: 20px;
                color: #fff;
            }
        }
    }

    /*重置样式*/
    .header-menu {
        box-sizing: border-box;
        padding-top: 20px;
        .ant-dropdown-menu-submenu-vertical{
            height: 80px;
        }
        .ant-dropdown-menu-submenu-title {
            height: 100%;
            padding: 10px 50px 12px 40px;
            color: #fff;
            line-height: 1.8;

            &:hover {
                background-color: rgba(0,0,0,.3);
            }
            .submenu{
                font-size: 16px;
                .desc{
                    font-size: 14px;
                    color: #E5E5E5;
                }
            }

            .ant-dropdown-menu-submenu-arrow {
                top: 12px;

                i {
                    color: #fff;
                    font-size: 20px;
                }
            }
        }

    }
    .hide-menu-icon{
        .ant-dropdown-menu-submenu-arrow{
            display: none;
        }

        .ant-dropdown-menu-submenu-title:hover{
            background-color: #fff;
        }
    }

    /*隐藏滚动条*/
    .ant-cascader-menu{
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        &::-webkit-scrollbar {
            width: 0 !important
        }
    }

    /*更改首页order-box内组件样式*/
    .order-box{
        .ant-input{
            border: 0;
            border-color:#fff !important;
            box-shadow:none;
            &:focus{
                box-shadow:none;
            }
        }

        .ant-cascader-picker:focus .ant-cascader-input{
            box-shadow:none;
        }
        .ant-cascader-picker-arrow{
            right: 6px;
        }
        .anticon-down{
            color: @primary-color;
        }

        .ant-select-selection{
            border: none;
        }
        .ant-select-open .ant-select-selection,.ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active{
            box-shadow: none !important;
            border-color: #fff;
        }
    }

    .ant-cascader-picker-label{
        text-align: left;
    }

</style>
