<template>
    <div class="registration-form-wrapper">
        <div class="header">企业注册快速提交通道</div>
        <div class="content">
            <div class="flex-box">
                <div class="label">注册地区</div>
                <div class="flex-unit">
                    <div>
                        <a-select v-model="userInfo.addr" style="width: 270px" :options="citiesConfig"></a-select>
                       <!-- <a-cascader :allowClear="false"
                                    style="width: 270px"
                                    :options="citiesConfig"
                                    v-model="userInfo.addr"/>-->
                    </div>
                    <div class="radio-box">
                        <a-radio-group name="hasRegAddr" v-model="userInfo.hasRegAddr">
                            <a-radio :value="true">有注册地址</a-radio>
                            <a-radio :value="false">无注册地址</a-radio>
                        </a-radio-group>
                    </div>
                </div>
            </div>
            <div class="flex-box">
                <div class="label">企业名称</div>
                <div class="flex-unit">
                    <div>
                        <a-input v-model="userInfo.companyName" placeholder="如：福州玮烨智能科技有限公司" style="width: 270px"/>
                    </div>
                    <div class="radio-box">
                        <a-radio-group name="hasRegAddr" v-model="userInfo.hasVerify">
                            <a-radio :value="true" style="width: 100px">已核名</a-radio>
                            <a-radio :value="false">未核名</a-radio>
                        </a-radio-group>
                    </div>
                </div>
            </div>
            <div class="flex-box">
                <div class="label">称呼</div>
                <div class="flex-unit">
                    <div>
                        <a-input v-model="userInfo.name" placeholder="如：林先生" style="width: 270px"/>
                    </div>
                </div>
            </div>
            <div class="flex-box">
                <div class="label">联系方式</div>
                <div class="flex-unit">
                    <div>
                        <a-input v-model="userInfo.phone" style="width: 270px"/>
                    </div>
                </div>
            </div>
            <div class="btn hover-point" @click="toRegistration">马上联系我</div>
        </div>
    </div>
</template>

<script>
    /*import citiesConfig from '@/config/proviceCity.json';*/
    import {Cascader,Radio,Input,Select} from 'ant-design-vue';
    const RadioGroup=Radio.Group;
    export default {
        name: "RegistrationForm",
        components: {
            [Cascader.name]: Cascader,
            [Radio.name]: Radio,
            [RadioGroup.name]: RadioGroup,
            [Input.name]: Input,
            [Select.name]: Select
        },
        data() {
            return {
                userInfo:{
                    addr:'福州',
                    hasRegAddr:true,
                    companyName:'',
                    hasVerify:true,
                    name:'',
                    phone:''
                }
            }
        },
        computed:{
            citiesConfig(){
                return this.$store.state.cities.map(item=>{
                    return {
                        label:item.cityName,
                        value:item.cityName
                    }
                })
            }
        },
        methods:{
            async toRegistration(){
                let data=this.userInfo;
                if(!/^[1][3,4,5,7,8][0-9]{9}$/.test(data.phone)){
                    this.$message.error('请填写正确的手机号');
                    return false
                }
                let req={
                    applicantTel:data.phone,
                    applicantName:data.name,
                    cityCode:data.addr,
                    companyName:data.companyName,
                    companyType:data.hasVerify ? '已核名' :'未核名',
                    hasRegAddr:data.hasRegAddr ? '有注册地址' :'无注册地址',
                    serviceName:'公司注册'
                }
                let res=await this.$api.toApplyService(req);
                if(res===undefined){
                    this.$emit('showModal',true)
                }
            }
        }
    }
</script>

<style lang="less" scoped>
.registration-form-wrapper{
    .header{
        height: 70px;
        line-height: 70px;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
        background:linear-gradient(140deg,#fa9b25,#fe6313);
        color: #fff;
        font-size: 18px;

        .common-hr{
            display: inline-block;
            content: '';
            width: 15px;
            height: 1px;
            background-color: #fff;
            vertical-align: middle;
        }
        &:before{
            .common-hr;
            margin-right: 10px;
        }
        &:after{
            .common-hr;
            margin-left: 10px;
        }
    }
    .content{
        padding: 35px 25px 0;
        .flex-box{
            margin-bottom: 30px;
            text-align: left;
            .radio-box{
                margin-top: 10px;
            }
        }
        .label{
            width: 90px;
        }
        .btn{
            width: 164px;
            height: 40px;
            margin: 30px auto 0;
            border-radius: 20px;
            line-height: 40px;
            background-color: #FE6313;
            color: #fff;
        }
    }
}
</style>