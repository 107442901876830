<template>
    <div class="after-sales-wrapper">
        <div class="after-sales-title">售后保障</div>
        <div class="section">
            <div class="section-title">我们承诺</div>
            <div class="section-content">关于服务质量的反馈，我们会第一时间专人处理。保证及时解决您的问题。</div>
        </div>
        <div class="section">
            <div class="section-title">联系渠道</div>
            <div class="section-content">
                1、拨打400-168-5560，联系我们<br>
                2、通过智会算微博、微信公众号、抖音等方式，联系我们
            </div>
        </div>
        <div class="section">
            <div class="section-title">处理流程</div>
            <div class="section-content progress">
                <div class="min-box box">服务中<br/>遇到困难</div>
                <span></span>
                <div class="middle-box box">
                    1、电话联系智会算或者专属工商顾问<br>
                    2、在线留言反馈
                </div>
                <span></span>
                <div class="large-box box">
                    1、核实问题具体情况 <br>
                    2、确定责任人责任划分（是否涉退款赔付问题）
                </div>
                <span></span>
                <div class="middle-box box">
                    1、工商专家咨询<br>
                    2、按责任相关赔付<br>
                    3、正常财务退款
                </div>
                <span></span>
                <div class="min-box box">处理完毕</div>
            </div>
        </div>
        <div class="section">
            <div class="section-title">消费保障</div>
            <div class="section-content">
                凡是在智会算平台消费的用户，享全方位服务保障，一律尊享全方位保障，因平台过失，差错及疏忽行为导致用户直接经济损失，依法判决、裁定的平台责任或连带责任，将由平台在限额内进行退款或赔付。
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AfterSales"
    }
</script>

<style lang="less" scoped>
    .after-sales-wrapper{
        .after-sales-title{
            height: 80px;
            line-height: 80px;
            color: #fff;
            font-size: 22px;
            background-color: @primary-color;
        }
        .section{
            box-sizing: border-box;
            padding: 30px 30px 30px 50px;
            border-bottom: 1px solid #EFEFEF;
            text-align: left;
            .section-title{
                margin-bottom: 10px;
                font-size: 22px;
                font-weight: 400;
            }
            .section-content{
                color: #666;
                font-size: 14px;
                line-height: 1.5;
            }
            .progress{
                display: flex;
                align-items: center;
                font-size: 12px;
                .box{
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;
                    padding: 0 12px;
                    border: 2px solid @primary-color;
                }
                span{
                    display: inline-block;
                    width: 16px;
                    height: 11px;
                    margin-left: 6px;
                    margin-right: 6px;
                    background-image: url("../../assets/service-right-arrow.png");
                    background-position: center;
                    background-size: cover;
                }
                .min-box{
                    width: 78px;
                    height: 50px;
                    text-align: center;
                }
                .middle-box{
                    width: 133px;
                    height: 78px;
                }
                .large-box{
                    width: 144px;
                    height: 94px;
                }
            }
        }
    }
</style>