<template>
    <div class="account-agent-wrapper grey-bg">
        <div class="banner bg-cc"></div>
        <section class="safe-content">
            <div class="title">代理记账申请通道</div>
            <account-form></account-form>
        </section>
        <section class="safe-content">
            <div class="title">服务内容</div>
            <div class="desc">满足各类企业账务需求</div>
            <div class="service-content">
                <div v-for="(item,index) in serviceContent" :key="index" class="service-unit">
                    <div class="service-title">{{item.title}}</div>
                    <div class="service-desc" v-html="item.desc"></div>
                    <div class="service-btn hover-point" @click="reqAccount(item.title)">详细咨询</div>
                </div>
            </div>
            <!--<service-content></service-content>-->
        </section>
        <section style="margin-top: 40px">
            <reasons></reasons>
        </section>
        <section>
            <advertisement></advertisement>
        </section>
        <!-- <section class="safe-content">
            <div class="title">智会算专家团队</div>
            <team></team>
        </section -->
        <section>
            <the-progress></the-progress>
        </section>
        <section class="safe-content">
            <div class="title">智客心声</div>
            <comment></comment>
        </section>
    </div>
</template>

<script>
    import AccountForm from '../components/account-agent/AccountForm';
    import Advertisement from '@/components/common/Advertisement';
    // import ServiceContent from '../components/account-agent/ServiceContent';
    // import Team from '../components/account-agent/Team';
    import Comment from '../components/account-agent/Comment';
    import Reasons from '../components/account-agent/Reasons';
    import TheProgress from '../components/account-agent/Progress';
    export default {
        name: "AccountAgent",
        components:{
            AccountForm:AccountForm,
            // ServiceContent:ServiceContent,
            // Team:Team,
            Comment:Comment,
            Reasons:Reasons,
            TheProgress:TheProgress,
            Advertisement
        },
        data() {
            return {
                serviceContent:[
                    {
                        title:'初始建账',
                        desc:'建立会计账套'
                    },
                    {
                        title:'税务报道',
                        desc:'取得执照30日内须办理'
                    },
                    {
                        title:'会计做账',
                        desc:'编制记账凭证科目余额表等'
                    },
                    {
                        title:'纳税申报',
                        desc:'每月按时申报纳税'
                    },
                    {
                        title:'财务报表',
                        desc:'出具资产负债表、损益表等'
                    },
                    {
                        title:'凭证装订',
                        desc:'打印装订凭证、报表、账本'
                    },
                    {
                        title:'税务/工商年报',
                        desc:'全面托管式服务'
                    },
                    {
                        title:'实时查账',
                        desc:'晓智代账APP随时查询<br>做账报税进度'
                    },
                    {
                        title:'抄税清卡',
                        desc:'免费指导客户抄税清卡'
                    },
                    {
                        title:'工资表格模板',
                        desc:'免费提供工资表格模板'
                    },
                    {
                        title:'财务咨询',
                        desc:'及时沟通处理财务问题'
                    },
                    {
                        title:'电子发票',
                        desc:'让账务处理更方便及时'
                    }
                ]
            }
        },
        methods:{
            reqAccount(service){
                let title=`代理记账-${service}`;
                window.triggleZc(title,true);
            }

        }
    };
</script>
<style lang="less">
    .account-agent-wrapper{
        box-sizing: border-box;
        padding-bottom: 50px;
        .banner{
            background-image: url("../assets/a-agent-banner.png");
        }

        /*公共样式*/
        section{
            .title{
                margin-top: 60px;
                font-size: 24px;

                &:after{
                    display: block;
                    content: '';
                    width: 30px;
                    height: 4px;
                    margin: 4px auto 30px;
                    background-color: @primary-color;
                }
            }
            .desc{
                margin-top: -10px;
                margin-bottom: 20px;
                color: #666;
                font-size: 18px;
            }
            .service-content{
                display: flex;
                flex-wrap: wrap;
                width: 960px;
                padding-bottom: 40px;
                margin: 0 auto;
                .service-unit{
                    width: 240px;
                    height: 180px;
                    background-color: #f8f8f8;
                    font-size: 16px;
                    position: relative;
                    &:nth-child(2),&:nth-child(4),&:nth-child(5),&:nth-child(7),&:nth-child(10),&:nth-child(12){
                        background-color: #fff;
                    }

                    .service-title{
                        margin-top: 40px;
                        margin-bottom: 6px;
                        font-size: 18px;
                        color: #333;
                    }
                    .service-desc{
                        margin-bottom: 28px;
                        color: #999;
                        line-height: 1;
                    }
                    .service-btn{
                        position: absolute;
                        bottom: 25px;
                        left: 60px;
                        width: 120px;
                        height: 40px;
                        border-radius: 20px;
                        line-height: 40px;
                        background: @primary-color;
                        color: #fff;
                    }
                }
            }
        }
    }
</style>
