<template>
    <div class="organization-wrapper safe-content">
        <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide v-for="(oz,oi) in organizations" :key="oi">
                <div class="slide-content">
                    <div class="icon" :style="`background-image:url(${require('../../assets/'+oz.icon)})`"></div>
                    <div class="location">{{oz.name}}</div>
                    <div class="desc">{{oz.target}}</div>
                </div>
            </swiper-slide>
        </swiper>
        <div :class="{'hide-swiper-btn':activeIndex===0}" class="swiper-button-prev" @click="slickNav(-1)"></div>
        <div :class="{'hide-swiper-btn':activeIndex>=swiperMaxNum}" class="swiper-button-next" @click="slickNav(1)"></div>
    </div>
</template>

<script>
    import { Swiper, SwiperSlide  } from 'vue-awesome-swiper'
    import 'swiper/swiper-bundle.css'
    export default {
        name: "Organization",
        components: {
            Swiper,
            SwiperSlide
        },
        computed: {
            activeIndex(){
                return this.swiper.activeIndex
            },
            swiperMaxNum(){
                return this.organizations.length - 4
            }
        },
        mounted(){
            this.swiper=this.$refs.mySwiper.$swiper;
        },
        data(){
            return {
                organizations:[
                    {
                        icon:'dissolve-organization-insurance.png',
                        name:'社保局',
                        target:'核验注销公司是否缴清社保费用无误后方可注销公司社保账号'
                    },
                    {
                        icon:'dissolve-organization-tax.png',
                        name:'税务局',
                        target:'全面核查注销公司税款有无未缴清情况'
                    },
                    {
                        icon:'dissolve-organization-news.png',
                        name:'银行注销',
                        target:'公司注销按照规定，需要对银行对公账户进行注销'
                    },
                    {
                        icon:'dissolve-organization-ecom.png',
                        name:'工商局',
                        target:'办理公司注销备案，并注销公司营业执照等证件'
                    }
                ],
                swiperOptions: {
                    slidesPerView: 4,
                    initialSlide:0,
                    spaceBetween:60,
                    grabCursor:true,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },
                swiper:{}
            }
        },
        methods:{
            slickNav(num){
                // TODO:判断大于长度应该不执行下面步骤
                this.swiper.slideTo(this.activeIndex+num, 1000, false);
            }
        }
    }
</script>

<style lang="less" scoped>
    .organization-wrapper{
        position: relative;
        .slide-content{
            box-sizing: border-box;
            height: 242px;
            padding: 27px;
            background-color: #fff;
            .icon{
                width: 84px;
                height: 84px;
                margin: 0 auto;
            }
            .location{
                margin-top: 15px;
                margin-bottom: 20px;
                font-size: 18px;
            }
            .desc{
                text-align: left;
            }
        }

        .swiper-button-prev{
            left: -40px;
        }
        .swiper-button-next{
            right: -40px;
        }
        .hide-swiper-btn{
            opacity: .2;
        }
    }

</style>